import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ContentTermsAndConditions extends Component {
    render(props) {
        console.log(this.props.data);

        if (!!this.props.data) {

            return (
                <section className="content-terms-and-conditions">
                    <div className="wrapper">
                        {this.props.data.backTo !== null &&
                            <Link className="button dark back-to" to={this.props.data.backTo}>&#60;&nbsp;&nbsp;back</Link>
                        }

                        {!!this.props.data.terms &&
                            <div className="custom-terms">
                                <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: this.props.data.terms }}></div>
                            </div>
                        }


                        {/*
                        <div className="fix-terms">
                            <p>Terms and conditions text here</p>
                        </div>
                        */}
                    </div>
                </section>
            );

        } else { return null; }
    }


}

export default ContentTermsAndConditions;