import { FETCHING_AVAILABILITY_REQUEST, FETCHING_AVAILABILITY_SUCCESS, FETCHING_AVAILABILITY_FAILURE, CREATE_AVAILABILITY_REQUEST, CREATE_AVAILABILITY_SUCCESS, CREATE_AVAILABILITY_FAILURE, EDIT_AVAILABILITY_REQUEST, EDIT_AVAILABILITY_SUCCESS, EDIT_AVAILABILITY_FAILURE } from '../actions/types';

const initialState = {
    availability: null,
    isFetching: false,
    errors: null 
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCHING_AVAILABILITY_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_AVAILABILITY_SUCCESS:
            return { ...state, isFetching: false, availability: action.payload };
        case FETCHING_AVAILABILITY_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case CREATE_AVAILABILITY_REQUEST:
            return { ...state, isFetching: true };
        case CREATE_AVAILABILITY_SUCCESS:
            return { ...state, isFetching: false, availability: action.payload };
        case CREATE_AVAILABILITY_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case EDIT_AVAILABILITY_REQUEST:
            return { ...state, isFetching: true };
        case EDIT_AVAILABILITY_SUCCESS:
            return { ...state, isFetching: false, availability: action.payload };
        case EDIT_AVAILABILITY_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;
    }
}