import axios from 'axios';
import { FETCHING_USER_REQUEST, FETCHING_USER_SUCCESS, FETCHING_USER_FAILURE, EDIT_CURRENCY_REQUEST, EDIT_CURRENCY_SUCCESS, EDIT_CURRENCY_FAILURE } from './types';

export const fetchUser = () => {
	return async dispatch => {
        try {
            dispatch({ type: FETCHING_USER_REQUEST });
            const response = await axios({
                url: '/user',
                method: 'post'
            });
            dispatch({ type: FETCHING_USER_SUCCESS, payload: response });
        } 
        catch(error) {
            dispatch({ type: FETCHING_USER_FAILURE, payload: error });
        }
    };
}

export const changeCurrency = data => {
	return async dispatch => {
        try {
            dispatch({ type: EDIT_CURRENCY_REQUEST });
            const response = await axios.put('/user/currency', data);
            dispatch({ type: EDIT_CURRENCY_SUCCESS, payload: response });
        } 
        catch(error) {
            dispatch({ type: EDIT_CURRENCY_FAILURE, payload: error });
        }
    };
}