import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteFlash } from '../../../actions';
import LoadMore from '../../partial.load-more';
import { FlashPlaceholder } from "./partials/partial.flash-placeholder"
import { Virtuoso } from 'react-virtuoso';

class MyFlashes extends Component {

    constructor() {
        super()
        this.state = {
            sliceAt: 0
        }
    }

    componentDidMount() {
        this.props.fetchUserFlashes();
    }

    handleDelete(id) {
        if (window.confirm("Are you sure you want to delete this flash?")) {
            this.props.deleteFlash(id).then(() => {
                this.props.fetchUserFlashes();
            })
        }
    }

    loadMore(allItems) {
        const sliceAt = Math.min(allItems.length, this.state.sliceAt + 20)
        console.log("loadMore " + sliceAt)
        this.setState({ sliceAt })
    }

    render() {
        const _flashes = this.props.flashes
            .slice(0, this.state.sliceAt)
        return (
            <section className='myFlashes'>
                <h1>My designs</h1>
                {

                    this.props.flashes.length !== 0 ?
                        <Virtuoso
                            useWindowScroll
                            context={{ loadMore: () => this.loadMore(this.props.flashes) }}
                            data={_flashes}
                            itemContent={(_, item) => (
                                <div key={item.id} className="flashItemContainer" >
                                    <Link className={(item.status === 2 || item.status === 0) ? 'sold' : ''} to={`/admin/flashes/myflashes/${item.id}`}>
                                        <div className="image-container">
                                            {(item.hasOwnProperty('image') && item.image !== null && item.image.imageUrl !== null) ?
                                                <img src={item.image.imageUrl} alt={item.title} /> :
                                                <img src={require('../../../assets/images/sites/placeholders/product-placeholder.png')} alt="Placeholder" />
                                            }
                                        </div>

                                        <div className="details-container">
                                            {(item.status === 1) &&
                                                <h3>{item.title}</h3>
                                            }

                                            {(item.status === 2 || item.status === 0) &&
                                                <h3><span>Sold</span>{item.title}</h3>
                                            }

                                            {item.category !== null && <p>{item.category.name}</p>}
                                        </div>
                                    </Link>
                                    <button className="delete-btn" onClick={() => this.handleDelete(item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" stroke="black" strokeWidth="2" className="react-timerange-picker__clear-button__icon react-timerange-picker__button__icon"><line x1="4" y1="4" x2="15" y2="15"></line><line x1="15" y1="4" x2="4" y2="15"></line></svg>
                                    </button>

                                </div>
                            )}
                            components={{ ScrollSeekPlaceholder: FlashPlaceholder, Footer: LoadMore }}
                            scrollSeekConfiguration={{
                                enter: (velocity) => Math.abs(velocity) > 500,
                                exit: (velocity) => Math.abs(velocity) < 20
                            }}
                        />
                        : <>
                            <p>No flashes were found.</p>
                            <Link className="button" to="/admin/flashes/addflashes">Create your first flash</Link>
                        </>
                }
            </section>
        );
    }
}

const mapStateToProps = state => ({
    flash: state.flash,
    isFetching: state.flash
});

export default connect(mapStateToProps, { deleteFlash })(MyFlashes);
//export default MyFlashes;

