import React, { Component } from 'react';

class ContentAbout extends Component {
    render(props) {
        if (!!this.props.data) {
            return (
                <section className="content-about">
                    <div className="wrapper">
                        <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: this.props.data.text }}></div>
                    </div>
                </section>
            );
        } else { return null; }
    }


}

export default ContentAbout;