import React, { Component } from 'react';
import ProductItem from "../partials/partial.product-item"
import CategoryItem from "../partials/partial.category-item"
import Loading from "../partials/partial.loading";
import ProductItemPlaceholder from "../partials/partial.product-item-placeholder"
import LoadMore from '../../partial.load-more';
import { Virtuoso } from "react-virtuoso";

class ProductsListing extends Component {

    constructor() {
        super();
        this.state = {
            selectedCategoryId: null,
            sliceAt: 0,
            cellCount: this.calculateElementsPerRow(window.innerWidth)
        };
        console.log(this.state.cellCount)
        this.categoryClickHandler = this.categoryClickHandler.bind(this);
    }

    calculateElementsPerRow = (width) => {
        if (width > 1500) return 5;
        else if (width > 1280) return 4;
        else if (width > 767) return 3;
        else if (width > 550) return 2;
        else return 1
    };

    handleResize = () => {
        this.setState({
            cellCount: this.calculateElementsPerRow(window.innerWidth),
        });
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    // Remove event listener when component unmounts
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    filterFeaturedProducts(products) {
        return products
            .filter(p => this.props.data.featured ? p.featured : true)
            .filter(product => !!product)
    }

    async loadMore(allProducts) {
        const sliceAt = Math.min(allProducts.length, this.state.sliceAt + 20)
        console.log("loadMore " + sliceAt)
        this.setState({ sliceAt })
    }

    getRow(rowIndex, products) {
        const idx = [];
        for (let i = 0; i < this.state.cellCount; i++) {
            idx.push(rowIndex * this.state.cellCount + i);
        }
        const cells = idx.map(i => {
            const product = products[i]
            if (product)
                return (<ProductItem
                    isActive={(this.state.selectedCategoryId === null || (!!product.category && this.state.selectedCategoryId === product.category.categoryId)) ? true : false}
                    key={product.id}
                    data={product}
                    siteUrl={this.props.siteUrl}
                    type={this.props.type}
                    websiteInfo={this.props.websiteInfo}
                />)
            return undefined
        });
        return <div key={rowIndex} className={'row'}>{cells.filter(x => x)}</div>
    }

    getPlaceholder() {
        return (props) => <div key={props.index} className={'row'}>{new Array(this.state.cellCount).fill(true).map(x => <ProductItemPlaceholder />)}</div>
    }

    render(props) {
        const featuredProducts = this.filterFeaturedProducts(this.props.data.products)
        const categories = this.renderCategories(featuredProducts);
        const _products = featuredProducts
            .filter(p => (this.props.type === "flash" && p.status === 1) || (this.props.type === "product" && p.quantity > 0))
            .slice(0, this.state.sliceAt)
        const Placeholder = this.getPlaceholder()
        return (
            <section className="products-listing">

                <Loading isLoading={this.props.data.isLoading} />
                <div className="wrapper">
                    {!!this.props.data.title && <h3>{this.props.data.title}</h3>}
                    {categories.length !== 0 && <div className="categories-list">{categories}</div>}
                    {
                        featuredProducts.length !== 0 ?
                            <Virtuoso
                                className="products-list"
                                useWindowScroll
                                context={{ loadMore: () => this.loadMore(featuredProducts) }}
                                totalCount={Math.ceil(_products.length / this.state.cellCount)}
                                itemContent={i => this.getRow(i, _products)}
                                components={{ ScrollSeekPlaceholder: Placeholder, Footer: LoadMore }}
                                scrollSeekConfiguration={{
                                    enter: (velocity) => Math.abs(velocity) > 500,
                                    exit: (velocity) => Math.abs(velocity) < 20
                                }}
                            />
                            :
                            <p className="error">{this.props.data.errorMsg}</p>
                    }
                </div>
            </section>
        );
    }

    // categoryClickHandler
    categoryClickHandler = (categoryId) => {
        this.setState({ selectedCategoryId: categoryId });
    }

    // <CategoryItem />   
    renderCategories = (products) => {
        let categoriesArray = [];
        let categoryNames = [];
        if (this.props.data.categories) {
            categoriesArray = products.map(item => {
                if (item.category !== null && item.status === 1) {
                    categoryNames.push(item.category.name);
                    let categoryId = !item.category ? null : item.category.categoryId;
                    return <CategoryItem
                        key={item.id}
                        category={item.category}
                        isSelected={((item.category === null && this.state.selectedCategoryId === null) || (this.state.selectedCategoryId === item.category.categoryId)) ? true : false}
                        actionOnClick={() => this.categoryClickHandler(categoryId)}
                    />
                } else {
                    categoryNames.push(null)
                    return null;
                }
            });
        }


        // Remove duplicates
        let tits = [];
        let categoryIndexToRemove = [];
        for (let i = 0; i < categoryNames.length; i += 1) {
            if (tits.indexOf(categoryNames[i]) === -1) {
                tits.push(categoryNames[i]);
            } else {
                categoryIndexToRemove.push(i);
            }
        }
        for (let r = categoryIndexToRemove.length - 1; r >= 0; r--) {
            categoriesArray.splice(categoryIndexToRemove[r], 1);
        }

        // Add "ALL" category
        if (this.props.data.categories && categoriesArray.length > 0) {
            categoriesArray.unshift(
                <CategoryItem
                    key={0}
                    category={null}
                    actionOnClick={() => this.categoryClickHandler(null)}
                    isSelected={this.state.selectedCategoryId === null ? true : false}
                />)
        }
        return categoriesArray;
    }
}

export default ProductsListing;