import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postGoogleCalendarToken } from '../../../../actions';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Loading from "../../partials/loading";

class GoogleCalendarCreate extends Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		currentUser: PropTypes.object,
	}

	componentDidMount() {
		const googleResponse = queryString.parse(this.props.location.search);
		let data = {
			code: googleResponse.code
		}
		if (googleResponse.code){
			this.props.postGoogleCalendarToken(data).then(() => this.props.addGoogle.refresh_token !== null ? window.location.replace("/admin") : alert("Error please try again"));
		}
		else if (googleResponse.error)
			return alert(googleResponse.error);
	}
    
	render() {
		return (
			<div className={"container"}>
				<div className={"imgContainer"}>
					<img src={require("../../../../assets/images/dashboard/logos/bookmeink-logo@2x.png")} alt="Logo BookMeInk" />
				</div>
				<div className={"stepFirstLogin"}>
					<h3>2 / 3</h3>
				</div>
				<Loading isLoading={this.props.isFetching || this.props.isGoogleFetching} />
				<h1>Connect your calendar</h1>
				<p>To get the most of the bookme.ink app, you need to connect your google calendar to the platform.</p>
				<p>This will allow your customers to directly book flashes that will automatically be scheduled in your agenda.</p>
				<a href={"https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=" + process.env.REACT_APP_GOOGLE_REDIRECT_URL + "&prompt=consent&response_type=code&client_id=" + process.env.REACT_APP_GOOGLE_CLIENT_ID + "&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar&access_type=offline"}><img src={require("../../../../assets/images/dashboard/icons/btn_google_signin_light_normal_web@2x.png")} alt={"GoogleSignIn"} className={"googleSignIn"}/></a>
				<button className='button skip' onClick={() => this.props.handleSkip()}>Skip set-up</button>
				<button className={"button logout"} onClick={() => this.props.auth.logout()}>Logout</button>
			</div> 
		);
	}
}

const mapStateToProps = state => ({
	isFetching: state.user.isFetching,
	isGoogleFetching: state.googleCalendar.isFetching,
	addGoogle: state.googleCalendar.addGoogle
});

export default connect(mapStateToProps, { postGoogleCalendarToken })(GoogleCalendarCreate);