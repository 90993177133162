import React, { Component } from 'react';
import { connect } from 'react-redux';
import { upload, editWebsite, fetchCurrentUserWebsiteInfo } from '../../../actions';
import _ from 'lodash';
import Loading from "./loading";
import Auth from '../../../auth/authService';

const auth = new Auth();

class SocialMediasSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            website: {
                facebookURL: null,
                instagramURL: null
            },
            errorFacebook: false,
            errorInstagram: false
        }
    }


    handleInputChange(event) {
        const newWebsiteData = this.state.website;
        newWebsiteData[event.target.name] = event.target.value;
        this.setState({ website: newWebsiteData });
    }



    handleSubmit() {
        let siteData = this.state.website;

        function validateURL(url) {
            var re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            return re.test(String(url))
        }

        let errorFacebook = false;
        if (!!siteData.facebookURL && !validateURL(siteData.facebookURL)) {
            errorFacebook = true;
        }

        let errorInstagram = false;
        if (!!siteData.instagramURL && !validateURL(siteData.instagramURL)) {
            errorInstagram = true;
        }

        if (!errorFacebook && !errorInstagram) {

            this.props.editWebsite(siteData).then(() => this.props.fetchCurrentUserWebsiteInfo().then(() => {
                    this.setState({
                        website: this.props.websiteInfo.websiteInfo.data[0],
                        errorFacebook: errorFacebook,
                        errorInstagram: errorInstagram
                    });
                }));

        } else {
            this.setState({
                errorFacebook: errorFacebook,
                errorInstagram: errorInstagram
            });
        }


    }

    componentDidMount() {
        this.props.fetchCurrentUserWebsiteInfo().then(() => this.setState({ website: this.props.websiteInfo.websiteInfo.data[0] }));
    }

    render() {

        return (
            <>
                <Loading isLoading={!!this.props.websiteInfo.isFetching || !!this.props.uploadResponse.isFetching || _.isEmpty(this.props.websiteInfo.websiteInfo)} />
                {
                    <div>
                        <h1>Social medias</h1>

                        <div className={!!this.state.errorFacebook ? "field-container error" : "field-container"}>
                            <h2>Facebook</h2>
                            <p className="details">URL to facebook page</p>
                            <input type="text" name="facebookURL" onChange={(text) => this.handleInputChange(text)} placeholder={"Facebook"} defaultValue={this.state.website.facebookURL} />
                        </div>

                        <div className={!!this.state.errorInstagram ? "field-container error" : "field-container"}>
                            <h2>Instagram</h2>
                            <p className="details">URL to instagram page</p>
                            <input type="text" name="instagramURL" onChange={(text) => this.handleInputChange(text)} placeholder={"Instagram"} defaultValue={this.state.website.instagramURL} />
                        </div>


                        {(this.state.errorFacebook || this.state.errorInstagram) &&
                            <div className="error-container">
                                <p className="error">Please make sure the facebook and instagram fields contain valid URLs.</p>
                            </div>
                        }

                        <div className="field-container">
                            <button onClick={() => this.handleSubmit()}>Save</button>
                        </div>

                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    uploadResponse: state.upload,
    websiteInfo: state.websiteInfo,
    editWebsite: state.websiteInfo,
});

export default connect(mapStateToProps, { upload, editWebsite, fetchCurrentUserWebsiteInfo })(SocialMediasSettings);