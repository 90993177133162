


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchFlashById, fetchCurrentUserCategories, putFlash, upload } from '../../actions';
import _ from 'lodash';
import Navigation from './partials/navigation';
import Dropdown from 'react-dropdown';
import Loading from "./partials/loading";
import { Link } from 'react-router-dom';

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



class FlashesSingle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            flash: null,
            categories: null,
            selectedCategory: null,
            imageChange: false,
            errorAmount: false,
            errorRequired: false,
            errorDeposit: false,
            errorDuration: false,
            editorState: EditorState.createEmpty()
        }

        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleWysiwygChange = this.handleWysiwygChange.bind(this)
    }

    componentDidMount() {
        this.props.fetchFlashById(this.props.match.params.id).then(() => {

            let newSelectedCategory = null;
            if (this.props.flash.data.hasOwnProperty('category') && this.props.flash.data.category !== null) {
                newSelectedCategory = {
                    value: this.props.flash.data.category.categoryId,
                    label: this.props.flash.data.category.name
                }
            }

            let newEditorState = EditorState.createEmpty();
            if (!!this.props.flash.data.description) {
                const blockFromHtml = convertFromHTML(this.props.flash.data.description);
                if (blockFromHtml.contentBlocks.length > 0) {
                    const newContentState = ContentState.createFromBlockArray(
                        blockFromHtml.contentBlocks,
                        blockFromHtml.entityMap
                    );
                    newEditorState = EditorState.createWithContent(newContentState);
                }

            }
            this.setState({ flash: this.props.flash.data, selectedCategory: newSelectedCategory, editorState: newEditorState });
        });

        this.props.fetchCurrentUserCategories().then(() => {
            this.setState({ categories: this.props.categories.data });
        });
    }



    handleFileChange(e) {
        this.setState({ file: e.target.files[0] }, function () {
            this.fileUpload();
        });
    }


    fileUpload() {
        const formData = new FormData();
        formData.append('files', this.state.file);
        this.props.upload(formData).then(() => this.props.uploadResponse.fileUrl !== null && this.setFlashImgState());
    }


    handleImageDelete() {
        const newFlashData = this.state.flash;
        newFlashData.image.imageUrl = null;
        this.setState({ flash: newFlashData, imageChange: true });
    }


    setFlashImgState() {
        const newFlashData = this.state.flash;
        newFlashData.image = {
            imageUrl: this.props.uploadResponse.fileUrl
        }
        this.setState({ pageContent: newFlashData, imageChange: true });
    }


    handleCategories() {
        let data = [];
        data.push({ value: null, label: 'No category' });
        this.state.categories.map(item => {
            return data.push({ value: item.categoryId, label: item.name });
        });
        return data;
    }


    handleCategoriesSelection(data) {
        this.setState({ selectedCategory: data });
    }


    handleSubmit() {
        let newFlashData = this.state.flash;
        newFlashData.deposit = parseFloat(this.state.flash.deposit);
        newFlashData.pricingMin = parseFloat(this.state.flash.pricingMin);
        newFlashData.pricingMax = parseFloat(this.state.flash.pricingMax);

        if (
            !!newFlashData.title &&
            newFlashData.deposit !== 0 &&
            newFlashData.pricingMin !== 0 &&
            newFlashData.pricingMax !== 0 &&
            !!newFlashData.time
        ) {
            if (parseInt(newFlashData.pricingMin) < parseInt(newFlashData.pricingMax)) {
                if (newFlashData.deposit >= 50) {
                    if (newFlashData.time % 30 === 0) {

                        // Form validation passed
                        this.setState({ errorAmount: false, errorRequired: false, errorDeposit: false });

                        if (this.state.selectedCategory !== null) {
                            newFlashData.categoryId = this.state.selectedCategory.value;
                        } else if (!!this.state.flash.category) {
                            newFlashData.categoryId = this.state.flash.category.categoryId;
                        }

                        if (!this.state.imageChange) delete newFlashData.image;
                        if (newFlashData.category !== undefined) delete newFlashData.category


                        this.props.putFlash(newFlashData).then(() => this.props.history.push("/admin/flashes/myflashes"));
                    } else {
                        this.setState({ errorAmount: false, errorRequired: false, errorDeposit: false, errorDuration: true });
                    }

                } else {
                    this.setState({ errorAmount: false, errorRequired: false, errorDeposit: true, errorDuration: false });
                }

            } else {
                // Error minimum amount must be smaller than maximum amount
                this.setState({ errorAmount: true, errorRequired: false, errorDeposit: false, errorDuration: false });
            }
        } else {
            // All required fields must be filled
            this.setState({ errorAmount: false, errorRequired: true, errorDeposit: false, errorDuration: false });
        }
    }


    handleInputChange(event) {
        let newFlashData = this.state.flash;

        if (event.target.name === "time") {
            let newTime = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value);
            newFlashData[event.target.name] = newTime;
            this.setState({ flash: newFlashData });

        } else if (
            event.target.name === 'pricingMin' ||
            event.target.name === 'pricingMax' ||
            event.target.name === 'deposit'
        ) {

            if (
                newFlashData[event.target.name] === "0.00" ||
                (
                    newFlashData[event.target.name].toString() === "0" &&
                    !isNaN(
                        parseInt(
                            event.target.value.toString().charAt(event.target.value.toString().length - 1)
                        )
                    ) &&
                    event.target.value.toString().indexOf('.') === -1
                )
            ) {
                newFlashData[event.target.name] = event.target.value.toString().charAt(event.target.value.toString().length - 1);
                this.setState({ flash: newFlashData });

            } else if (event.target.value.toString() === "") {
                newFlashData[event.target.name] = 0
                this.setState({ flash: newFlashData });

            } else if (
                (
                    event.target.value.toString().indexOf('.') !== -1 &&
                    event.target.value.toString().split('.')[1].length <= 2
                ) || event.target.value.toString().indexOf('.') === -1

            ) {
                let newPrice = isNaN(event.target.value) ? 0 : event.target.value;
                newFlashData[event.target.name] = newPrice
                this.setState({ flash: newFlashData });
            }

        } else {
            newFlashData[event.target.name] = event.target.value;
            this.setState({ flash: newFlashData });
        }

    }


    handleWysiwygChange = (editorState) => {
        this.setState({ editorState }, () => {
            const newFlashData = this.state.flash;
            newFlashData.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            this.setState({ flash: newFlashData });
        });
    }


    handleFeatured() {
        const newFlashData = this.state.flash;
        newFlashData.featured = !this.state.flash.featured;
        this.setState({ flash: newFlashData });
    }


    handleIsRecurring() {
        const newFlashData = this.state.flash;
        newFlashData.isRecurring = !this.state.flash.isRecurring;
        this.setState({ flash: newFlashData });
    }


    render() {
        let uploadBtnText = this.props.uploadResponse.fileUrl !== null ? 'Modify image' : 'Upload Image'

        return (
            <div className="dashboard-wrapper">
                <Navigation subMenu={true} subMenuItems={[{ name: "My flashes", link: "/admin/flashes/myflashes" }, { name: "Add flash", link: "/admin/flashes/addflashes" }, { name: "Manage categories", link: "/admin/flashes/categories" }]} subMenuTitle={"Flashes"} />

                <Loading isLoading={this.state.flash === null || this.state.categories === null || this.props.isFetching || this.props.uploadResponse.isFetching} />

                <div className="dashboard-content-wrapper">
                    <section className="single-flash">

                        <Link className="back-btn" to="/admin/flashes/myflashes">
                            <img className="checked-image" src={require('../../assets/images/dashboard/icons/back-arrow_4x.png')} />
                            <span>Back to my designs</span>
                        </Link>

                        { // EDITABLE FLASH
                            (this.state.flash !== null && this.state.flash.status === 1) &&
                            <>
                                <h2>Information</h2>
                                <div className="field-container">
                                    <div className={(this.state.errorRequired && !this.state.flash.title) ? "input-container error" : "input-container"}>
                                        <label>Name *</label>
                                        <input type="text" name="title" onChange={(text) => this.handleInputChange(text)} placeholder={"Name"} defaultValue={this.state.flash.title} />
                                    </div>


                                    <label>Description</label>
                                    <div className="wysiwyg-container">
                                        <Editor
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.handleWysiwygChange}
                                        />
                                    </div>

                                    <div className="input-container">
                                        <label>Image</label>
                                        <p className="details">Recommended size (500 x 500)</p>
                                        {
                                            this.state.flash.hasOwnProperty('image') &&
                                            <img className={(this.state.flash.image !== null && this.state.flash.image.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.flash.image !== null && this.state.flash.image.imageUrl !== null) ? this.state.flash.image.imageUrl : null} />
                                        }

                                        <div className='buttons-container'>
                                            <label className="upload-button" htmlFor="flash-image-upload">{uploadBtnText}</label><input id="flash-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                            {(this.state.flash.hasOwnProperty('image') && this.state.flash.image !== null && this.state.flash.image.imageUrl !== null) && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                        </div>
                                    </div>


                                    <div className="input-container">
                                        <label>Category</label>
                                        {
                                            this.state.categories !== null ? <Dropdown options={this.handleCategories()} onChange={(item) => this.handleCategoriesSelection(item)} value={this.state.selectedCategory} placeholder="Select a category" /> :
                                                <h1>Loading categories</h1>
                                        }
                                    </div>


                                    <div className={(this.state.errorRequired && !this.state.flash.time) || this.state.errorDuration ? "input-container error" : "input-container"}>
                                        <label>Appointment duration *</label>
                                        <p className="details">Necessary appointment duration in increments of 30 minutes.<br />Example: 120 (for 2 hours) </p>
                                        <input
                                            step="30"
                                            type="number"
                                            min="0"
                                            name="time"
                                            onChange={(text) => this.handleInputChange(text)}
                                            placeholder={"Flash time in minutes"}
                                            value={this.state.flash.time.toString()}
                                        />
                                    </div>


                                </div>

                                <h2>Pricing</h2>
                                <div className="field-container">

                                    <div className={((this.state.errorRequired && parseFloat(this.state.flash.deposit) === 0) || !!this.state.errorDeposit) ? "input-container error" : "input-container"}>
                                        <label>Online deposit *</label>
                                        <p className="details">Minimum of 50.00$<br />This amount will be paid online.</p>
                                        <input
                                            type="number"
                                            min="50"
                                            name="deposit"
                                            onChange={(text) => this.handleInputChange(text)}
                                            placeholder={"Flash deposit"}
                                            value={this.state.flash.deposit.toString()}
                                        />
                                    </div>

                                    <div className={(this.state.errorAmount || (this.state.errorRequired && parseFloat(this.state.flash.pricingMin) === 0)) ? "input-container error" : "input-container"}>
                                        <label>Minimum price * </label>
                                        <p className="details">Excludes the Online deposit<br />This amount will be paid in store.</p>
                                        <input
                                            type="number"
                                            min="0"
                                            name="pricingMin"
                                            onChange={(text) => this.handleInputChange(text)}
                                            placeholder={"Flash price min"}
                                            value={this.state.flash.pricingMin.toString()}
                                        />
                                    </div>

                                    <div className={(this.state.errorAmount || (this.state.errorRequired && parseFloat(this.state.flash.pricingMax) === 0)) ? "input-container error" : "input-container"}>
                                        <label>Maximum price *</label>
                                        <p className="details">Excludes the Online deposit<br />This amount will be paid in store.</p>
                                        <input
                                            type="number"
                                            min="0"
                                            name="pricingMax"
                                            onChange={(text) => this.handleInputChange(text)}
                                            placeholder={"Flash price max"}
                                            value={this.state.flash.pricingMax.toString()}
                                        />
                                    </div>

                                    <div className="input-container">
                                        <label>Currency</label>
                                        <div className="columns">
                                            {
                                                (!!this.props.currentUser && this.props.currentUser.data.currency !== null) ?
                                                    <div className="input currency">
                                                        <p>{this.props.currentUser.data.currency.toUpperCase()}</p>
                                                    </div>
                                                    :
                                                    <div className="input currency">
                                                        <p>CAD</p>
                                                    </div>
                                            }
                                            <Link className="button" to="/admin/settings/advanced">
                                                <span>Change currency</span>
                                            </Link>
                                        </div>
                                    </div>

                                </div>


                                <h2>Details</h2>
                                <div className="field-container">
                                    <div className="input-container">
                                        <label>Featured</label>
                                        <p className="details">Display the flash on the homepage.</p>
                                        <div>
                                            <div className="radio-container">
                                                <div className="radio-item">
                                                    <input type="radio" id="radio-featured-yes" checked={this.state.flash.featured} onChange={() => this.handleFeatured()} />
                                                    <label htmlFor="radio-featured-yes" className="radio-label">Yes</label>
                                                </div>

                                                <div className="radio-item">
                                                    <input type="radio" id="radio-featured-no" checked={!this.state.flash.featured} onChange={() => this.handleFeatured()} />
                                                    <label htmlFor="radio-featured-no" className="radio-label">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-container">
                                        <label>Unique</label>
                                        <p className="details">Will this flash be sold only once?</p>
                                        <div>
                                            {
                                                <div className="radio-container">
                                                    <div className="radio-item">
                                                        <input type="radio" id="radio-reccuring-yes" checked={!this.state.flash.isRecurring} onChange={() => this.handleIsRecurring()} />
                                                        <label htmlFor="radio-reccuring-yes" className="radio-label">Yes</label>
                                                    </div>

                                                    <div className="radio-item">
                                                        <input type="radio" id="radio-reccuring-no" checked={this.state.flash.isRecurring} onChange={() => this.handleIsRecurring()} />
                                                        <label htmlFor="radio-reccuring-no" className="radio-label">No</label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>



                                <div className="error-container">
                                    {!!this.state.errorAmount &&
                                        <p className="error">The "In store minimum amount" must be smaller than the "In store maximum amount"</p>
                                    }

                                    {!!this.state.errorRequired &&
                                        <p className="error">All * fields are required. </p>
                                    }

                                    {!!this.state.errorDeposit &&
                                        <p className="error">The deposit amount must be over 50.00 $</p>
                                    }

                                    {!!this.state.errorDuration &&
                                        <p className="error">The duration must be a 30 minutes increment</p>
                                    }
                                </div>
                                <button onClick={() => this.handleSubmit()}>Modify</button>
                            </>
                        }

                        { // SOLD FLASH
                            (this.state.flash !== null && this.state.flash.status !== 1) &&
                            <>
                                <div className="field-container">
                                    <label>Name</label>
                                    <p>{this.state.flash.title}</p>
                                </div>

                                <div className="field-container">
                                    <label>Description</label>
                                    <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: this.state.flash.description }}></div>
                                </div>

                                {(this.state.flash.hasOwnProperty('image') && this.state.flash.image !== null && this.state.flash.image.imageUrl !== null) &&
                                    <div className="field-container">
                                        <label>Image</label>
                                        <img className={(this.state.flash.image !== null && this.state.flash.image.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.flash.image !== null && this.state.flash.image.imageUrl !== null) ? this.state.flash.image.imageUrl : null} />
                                    </div>
                                }

                                <div className="field-container">
                                    <label>Deposit</label>
                                    <p>{this.state.flash.deposit}</p>

                                </div>

                                <div className={(this.state.errorAmount || (this.state.errorRequired && !this.state.flash.pricingMin)) ? "field-container error" : "field-container"}>
                                    <label>Minimum cost</label>
                                    <p>{this.state.flash.pricingMin}</p>
                                </div>

                                <div className={(this.state.errorAmount || (this.state.errorRequired && !this.state.flash.pricingMax)) ? "field-container error" : "field-container"}>
                                    <label>Maximum cost</label>
                                    <p>{this.state.flash.pricingMax}</p>
                                </div>

                                <div className={this.state.errorRequired && !this.state.flash.time ? "field-container error" : "field-container"}>
                                    <label>Appointment duration</label>
                                    <p>{this.state.flash.time}</p>
                                </div>

                                <div className="field-container">
                                    <label>Featured</label>
                                    {
                                        this.state.flash.featured ?
                                            <p>Yes</p> :
                                            <p>No</p>
                                    }
                                </div>

                                {this.state.selectedCategory !== null &&
                                    <div className="field-container">
                                        <label>Category</label>
                                        <p>{this.state.selectedCategory.label}</p>
                                    </div>
                                }
                            </>
                        }
                    </section>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    flash: state.flash.flash,
    categories: state.category.categories,
    uploadResponse: state.upload,
    isFetching: state.flash.isFetching,
});


export default connect(mapStateToProps, { fetchFlashById, fetchCurrentUserCategories, putFlash, upload })(FlashesSingle);