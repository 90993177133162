import axios from 'axios';
import { POST_CONTACT_EMAIL_REQUEST, POST_CONTACT_EMAIL_SUCCESS, POST_CONTACT_EMAIL_FAILURE, POST_SUPPORT_EMAIL_REQUEST, POST_SUPPORT_EMAIL_SUCCESS, POST_SUPPORT_EMAIL_FAILURE } from './types';

export const postContactEmail = (data, siteUrl) => async (dispatch) => {
    try {
      dispatch({ type: POST_CONTACT_EMAIL_REQUEST });
      const response = await axios.post(`/email/${siteUrl}`, data);
      dispatch({ type: POST_CONTACT_EMAIL_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: POST_CONTACT_EMAIL_FAILURE, payload: error });
    }
};

export const postSupportEmail = (data) => async (dispatch) => {
  try {
    dispatch({ type: POST_SUPPORT_EMAIL_REQUEST });
    const response = await axios.post(`/email/support`, data);
    dispatch({ type: POST_SUPPORT_EMAIL_SUCCESS, payload: response });
  } catch (error) {
    alert(error);
    dispatch({ type: POST_SUPPORT_EMAIL_FAILURE, payload: error });
  }
};
 