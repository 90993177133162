import React, { Component } from 'react';

import BannerSmall from "./sections/section.banner-small";
import Content404 from "./sections/section.content-404";
import { animateScroll as scroll} from 'react-scroll'




class Sites404 extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.bannerData = {
            title: "404 not found",
        }
    }

    componentDidMount(){
        scroll.scrollToTop();
    }

    render() {
        return ( 
                <div className="main">
                    <BannerSmall data={this.bannerData} />
                    <Content404 websiteInfo={this.props.websiteInfo} />
                </div>
        );
    }
}

export default Sites404;