import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postFlash, fetchCurrentUserCategories, upload } from '../../../actions';
import _ from 'lodash';
import Dropdown from 'react-dropdown';
import Loading from "../partials/loading";
import { Link } from 'react-router-dom';


import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class AddFlashes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            title: '',
            featured: false,
            description: '',
            deposit: "0.00",
            pricingMin: "0.00",
            pricingMax: "0.00",
            time: 0,
            image: null,
            categories: null,
            selectedCategory: null,
            errorRequired: false,
            errorDeposit: false,
            errorAmount: false,
            errorDuration: false,
            isRecurring: false,
            editorState: EditorState.createEmpty()
        }

        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleWysiwygChange = this.handleWysiwygChange.bind(this)
    }

    componentDidMount() {
        this.props.fetchCurrentUserCategories().then(() => this.setState({ categories: this.props.categories.data }));
    }

    handleFileChange(e) {
        this.setState({ file: e.target.files[0] }, function () {
            this.fileUpload();
        });
    }

    fileUpload() {
        const formData = new FormData();
        formData.append('files', this.state.file);
        this.props.upload(formData).then(() => this.props.uploadResponse.fileUrl !== null && this.setFlashImgState());
    }

    handleImageDelete() {
        this.setState({ image: null });
    }

    setFlashImgState() {
        let data = {
            imageUrl: this.props.uploadResponse.fileUrl
        }
        this.setState({ image: data });
    }

    handleInputChange(event) {

        if (event.target.name === "time") {
            let newTime = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value);
            this.setState({ time: newTime });

        } else if (
            event.target.name === 'pricingMin' ||
            event.target.name === 'pricingMax' ||
            event.target.name === 'deposit'
        ) {

            if (
                this.state[event.target.name] === "0.00" ||
                (
                    this.state[event.target.name].toString() === "0" &&
                    !isNaN(
                        parseInt(
                            event.target.value.toString().charAt(event.target.value.toString().length - 1)
                        )
                    ) &&
                    event.target.value.toString().indexOf('.') === -1
                )
            ) {
                this.setState({ [event.target.name]: event.target.value.toString().charAt(event.target.value.toString().length - 1) });

            } else if (event.target.value.toString() === "") {
                this.setState({ [event.target.name]: 0 });

            } else if (
                (
                    event.target.value.toString().indexOf('.') !== -1 &&
                    event.target.value.toString().split('.')[1].length <= 2
                ) || event.target.value.toString().indexOf('.') === -1

            ) {
                let newPrice = isNaN(event.target.value) ? 0 : event.target.value;
                this.setState({ [event.target.name]: newPrice });
            }

        } else {
            this.setState({ [event.target.name]: event.target.value });
        }

    }

    handleWysiwygChange = (editorState) => {
        this.setState({ editorState }, () => {
            const newDescription = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            this.setState({ description: newDescription });
        });
    }


    handleSubmit() {
        let data = {
            title: this.state.title,
            description: this.state.description,
            image: this.state.image !== null ? this.state.image : null,
            status: 1,
            deposit: parseFloat(this.state.deposit),
            pricingMin: parseFloat(this.state.pricingMin),
            pricingMax: parseFloat(this.state.pricingMax),
            time: this.state.time,
            featured: this.state.featured,
            categoryId: this.state.selectedCategory !== null ? this.state.selectedCategory.value : null,
            isRecurring: this.state.isRecurring
        }

        if (
            !!data.title &&
            data.deposit !== 0 &&
            data.pricingMin !== 0 &&
            data.pricingMax !== 0 &&
            !!data.time
        ) {
            if (parseFloat(data.pricingMin) < parseFloat(data.pricingMax)) {

                if (data.deposit >= 50) {
                    if (data.time % 30 === 0) {
                        this.setState({ errorAmount: false, errorRequired: false, errorDeposit: false, errorDuration: false });
                        console.log(data);
                        this.props.postFlash(data).then(() => this.props.history.push("/admin/flashes/myflashes"));
                    } else {
                        this.setState({ errorAmount: false, errorRequired: false, errorDeposit: false, errorDuration: true });
                    }

                } else {
                    this.setState({ errorAmount: false, errorRequired: false, errorDeposit: true, errorDuration: false });
                }


            } else {
                // Error minimum amount must be smaller than maximum amount
                this.setState({ errorAmount: true, errorRequired: false, errorDeposit: false, errorDuration: false });
            }
        } else {
            // All required fields must be filled
            this.setState({ errorAmount: false, errorRequired: true, errorDeposit: false, errorDuration: false });
        }
    }

    handleFeatured() {
        this.setState({ featured: !this.state.featured });
    }

    handleIsRecurring() {
        this.setState({ isRecurring: !this.state.isRecurring });
    }

    handleCategories() {
        let data = [];
        data.push({ value: null, label: 'No category' });
        this.state.categories.map(item => {
            return data.push({ value: item.categoryId, label: item.name });
        });
        return data;
    }

    handleCategoriesSelection(data) {
        this.setState({ selectedCategory: data });
    }

    render() {
        let uploadBtnText = this.state.image !== null ? 'Modify image' : 'Upload Image';
        return (
            <section className="addFlashes">
                {
                    <>
                        <Loading isLoading={this.props.isFetching || this.props.uploadResponse.isFetching} />
                        <div>
                            <h1>Add a flash</h1>
                            <h2>Information</h2>
                            <div className="field-container">
                                <div className={(this.state.errorRequired && !this.state.title) ? "input-container error" : "input-container"}>
                                    <label>Name *</label>
                                    <input type="text" name="title" onChange={(text) => this.handleInputChange(text)} placeholder={"Name"} />
                                </div>

                                <div className="input-container">
                                    <label>Description</label>

                                    <div className="wysiwyg-container">
                                        <Editor
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.handleWysiwygChange}
                                        />
                                        {/* <div dangerouslySetInnerHTML={{__html: this.state.flash.description}}></div> */}
                                    </div>
                                </div>

                                <div className="input-container">
                                    <label>Image</label>
                                    <p className="details">Recommended size (500 x 500)</p>
                                    {
                                        <img className={this.state.image !== null ? 'visible image-upload-preview' : 'image-upload-preview'} src={this.state.image !== null ? this.state.image.imageUrl : null} />
                                    }
                                    <div className="buttons-container">
                                        <label className="upload-button" htmlFor="flash-image-upload">{uploadBtnText}</label><input id="flash-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                        {this.state.image !== null && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                    </div>
                                </div>

                                <div className="input-container">
                                    <label>Category</label>
                                    {
                                        this.state.categories !== null ? <Dropdown options={this.handleCategories()} onChange={(item) => this.handleCategoriesSelection(item)} value={this.state.selectedCategory} placeholder="Select an category" /> :
                                            <h1>Loading categories</h1>
                                    }
                                </div>

                                <div className={(this.state.errorRequired && this.state.time === 0) || this.state.errorDuration ? "input-container error" : "input-container"}>
                                    <label>Appointment duration *</label>
                                    <p className="details">Necessary appointment duration in increments of 30 minutes.<br />Example: 120 (for 2 hours) </p>
                                    <input
                                        type="number"
                                        min="0"
                                        name="time"
                                        onChange={(text) => this.handleInputChange(text)}
                                        placeholder={"Duration"}
                                        value={this.state.time.toString()}
                                    />
                                </div>

                            </div>

                            <h2>Pricing</h2>
                            <div className="field-container">

                                <div className={((this.state.errorRequired && parseFloat(this.state.deposit) === 0) || this.state.errorDeposit) ? "input-container error" : "input-container"}>
                                    <label>Online deposit *</label>
                                    <p className="details">Minimum of 50.00$<br />This amount will be paid online.</p>
                                    <input
                                        type="number"
                                        min="50"
                                        name="deposit"
                                        onChange={(text) => this.handleInputChange(text)}
                                        placeholder={"Deposit"}
                                        value={this.state.deposit}
                                    />
                                </div>


                                <div className={(this.state.errorAmount || (this.state.errorRequired && parseFloat(this.state.pricingMin) === 0)) ? "input-container error" : "input-container"}>
                                    <label>Minimum cost *</label>
                                    <p className="details">Excludes the Online deposit<br />This amount will be paid in store.</p>
                                    <input
                                        type="number"
                                        min="0"
                                        name="pricingMin"
                                        onChange={(text) => this.handleInputChange(text)}
                                        placeholder={"Minimum cost"}
                                        value={this.state.pricingMin.toString()}
                                    />
                                </div>

                                <div className={(this.state.errorAmount || (this.state.errorRequired && parseFloat(this.state.pricingMax) === 0)) ? "input-container error" : "input-container"}>
                                    <label>Maximum cost *</label>
                                    <p className="details">Excludes the Online deposit<br />This amount will be paid in store.</p>
                                    <input
                                        type="number"
                                        min="0"
                                        name="pricingMax"
                                        onChange={(text) => this.handleInputChange(text)}
                                        placeholder={"Maximum cost"}
                                        value={this.state.pricingMax.toString()}
                                    />
                                </div>

                                <div className="input-container">
                                    <label>Currency</label>
                                    <div className="columns">
                                        {
                                            (!!this.props.currentUser && this.props.currentUser.data.currency !== null) ?
                                                <div className="input currency">
                                                    <p>{this.props.currentUser.data.currency.toUpperCase()}</p>
                                                </div>
                                                :
                                                <div className="input currency">
                                                    <p>CAD</p>
                                                </div>
                                        }

                                        <Link className="button" to="/admin/settings/advanced">
                                            <span>Change currency</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>




                            <h2>Details</h2>
                            <div className="field-container">
                                <div className="input-container">
                                    <label>Featured</label>
                                    <p className="details">Display the flash on the homepage.</p>

                                    <div>
                                        {
                                            <div className="radio-container">

                                                <div className="radio-item">
                                                    <input type="radio" id="radio-featured-yes" checked={this.state.featured} onChange={() => this.handleFeatured()} />
                                                    <label htmlFor="radio-featured-yes" className="radio-label">Yes</label>
                                                </div>

                                                <div className="radio-item">
                                                    <input type="radio" id="radio-featured-no" checked={!this.state.featured} onChange={() => this.handleFeatured()} />
                                                    <label htmlFor="radio-featured-no" className="radio-label">No</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>


                                <div className="input-container">
                                    <label>Unique</label>
                                    <p className="details">Will this flash be sold only once?</p>
                                    <div>
                                        {
                                            <div className="radio-container">

                                                <div className="radio-item">
                                                    <input type="radio" id="radio-recurring-yes" checked={!this.state.isRecurring} onChange={() => this.handleIsRecurring()} />
                                                    <label htmlFor="radio-recurring-yes" className="radio-label">Yes</label>
                                                </div>

                                                <div className="radio-item">
                                                    <input type="radio" id="radio-recurring-no" checked={this.state.isRecurring} onChange={() => this.handleIsRecurring()} />
                                                    <label htmlFor="radio-recurring-no" className="radio-label">No</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>



                            <div className="error-container">
                                {!!this.state.errorAmount &&
                                    <p className="error">The "In store minimum amount" must be smaller than the "In store maximum amount"</p>
                                }

                                {!!this.state.errorRequired &&
                                    <p className="error">All * fields are required. </p>
                                }

                                {!!this.state.errorDeposit &&
                                    <p className="error">The deposit amount must be over 50.00 $</p>
                                }

                                {!!this.state.errorDuration &&
                                    <p className="error">The duration must be a 30 minutes increment</p>
                                }

                            </div>
                            <button onClick={() => this.handleSubmit()}>Add flash</button>
                        </div>
                    </>
                }
            </section>
        );
    }
}

const mapStateToProps = state => ({
    flash: state.flash.flash,
    isFetching: state.flash.isFetching,
    categories: state.category.categories,
    uploadResponse: state.upload,
});

export default connect(mapStateToProps, { postFlash, fetchCurrentUserCategories, upload })(AddFlashes);