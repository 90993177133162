import React, { Component } from 'react';

class Loading extends Component {

    constructor() {
        super();
        this.state = {
            isDisplayed: false,
            isActive: false
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.isLoading !== this.props.isLoading) {

            if (this.props.isLoading) {
                this.setState({
                    isDisplayed: true,
                    isActive: true
                });

            } else { 
                const scope = this;
                setTimeout(function () {
                    scope.setState({ isActive: false })
                }, 500);

                setTimeout(function () {
                    scope.setState({ isDisplayed: false })
                }, 1000 );
                
            }
        }
    }

    render(props) {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        const isEdge = !isIE && !!window.StyleMedia;

        if (this.state.isDisplayed) {
            return (
                <div className={this.state.isActive ? "active loading-container" : "loading-container"}>

                    {// Edge doesn't support the SVG animated loading
                        (isEdge || isIE) &&
                        <div className="centerer">
                            <p className="Loading-edge">Loading...</p>
                        </div>
                    }

                    {(!isEdge && !isIE) &&
                        <div className="centerer">
                            <svg id="agenda-svg" viewBox="0 0 326.06 375.15">
                                <path className="agenda-base-path path" d="M140.78,57.3c-9.56,0-14.81,6.36-17.39,11.07a17.33,17.33,0,0,0-2.09,8.35V99.28a3.5,3.5,0,0,1-3.5,3.5h-9c-13.41,0-13.41,23.18,0,23.18h9a3.5,3.5,0,0,1,3.49,3.49v20a3.46,3.46,0,0,1-3.47,3.46h-8.76c-13.7,0-13.7,23.55,0,23.55h8.69A3.55,3.55,0,0,1,121.3,180v19.93a3.43,3.43,0,0,1-3.43,3.42h-9.09c-13.41,0-13.41,22.67,0,22.67h9a3.54,3.54,0,0,1,3.54,3.54v20.55a3.47,3.47,0,0,1-3.47,3.47h-9c-13.34,0-13.41,22.7,0,22.7h9a3.48,3.48,0,0,1,3.49,3.48v20.33a3.37,3.37,0,0,1-3.37,3.37h-9.15c-13.41,0-13.41,23.23-.3,23.23h9.32a3.5,3.5,0,0,1,3.5,3.5v20.12a3.37,3.37,0,0,1-3.36,3.36h-9.25c-13.32,0-13.32,24.11.09,24.11h9.09a3.35,3.35,0,0,1,3.35,3.35v20.48c0,12.74,7.11,19.85,26.08,19.85H372.11c24.45,0,41.63-16,41.63-39.7V95.22c0-20.74-15.84-37.92-41.47-37.92Z" transform="translate(-93.18 -51.8)" />
                                <rect className="rectangle-path path" x="100.56" y="67.72" width="174.37" height="75.26" rx="14" />
                                <line className="line-1-path path" x1="129.04" y1="94.99" x2="244.59" y2="94.99" />
                                <line className="line-2-path path" x1="150.15" y1="115.84" x2="219.48" y2="115.84" />
                                <line className="line-3-path path" x1="28.11" y1="47.48" x2="28.04" y2="329.31" />
                                <line className="line-4-path path" x1="52.93" y1="5.5" x2="52.93" y2="369.65" />
                            </svg>
                        </div>
                    }
                </div>
            );
        } else {
            return (null);
        }
    }
}

export default Loading; 