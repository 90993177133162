import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createWebsite } from '../../../../actions';
import Loading from "../../partials/loading";

class WebsiteCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siteUrl: '',
            error: false,
            errorDuplicate: false,
        };
    }
    handleChange(event) {
        if(this.state.error || this.state.errorDuplicate){
            this.setState({error: false, errorDuplicate: false});
        }
        var lowerValue = event.target.value;
        this.setState({siteUrl: lowerValue.toLowerCase()});
    }

    validateSlug(data) {
        var re = /^[a-z0-9-]+$/;
        return re.test(String(data).toLowerCase());
    }
    
    handleCreateSite() {
        if(this.state.siteUrl === "" || !this.validateSlug(this.state.siteUrl)){
            this.setState({error: true});
        }else{
            let data = {
                url: this.state.siteUrl,
                theme: 'canva',
                logo: null,
                primaryColor: '#ffffff',
                secondaryColor: '#eb1d22',
                status: 1
            }
            this.props.createWebsite(data).then(() => this.handleErrorCheck());
        }
    }

    handleErrorCheck() {
        if(this.props.websiteCreation.statusCode == 2){
            this.setState({errorDuplicate: true});
        }
        else if(this.props.websiteCreation.statusCode == 3){
            alert("You already have a website");
        }else{
            this.props.isWebsiteCreated(true);
        }
    }
    
	render() {
		return (
            <div className={"container"}>
                <div className={"imgContainer"}>
                    <img src={require("../../../../assets/images/dashboard/logos/bookmeink-logo@2x.png")} alt="Logo BookMeInk" />
                </div>
                <div className={"stepFirstLogin"}>
                     <h3>1 / 3</h3>
                </div>
                <Loading isLoading={this.props.isFetching} />
                <h1>Add your website url</h1>
                <p>To create your website you need to give it a name! Write down the name you want to give to your website on the bookme.ink platform. The name you choose will be displayed as followed: app.bookme.ink/[your url].</p>
                <div className={this.state.error || this.state.errorDuplicate ? "field-container error" : "field-container"}> 
                    <input type="text" name="websiteInput" value={this.state.siteUrl} onChange={(text) => this.handleChange(text)}/>
                </div>
                {
                    (this.state.error || this.state.errorDuplicate) && <div className={"error-container"} style={{paddingBottom: 25}}>{this.state.error && <p className={"error"}>*Please enter a correct website url.</p>}{this.state.errorDuplicate && <p className={"error"}>*Website url already taken.</p>}</div>
                }
                <button className={"button"} onClick={() => this.handleCreateSite()}>Create</button>
                <button className={"button logout"} onClick={() => this.props.auth.logout()}>Logout</button>
            </div>
		);
	}
}

const mapStateToProps = state => ({
    websiteCreation: state.websiteInfo.websiteCreation,
    isFetching: state.websiteInfo.isFetching
});

export default connect(mapStateToProps, { createWebsite })(WebsiteCreate);