import { CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE } from '../actions/types';

const initialState = {
    auth: null,
	isFetching: false,
	errors: null
}

export default function(state = initialState, action) {
	switch(action.type) {
        case CREATE_USER_REQUEST:
            return { ...state, isFetching: true };
        case CREATE_USER_SUCCESS:
            return { ...state, isFetching: false, auth: action.payload };
        case CREATE_USER_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;
    }
}