import React, { Component } from 'react';
import { fetchFlashes } from '../../actions';
import _ from "lodash";
import { connect } from 'react-redux';

// Components
import BannerLarge from "./sections/section.banner-large";
import ProductsListing from "./sections/section.products-listing";
import { animateScroll as scroll } from 'react-scroll'

class Sites extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.bannerData = {
			title: "Your website",
			subtitle: "Your subtitle",
			background: null,
			button: "View all flash",
			buttonUrl: this.props.siteUrl + "/flashes",
			showButton: true
		};

		this.productsListingData = {
			title: "Highlights",
			errorMsg: "There are no products available.",
			categories: false,
			featured: true,
			isLoading: false,
			products: []
		}
	}

	componentDidMount(props) {
		scroll.scrollToTop();
		const siteUrl = this.props.match.params.site;
		if (_.isEmpty(this.props.flash)) {
			this.setState({ isLoading: true });
			this.props.fetchFlashes(siteUrl);
		} else {
			this.setState({ isLoading: false });
		}
	}


	render(props) {


		// Define productsListingData products
		if (!_.isEmpty(this.props.flash)) {
			this.productsListingData.products = this.props.flash.data;
		}

		// Define components data 
		if (this.props.data !== null) {

			// bannerData
			if (!!this.props.data.content1) this.bannerData.title = this.props.data.content1;
			if (!!this.props.data.content2) this.bannerData.subtitle = this.props.data.content2;
			//if (!!this.props.data.content3) this.bannerData.button = this.props.data.content3;
			if (!!this.props.data.banner) this.bannerData.background = this.props.data.banner.imageUrl;
			this.bannerData.hideButton = this.props.data.boolean1;

			// productsListingData
			if (!!this.props.data.content5) this.productsListingData.title = this.props.data.content6;
			if (!!this.props.data.content6) this.productsListingData.errorMsg = this.props.data.content7;
		}

		this.productsListingData.isLoading = this.props.isFetching ? true : false
		return (
			<main className="page-home">			
				<BannerLarge data={this.bannerData} />
				<ProductsListing
					data={this.productsListingData}
					siteUrl={this.props.siteUrl}
					websiteInfo={this.props.websiteInfo}
					type="flash"
				/>
			</main>
		);
	}
}

const mapStateToProp = state => {
	return {
		flash: state.flash.flashes,
		websiteInfo: state.websiteInfo.websiteInfo.data,
		isFetching: state.flash.isFetching
	}
}

export default connect(mapStateToProp, { fetchFlashes })(Sites);