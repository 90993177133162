import React, { Component } from 'react';

export function FlashPlaceholder(props) {

    return (
        <div key={props.index} className="flashItemContainer" >
            <a>
                <div className="image-container">
                    <img height={180} width={180} src={require('../../../../assets/images/sites/placeholders/product-placeholder.png')} alt="Placeholder" />
                </div>

                <div className="details-container" style={{ height: "20px", width: "80px", backgroundColor: "gray" }}>
                </div>
            </a >
            <button className="delete-btn" disabled>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" stroke="black" strokeWidth="2" className="react-timerange-picker__clear-button__icon react-timerange-picker__button__icon"><line x1="4" y1="4" x2="15" y2="15"></line><line x1="15" y1="4" x2="4" y2="15"></line></svg>
            </button>

        </div >
    );
}

