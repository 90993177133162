import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCategoryById, putCategory, deleteCategory } from '../../actions';
import _ from 'lodash';
import Navigation from './partials/navigation';
import Loading from "./partials/loading";
import { Link } from 'react-router-dom';

class SingleCategory extends Component {
    state = {
        data: {
            id: this.props.match.params.id,
            name: null
        },
        attachToProduct: false
    }
    componentDidMount() {
        this.props.fetchCategoryById(this.props.match.params.id).then(() => this.handleSeedData());
    }
    handleSeedData() {
        let newData = this.state.data;
        newData.name = this.props.category.data.name;
        this.setState({
            data: newData,
            initialName: newData.name
        });
    }
    handleInputChange(event) {
        let newData = this.state.data;
        newData.name = event.target.value;
        this.setState({ data: newData });
    }
    handleSubmit() {
        if (this.state.data.name !== null && (this.state.initialName !== this.state.data.name) && !!this.state.data.name) {
            this.props.putCategory(this.state.data).then(() => this.setState({ attachToProduct: false }, function () { this.props.history.push("/admin/flashes/categories") }));
        }
    }
    handleDelete() {
        if (window.confirm("Are you sure you want to delete this category?")) {
            this.props.deleteCategory(this.props.match.params.id).then(() =>
                this.props.deleted.data.statusCode === 1215 ? this.setState({ attachToProduct: true }) : this.props.history.push("/admin/flashes/categories")
            );
        }
    }
    render() {
        return (
            <div className="dashboard-wrapper">
                {
                    /* --- Categories dans flash ou produit ? --- */
                    this.props.location.pathname === `/admin/flashes/category/${this.props.match.params.id}` && <Navigation subMenu={true} subMenuItems={[{ name: "My flashes", link: "/admin/flashes/myflashes" }, { name: "Add flash", link: "/admin/flashes/addflashes" }, { name: "Manage categories", link: "/admin/flashes/categories" }]} subMenuTitle={"Flashes"} />
                }
                <div className="dashboard-content-wrapper">
                    <section className="single-category">
                        <Loading isLoading={_.isEmpty(this.props.category) || this.props.isFetching} />

                        {!_.isEmpty(this.props.category) && !this.props.isFetching &&
                            <>
                                <Link className="back-btn" to="/admin/flashes/categories">
                                    <img className="checked-image" src={require('../../assets/images/dashboard/icons/back-arrow_4x.png')} />
                                    <span>Back to categories</span>
                                </Link>
                                <div className="field-container">
                                    <h1>Edit category</h1>
                                    <input type={"text"} name="name" defaultValue={this.state.data.name} onChange={(text) => this.handleInputChange(text)} />
                                    {
                                        this.state.attachToProduct && <p style={{ color: "red" }}>This category is attach to product/s or flash/es.</p>
                                    }
                                </div>

                                <button className={(this.state.initialName === this.state.data.name || !this.state.data.name) ? 'prevent-click' : ''} onClick={() => this.handleSubmit()}>Modifier</button>
                                <button onClick={() => this.handleDelete()}>Supprimer</button>
                            </>
                        }
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    category: state.category.category,
    isFetching: state.category.isFetching,
    deleted: state.category.deleted,
});

export default connect(mapStateToProps, { fetchCategoryById, putCategory, deleteCategory })(SingleCategory);