import { FETCHING_FLASHES_REQUEST, FETCHING_FLASHES_SUCCESS, FETCHING_FLASHES_FAILURE, FETCHING_FLASH_REQUEST, FETCHING_FLASH_SUCCESS, FETCHING_FLASH_FAILURE, PUT_FLASH_REQUEST, PUT_FLASH_SUCCESS, PUT_FLASH_FAILURE, DELETE_FLASH_REQUEST, DELETE_FLASH_SUCCESS, DELETE_FLASH_FAILURE, POST_FLASH_REQUEST, POST_FLASH_SUCCESS, POST_FLASH_FAILURE } from '../actions/types';

const initialState = {
    flashes: null,
    flash: null,
    isFetching: false,
    errors: null
}

export default function (state = initialState, action) {
    switch (action.type) {

        // All flashes
        case FETCHING_FLASHES_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_FLASHES_SUCCESS:
            return { ...state, isFetching: false, flashes: action.payload };
        case FETCHING_FLASHES_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Single flash by ID
        case FETCHING_FLASH_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_FLASH_SUCCESS:
            return { ...state, isFetching: false, flash: action.payload };
        case FETCHING_FLASH_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Put flash by ID
        case PUT_FLASH_REQUEST:
            return { ...state, isFetching: true };
        case PUT_FLASH_SUCCESS:
            return { ...state, isFetching: false, flash: action.payload };
        case PUT_FLASH_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Delete flash by ID
        case DELETE_FLASH_REQUEST:
            return { ...state, isFetching: true };
        case DELETE_FLASH_SUCCESS:
            return { ...state, isFetching: false, flash: action.payload };
        case DELETE_FLASH_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Post flash by ID 
        case POST_FLASH_REQUEST:
            return { ...state, isFetching: true };
        case POST_FLASH_SUCCESS:
            return { ...state, isFetching: false, flash: action.payload };
        case POST_FLASH_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        default:
            return state;
    }
}