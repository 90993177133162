import { combineReducers } from 'redux';
import flashReducer from './flashReducer';
import pageReducer from './pageReducer';
import stripeReducer from './stripeReducer';
import userReducer from './userReducer';
import authReducer from './authReducer';
import websiteInfoReducer from './websiteInfoReducer';
import googleReducer from './googleReducer';
import availabilityReducer from './availabilityReducer';
import uploadReducer from './uploadReducer';
import categoryReducer from './categoryReducer';
import emailReducer from './emailReducer';
import productReducer from './productReducer';
 
export default combineReducers({
	flash: flashReducer,
	stripe: stripeReducer,
	user: userReducer,
	auth: authReducer,
	page: pageReducer,
	websiteInfo: websiteInfoReducer,
	googleCalendar: googleReducer,
	availability: availabilityReducer,
	upload: uploadReducer,
	category: categoryReducer,
	email: emailReducer,
	product: productReducer
});