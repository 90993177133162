import { FETCHING_PRODUCTS_REQUEST, FETCHING_PRODUCTS_SUCCESS, FETCHING_PRODUCTS_FAILURE, FETCHING_PRODUCT_REQUEST, FETCHING_PRODUCT_SUCCESS, FETCHING_PRODUCT_FAILURE, PUT_PRODUCT_REQUEST, PUT_PRODUCT_SUCCESS, PUT_PRODUCT_FAILURE, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILURE, POST_PRODUCT_REQUEST, POST_PRODUCT_SUCCESS, POST_PRODUCT_FAILURE } from '../actions/types';

const initialState = {
    products: null,
    product: null,
    isFetching: false,
    errors: null
}

export default function (state = initialState, action) {
    switch (action.type) {

        // All products
        case FETCHING_PRODUCTS_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_PRODUCTS_SUCCESS:
            return { ...state, isFetching: false, products: action.payload };
        case FETCHING_PRODUCTS_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Single product by ID
        case FETCHING_PRODUCT_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_PRODUCT_SUCCESS:
            return { ...state, isFetching: false, product: action.payload };

        case FETCHING_PRODUCT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Put product by ID
        case PUT_PRODUCT_REQUEST:
            return { ...state, isFetching: true };
        case PUT_PRODUCT_SUCCESS:
            return { ...state, isFetching: false, product: action.payload };
        case PUT_PRODUCT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Delete product by ID
        case DELETE_PRODUCT_REQUEST:
            return { ...state, isFetching: true };
        case DELETE_PRODUCT_SUCCESS:
            return { ...state, isFetching: false, product: action.payload };
        case DELETE_PRODUCT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Post product by ID 
        case POST_PRODUCT_REQUEST:
            return { ...state, isFetching: true };
        case POST_PRODUCT_SUCCESS:
            return { ...state, isFetching: false, product: action.payload };
        case POST_PRODUCT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        default:
            return state;
    }
}