import React, { Component } from 'react';

import BannerSmall from "./sections/section.banner-small";
import ContactForm from "./sections/section.contact-form";
import { animateScroll as scroll } from 'react-scroll'


class SitesContact extends Component {
	constructor() {
		super();
		this.state = {}

		this.bannerData = {
			title: "Contact",
			background: null
		};

		this.contentData = {
			text: null
		};
	}

	componentDidMount() {
		scroll.scrollToTop();
	}

	render() {
		// Define components data
		if (this.props.data !== null) {

			// bannerData
			if (!!this.props.data.content1) this.bannerData.title = this.props.data.content1;
			if (!!this.props.data.banner) this.bannerData.background = this.props.data.banner.imageUrl;

			// contentData
			if (!!this.props.data.content2) this.contentData.text = this.props.data.content2;
		}

		return (
			<main className="page-contact">
				<BannerSmall data={this.bannerData} />
				<ContactForm data={this.contentData} siteUrl={this.props.siteUrl} />
			</main>
		);
	}
}


export default SitesContact;