import React, { Component } from 'react';
import { postContactEmail } from '../../../actions';
import _ from "lodash";
import { connect } from 'react-redux';
import Loading from "../partials/partial.loading";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            subject: '',
            email: '',
            message: '',
            file: null,
            mailSent: false,
            error: "",
            errorName: false,
            errorSubject: false,
            errorEmail: false,
            errorMessage: false,
            errorSend: false
        };


        this.handleFileChange = this.handleFileChange.bind(this)
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (this.state.error !== "") {
                if (this.state.errorName && this.state.name !== "") {
                    this.setState({ errorName: false });
                }

                if (this.state.errorSubject && this.state.subject !== "") {
                    this.setState({ errorSubject: false });
                }

                if (this.state.errorEmail && this.state.email !== "") {
                    this.setState({ errorEmail: false });
                }

                if (this.state.errorMessage && this.state.message !== "") {
                    this.setState({ errorMessage: false });
                }
            }
        });
    }

    handleFormSubmit = (e) => {

        e.preventDefault();

        function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        if (this.state.name === "" || this.state.subject === "" || this.state.email === "" || this.state.message === "") {
            this.setState({ error: "You are required to fill all the fields. Please do so before submitting the form." })

            if (this.state.name === "") {
                this.setState({ errorName: true });
            }

            if (this.state.subject === "") {
                this.setState({ errorSubject: true });
            }

            if (this.state.email === "") {
                this.setState({ errorEmail: true });
            }

            if (this.state.message === "") {
                this.setState({ errorMessage: true });
            }

        } else if (!validateEmail(this.state.email)) {
            this.setState({ errorEmail: true, error: "The email address is invalid." });

        } else {

            
            let sendEmail = (fileToBase64) => {
                this.setState({ error: "" }, () => {

                    let emailData = {
                        name: this.state.name,
                        email: this.state.email,
                        subject: this.state.subject,
                        message: this.state.message,
                        file: fileToBase64
                    }

                    this.props.postContactEmail(emailData, this.props.siteUrl).then(() => {

                        if (this.props.emailResponse !== undefined && this.props.emailResponse.data.statusCode === 1) {
                            this.setState({ mailSent: true, errorSend: false })
                        } else {
                            this.setState({ errorSend: true })
                        }

                    })
                })
            }


            if (this.state.file !== null) {
                var reader = new FileReader();
                reader.readAsBinaryString(this.state.file);

                reader.onload = () => {
                    let fileToBase64 = {
                        base64 : btoa(reader.result),
                        type : this.state.file.type
                    }

                    sendEmail(fileToBase64);
                };

                reader.onerror = function () {
                    sendEmail(null);
                };

            } else {
                sendEmail(null);
            }







        }
    };


    handleFileChange(e) {
        if (e.target.files[0].size <= 2097152) {
            console.log(e.target.files[0]);
            this.setState({ file: e.target.files[0] });
        } else {
            alert("The file is too large")
        }
    }

    handleDeleteFile() {
        this.setState({ file: null });
    }

    render(props) {

        if (!!this.props.data) {

            return (
                <>
                    <Loading isLoading={this.props.isFetching} />
                    <section className="contact-form">
                        <div className="wrapper">

                            <div className="paragraphs-container">
                                <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: this.props.data.text }}></div>
                            </div>

                            {!this.state.mailSent &&
                                <form action="http://localhost:3000/contact/form.php" className="form">

                                    <input
                                        className={this.state.errorName ? "invalid" : ""}
                                        type="text"
                                        name="name"
                                        autoComplete="off"
                                        placeholder={"Name"}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <input
                                        className={this.state.errorEmail ? "invalid" : ""}
                                        type="email"
                                        name="email"
                                        placeholder={"Email"}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        required
                                    />
                                    <input
                                        className={this.state.errorSubject ? "invalid" : ""}
                                        type="text"
                                        name="subject"
                                        autoComplete="off"
                                        placeholder={"Subject"}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <textarea
                                        className={this.state.errorMessage ? "invalid" : ""}
                                        name="message"
                                        placeholder={"Message"}
                                        onChange={this.handleChange}
                                        required
                                    />

                                    <div className="buttons-container">

                                        {this.state.file !== null ?
                                            <button className="button upload-button" onClick={() => this.handleDeleteFile()}>Remove image</button>
                                            :
                                            <> 
                                                <label className="upload-button button" htmlFor="product-image-upload">Add image</label>
                                                <input id="product-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                            </>
                                        }
                                    </div>

                                    {!!this.state.error && <p className="error">{this.state.error}</p>}

                                    {
                                        this.state.errorSend &&
                                        <p className="error">We were unable to send your email. Please try again later.</p>
                                    }

                                    <input
                                        type="submit"
                                        onClick={e => this.handleFormSubmit(e)}
                                        value="Submit"
                                    />


                                </form>
                            }

                            {this.state.mailSent &&
                                <div className='success-container'>
                                    <p className="success">Your email was successfully sent.</p>
                                </div>
                            }
                        </div>
                    </section >
                </>
            );

        } else { return null; }
    }
}


const mapStateToProp = state => {
    return {
        emailResponse: state.email.emailResponse,
        isFetching: state.email.isFetching
    }
}

export default connect(mapStateToProp, { postContactEmail })(ContactForm);