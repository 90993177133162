import React, { Component } from 'react';
import { fetchFlashes } from '../../actions';
import _ from "lodash";

import { connect } from 'react-redux';
import { animateScroll as scroll} from 'react-scroll'

// import partials
import BannerSmall from "./sections/section.banner-small";
import ProductsListing from "./sections/section.products-listing";


class SitesFlashes extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.productsListingData = {
			title: "",
			categories: true,
			featured: false,
			isLoading: false,
			products: [], 
			errorMsg: "No flashes found."
		};

		this.bannerData = null;
	}

	componentDidMount() {
		scroll.scrollToTop();
		const siteUrl = this.props.match.params.site;
		if (_.isEmpty(this.props.flash)) {
			this.setState({ isLoading: true });
			this.props.fetchFlashes(siteUrl, false);
		} else {
			this.setState({ isLoading: false });
		}
	}


	render(props) {

		// Define productsListingData products
		if (!_.isEmpty(this.props.flash)) {
			this.productsListingData.products = this.props.flash.data;
		}

		// Define bannerData
		if (!!this.props.data && !this.bannerData) {
			if (!!this.props.data.content1 || !!this.props.data.banner) this.bannerData = {};
			if (!!this.props.data.content1) this.bannerData.title = this.props.data.content1;
			if (!!this.props.data.banner) this.bannerData.background = this.props.data.banner.imageUrl;
		}

		this.productsListingData.isLoading = this.props.isFetching ? true : false

		return (
			<main className="page-flashes">
				<BannerSmall data={this.bannerData} />
				<ProductsListing
					data={this.productsListingData}
					siteUrl={this.props.siteUrl}
					websiteInfo={this.props.websiteInfo}
					type="flash"
				/>
			</main>
		);
	}
}

const mapStateToProp = state => {
	return {
		flash: state.flash.flashes,
		websiteInfo: state.websiteInfo.websiteInfo.data,
		isFetching: state.flash.isFetching
	}
}

export default connect(mapStateToProp, { fetchFlashes })(SitesFlashes);


