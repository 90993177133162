import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Content404 extends Component {
    render(props) {
        return (
            <section className="content-404">
                <div className="wrapper">
                    <h2>The page you have requested does not exist.</h2>
                    {
                        (typeof this.props.websiteInfo.data !== 'string' && this.props.websiteInfo.data.status === 1) &&
                        <Link to={`/${this.props.websiteInfo.data.url}`}> 
                            <button className="button">Go to homepage</button>
                        </Link>
                    }
                </div>
            </section>
        );
    }
}

export default Content404;