import { CALENDAR_EXCHANGE_CODE_REQUEST, CALENDAR_EXCHANGE_CODE_SUCCESS, CALENDAR_EXCHANGE_CODE_FAILURE, FETCHING_CALENDAR_REQUEST, FETCHING_CALENDAR_SUCCESS, FETCHING_CALENDAR_FAILURE, POSTING_CALENDAR_EVENT_REQUEST, POSTING_CALENDAR_EVENT_SUCCESS, POSTING_CALENDAR_EVENT_FAILURE, UNLINK_CALENDAR_REQUEST, UNLINK_CALENDAR_SUCCESS, UNLINK_CALENDAR_FAILURE } from '../actions/types';

const initialState = {
    addGoogle: null,
    calendar: null,
    isFetching: false,
    eventConfirmation: null,
    unlink: {},
    errors: null,
    isPosting: false
}

export default function (state = initialState, action) {
    switch (action.type) {

        // Post Google Calendar token
        case CALENDAR_EXCHANGE_CODE_REQUEST:
            return { ...state, isFetching: true };
        case CALENDAR_EXCHANGE_CODE_SUCCESS:
            return { ...state, isFetching: false, addGoogle: action.payload };
        case CALENDAR_EXCHANGE_CODE_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Get Google calendar
        case FETCHING_CALENDAR_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_CALENDAR_SUCCESS:
            return { ...state, isFetching: false, calendar: action.payload };
        case FETCHING_CALENDAR_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        // Post Google calendar event
        case POSTING_CALENDAR_EVENT_REQUEST:
            return { ...state, isPosting: true };
        case POSTING_CALENDAR_EVENT_SUCCESS:
            return { ...state, isPosting: false, eventConfirmation: action.payload };
        case POSTING_CALENDAR_EVENT_FAILURE:
            return { ...state, isPosting: false, errors: action.payload };

        // Unlink Google calendar
        case UNLINK_CALENDAR_REQUEST:
            return { ...state, isFetching: true };
        case UNLINK_CALENDAR_SUCCESS:
            return { ...state, isFetching: false, unlink: action.payload };
        case UNLINK_CALENDAR_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };

        default:
            return state;
    }
}