import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SitesFooter extends Component {

	constructor() {
		super();
		this.state = {}
		this.menuItems = null;

	}

	render() {

		/* GENERATE MENU ITEMS */
		this.menuItems = this.generateMenuItems(this.props.data.pages);

		/* RETURN */
		return (
			<footer>
				<div className='wrapper'>
					<div className='row'>




						<a className="logo-container" href="https://bookme.ink/" target="_blank" rel="noopener noreferrer">
							<img className="logo" src={require('../../../assets/images/sites/logos/bookmeink-logo@2x.png')} alt="BookMeInk" />
						</a>


						<div className="navigation-container">
							<nav className='footer-navigation'>
								{!!this.menuItems && <ul>{this.menuItems}</ul>}
							</nav>


							{(!!this.props.data.facebookURL || !!this.props.data.instagramURL) &&
								<div className="social-medias-container">
									<ul>
										{!!this.props.data.instagramURL &&
											<li>
												<a href={this.props.data.instagramURL} target="_blank" rel="noopener noreferrer">
													<img
														src={require('../../../assets/images/sites/icons/instagram-header-black@2x.png')}
														alt="instagram"
													/>
												</a>
											</li>
										}
										{!!this.props.data.facebookURL &&
											<li>
												<a href={this.props.data.facebookURL} target="_blank" rel="noopener noreferrer">
													<img
														src={require('../../../assets/images/sites/icons/facebook-header-black@2x.png')}
														alt="instagram"
													/>
												</a>
											</li>
										}
									</ul>
								</div>
							}
						</div>
					</div>

					<div className='row'>
						<p className="copyright">Copyright BookMe.Ink</p>
					</div>
				</div>
			</footer>
		);
	}


	/* GENERATE MENU ITEMS HELPER */
	generateMenuItems(pages) {
		if (!!pages) {

			// Remove pages that must not be in the main navigation
			let filteredPages = [];
			const pagesToRemove = [
				"homepage",
				"flashes",
				"shop",
				"products"
			];

			for (var i = pages.length - 1; i >= 0; i--) {
				if (pagesToRemove.indexOf(pages[i].type) > -1) {
					continue;
				} else {
					filteredPages.push(pages[i]);
				}
			}

			// Re-order the pages
			if (filteredPages.length >= 2) {
				const pagesOrder = [
					"terms-and-conditions",
					"about",
					"contact"
				];

				filteredPages.sort((a, b) => {
					const aPriority = pagesOrder.indexOf(a.type);
					const bPriority = pagesOrder.indexOf(b.type);

					if (aPriority && bPriority === -1) {
						console.error(`Types ${a.type} and ${b.type} can't be ordered. Please review the pagesOrder array in components/navigation/header.js`);
						return null;

					} else {
						const output = (aPriority > bPriority) ? 1 : -1;
						return output;
					}
				});
			}

			// Create the items
			let menuItemsArray = filteredPages.map((pItem, key) => {
				switch (pItem.type) {
					case "terms-and-conditions":
						return <li key={key}><Link to={`/${this.props.data.siteUrl}/terms-and-conditions`} >{pItem.name}</Link></li>
					case "about":
						return <li key={key}><Link to={`/${this.props.data.siteUrl}/about`} >{pItem.name}</Link></li>
					case "contact":
						return <li key={key}><Link to={`/${this.props.data.siteUrl}/contact`} >{pItem.name}</Link></li>
					default:
						console.error(`The pagetype ${pItem.type} isn't supported in the footer menu`);
						return null;
				}
			});

			return menuItemsArray;
		} else { return null; }
	}
}

export default SitesFooter;