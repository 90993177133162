import React, { Component } from 'react';

class CategoryItem extends Component {
    render(props) {
        return (
            <button
                className={this.props.isSelected ? "category-item button active" : "category-item button"}
                onClick={this.props.actionOnClick}
            >
                <p>{this.props.category === null ? 'All' : this.props.category.name}</p>
            </button>
        );
    }
}

export default CategoryItem; 