import React, { Component } from 'react';
import { connect } from 'react-redux';
import { upload, editWebsite, fetchCurrentUserWebsiteInfo } from '../../../actions';
import _ from 'lodash';
import Loading from "./loading";
import Auth from '../../../auth/authService';
import Dropdown from 'react-dropdown';
const countryJson = require('countrycitystatejson');



const auth = new Auth();

class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            website: {
                logo: null,
                address: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    postal: "",
                    country: null,
                    province: null
                },
                phoneNumber: ""
            
            },
            logoChange: false,
            provinceOptions: [],
            countryOptions: [],
            errorMessage: "",
            errorCountry: false,
            errorName: false,
            errorAddressLine1: false,
            errorCity: false,
            errorProvince: false,
            errorPostal: false,
            errorPhone: false
        }
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    componentDidMount() {
        this.props.fetchCurrentUserWebsiteInfo().then(() => {
            let country = this.props.websiteInfo.websiteInfo.data[0].address.country

            this.setState({
                website: this.props.websiteInfo.websiteInfo.data[0],
                countryOptions: this.getCountries(),
                provinceOptions: !!country ? this.getProvinceOptions(this.props.websiteInfo.websiteInfo.data[0].address.country) : []
            })
        }
        );
    }


    getCountries() {
        let countries = [];
        const list = countryJson.getAll();
        for (const countryItem in list) {
            countries.push({ value: countryItem, label: list[countryItem].name })
        }
        return countries;
    }


    handleFileChange(e) {
        this.setState({ file: e.target.files[0] }, function () {
            this.fileUpload();
        });
    }


    handleLogoDelete() {
        const newData = this.state.website;
        newData.logo.imageUrl = null;
        this.setState({ website: newData, logoChange: true });
    }


    fileUpload() {
        const formData = new FormData();
        formData.append('files', this.state.file);
        this.props.upload(formData).then(() => this.handleLogoUploadResponse());
    }


    handleLogoUploadResponse() {
        let siteData = this.state.website;
        if (siteData.logo === null) {
            siteData.logo = {};
        }
        siteData.logo.imageUrl = this.props.uploadResponse.fileUrl
        this.setState({ website: siteData }, function () {
            this.setState({ logoChange: true });
        });
    }


    handleInputChange(event) {
        const newWebsiteData = this.state.website;
        if (event.target.name === 'addressLine1' || event.target.name === 'addressLine2' || event.target.name === 'city' || event.target.name === 'postal') {
            newWebsiteData.address[event.target.name] = event.target.value;
        } else {
            newWebsiteData[event.target.name] = event.target.value;
        }
        this.setState({ website: newWebsiteData }, () => console.log(this.state));
    }


    handleCountrySelection(item) {
        let provinceOptions = this.getProvinceOptions(item.value)
        let newWebsiteData = this.state.website;
        newWebsiteData.address.country = item.value
        this.setState({
            website: newWebsiteData,
            provinceOptions: provinceOptions,
            shippingProvince: this.state.website.address.country !== item.value ? null : this.state.website.address.province
        });
    }


    getProvinceOptions(pCountry) {
        let provinceOptions = [];
        const country = countryJson.getCountryByShort(pCountry);
        for (const provinceItem in country.states) {
            provinceOptions.push({ value: provinceItem, label: provinceItem })
        }
        return provinceOptions;
    }


    handleProvinceSelection(item) {
        let newWebsite = this.state.website;
        newWebsite.address.province = item.value
        this.setState({ website: newWebsite });
    }


    handleSubmit() {
        let siteData = this.state.website;

        if (!this.state.logoChange) {
            delete siteData.logo
        }

        this.props.editWebsite(siteData).then(() => this.props.fetchCurrentUserWebsiteInfo().then(() => {
            this.setState({
                website: this.props.websiteInfo.websiteInfo.data[0]
            });
        }));
    }

    handleVerifyInputFields() {

        function validatePhoneNumber(number) {
            var re = /^([+]?)((\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
            return re.test(String(number))
        }

        if (this.state.website.address.country === null || this.state.website.address.addressLine1 === "" || this.state.website.address.city === "" || this.state.website.address.postal === "" || !validatePhoneNumber(this.state.website.phoneNumber) || (this.state.provinceOptions.length > 0 && this.state.website.address.province === null)) {

            let errorMessage = "";
            let errorCountry = false;
            let errorProvince = false;
            let errorAddressLine1 = false;
            let errorCity = false;
            let errorPostal = false;
            let errorPhone = false;

            if (this.state.website.address.country === null) errorCountry = true;
            if (this.state.provinceOptions.length > 0 && this.state.website.address.province === null) errorProvince = true;
            if (this.state.website.address.addressLine1 === "") errorAddressLine1 = true;
            if (this.state.website.address.city === "") errorCity = true;
            if (this.state.website.address.postal === "") errorPostal = true;
            if (!validatePhoneNumber(this.state.website.phoneNumber)) errorPhone = true;

            const errorsArray = [errorCountry, errorProvince, errorAddressLine1, errorCity, errorPostal, errorPhone];
            var errorsCount = 0;
            for (var i = 0; i < errorsArray.length; ++i) {
                if (errorsArray[i] === true) errorsCount++;
            }

            if (errorsCount >= 2) {
                errorMessage = 'Please fill all the required fields.'
            } else if (errorCountry) {
                errorMessage = 'Please select a country.'
            } else if (errorProvince) {
                errorMessage = 'Please select a province / state.'
            } else if (errorAddressLine1) {
                errorMessage = 'Please enter the address.'
            } else if (errorCity) {
                errorMessage = "Please enter the city."
            } else if (errorPostal) {
                errorMessage = "Please enter the postal code."
            } else if (errorPhone) {
                errorMessage = "Please enter a valid phone number."
            }

            this.setState({
                errorCountry: errorCountry,
                errorProvince: errorProvince,
                errorAddressLine1: errorAddressLine1,
                errorCity: errorCity,
                errorPostal: errorPostal,
                errorPhone: errorPhone,
                errorMessage: errorMessage
            });

        } else {

            this.setState({
                errorCountry: false,
                errorProvince: false,
                errorAddressLine1: false,
                errorCity: false,
                errorPostal: false,
                errorPhone: false,
                errorMessage: '',
                isLoading: true
            }, () => {
                this.handleSubmit()
            });


        }
    }


    render() {
        let uploadBtnText = (this.state.website.logo !== undefined && this.state.website.logo !== null && this.state.website.logo.imageUrl !== null) ? 'Edit' : 'Upload';

        return (
            <>
                <Loading isLoading={!!this.props.websiteInfo.isFetching || !!this.props.uploadResponse.isFetching || _.isEmpty(this.props.websiteInfo.websiteInfo)} />
                {
                    <div>
                        <h1>General settings</h1>

                        <div className="field-container">
                            <h2>Store Address</h2>

                            <label for="address-line1-input">Address line 1 *</label>
                            <input
                                className={this.state.errorAddressLine1 ? "invalid" : ""}
                                id="address-line1-input"
                                type="text"
                                name="addressLine1"
                                placeholder={"Address (line 1)"}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                value={this.state.website.address.addressLine1}
                                required
                            />

                            <label for="address-line2-input">Address line 2</label>
                            <input
                                id="address-line2-input"
                                type="text"
                                name="addressLine2"
                                placeholder={"Address (line 2)"}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                value={this.state.website.address.addressLine2}
                                required
                            />

                            <label for="city-input">City *</label>
                            <input
                                className={this.state.errorCity ? "invalid" : ""}
                                id="city-input"
                                type="text"
                                name="city"
                                placeholder={"City"}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                value={this.state.website.address.city}
                                required
                            />

                            <label for="postal-input">Postal code *</label>
                            <input
                                className={this.state.errorPostal ? "invalid" : ""}
                                id="postal-input"
                                type="text"
                                name="postal"
                                placeholder={"Postal code"}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                value={this.state.website.address.postal}
                                required
                            />

                            <label>Country *</label>
                            <Dropdown
                                className={this.state.errorCountry ? "invalid" : ""}
                                options={this.state.countryOptions}
                                onChange={(item) => this.handleCountrySelection(item)}
                                value={this.state.website.address.country}
                                placeholder="Select a country"
                            />

                            {this.state.provinceOptions.length !== 0 &&
                                <>
                                    <label>Province *</label>
                                    <Dropdown
                                        className={this.state.errorProvince ? "invalid" : ""}
                                        options={this.state.provinceOptions}
                                        onChange={(item) => this.handleProvinceSelection(item)}
                                        value={this.state.website.address.province}
                                        placeholder="Select a province"

                                    />
                                </>
                            }
                        </div>

                        <div className="field-container">
                            <h2>Phone number *</h2>
                            <input
                                className={this.state.errorPhone ? "invalid" : ""}
                                value={this.state.website.phoneNumber}
                                type="tel"
                                name="phoneNumber"
                                placeholder={"Phone number"}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                required
                            />
                        </div>


                        {!!this.state.errorMessage &&
                            <div className="error-container">
                                <div className="error-wrapper">
                                    <p className="error">{this.state.errorMessage}</p>
                                </div>
                            </div>
                        }



                        <div className="field-container">
                            <h2>Logo</h2>
                            <p className="details">Recommended size (250 x 75)<br />it is recommended to insert your logo of your site, especially for  the display on mobile phones users.</p>
                            {!!this.state.website.logo &&
                                <img className={(this.state.website.logo !== null && this.state.website.logo.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} alt="Uploaded website logo" src={(this.state.website.logo !== null && this.state.website.logo.imageUrl !== null) ? this.state.website.logo.imageUrl : null} />
                            }
                            <div className="buttons-container">
                                <label className="upload-button" htmlFor="flash-image-upload">{uploadBtnText}</label><input id="flash-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                {(this.state.website.logo !== undefined && this.state.website.logo !== null && this.state.website.logo.imageUrl !== null) && <button onClick={() => this.handleLogoDelete()}>Delete</button>}
                            </div>
                        </div>


                        <div className="field-container">
                            <button onClick={() => this.handleVerifyInputFields()}>Save</button>
                        </div>

                        <div className="field-container">
                            <h2>Account</h2>
                            <button className="light" onClick={() => auth.logout()}>Logout</button>
                        </div>

                    </div>
                }
            </>
        );
    }
}


const mapStateToProps = state => ({
    uploadResponse: state.upload,
    websiteInfo: state.websiteInfo,
    editWebsite: state.websiteInfo,
});


export default connect(mapStateToProps, { upload, editWebsite, fetchCurrentUserWebsiteInfo })(GeneralSettings);