import { FETCHING_PAGES_REQUEST, FETCHING_PAGES_SUCCESS, FETCHING_PAGES_FAILURE, POST_PAGES_REQUEST, POST_PAGES_SUCCESS, POST_PAGES_FAILURE, PUT_PAGES_REQUEST, PUT_PAGES_SUCCESS, PUT_PAGES_FAILURE } from '../actions/types';

const initialState = {
    pages: null,
	isFetching: false,
	errors: null
}
  
export default function(state = initialState, action) {
	switch(action.type) {
        case FETCHING_PAGES_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_PAGES_SUCCESS:
            return { ...state, isFetching: false, pages: action.payload };
        case FETCHING_PAGES_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case POST_PAGES_REQUEST:
            return { ...state, isFetching: true };
        case POST_PAGES_SUCCESS:
            return { ...state, isFetching: false, pages: action.payload };
        case POST_PAGES_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case PUT_PAGES_REQUEST:
            return { ...state, isFetching: true };
        case PUT_PAGES_SUCCESS:
            return { ...state, isFetching: false, pages: action.payload };
        case PUT_PAGES_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;  
    }
}