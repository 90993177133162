import React, { Component } from 'react';

class ProductItemPlaceholder extends Component {

    render() {
        return (
            <a className={"product-item"}>
                <div className="image-container" style={{ backgroundColor: "lightgray", height: "220px" }}></div>
                <div className="image-container" style={{ backgroundColor: "lightgray", height: "20px", margin: "20px", marginBottom: "0" }}></div>
                <div className="image-container" style={{ backgroundColor: "lightgray", height: "15px", margin: "15px", marginLeft: "30px", marginRight: "30px", marginBottom: "0" }}></div>
                <div className="image-container" style={{ backgroundColor: "lightgray", height: "10px", margin: "10px", marginLeft: "40px", marginRight: "40px", marginBottom: "0" }}></div>
                <div className="image-container" style={{ backgroundColor: "lightgray", height: "20px", margin: "15px", marginBottom: "0" }}></div>
            </a>
        );
    }
}

export default ProductItemPlaceholder;   