import React, { Component } from 'react';
import StripeConnectButton from '../../../../assets/images/stripe-connect.png';
import { postStripeToken } from '../../../../actions/index';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Loading from "../../partials/loading";

class StripeConnect extends Component {
	componentDidMount() {
		const stripeResponse = queryString.parse(this.props.location.search);
		let data = {
			code: stripeResponse.code
		}
		if (stripeResponse.code){
			this.props.postStripeToken(data).then(() => this.props.stripeAccount.refreshToken !== null ? window.location.replace("/admin") : alert("Error please try again"));
		}
		else if (stripeResponse.error)
			return alert(stripeResponse.error);
	}
	
	render() {
		return (
			<div className={"container"}>
				<div className={"imgContainer"}>
					<img src={require("../../../../assets/images/dashboard/logos/bookmeink-logo@2x.png")} alt="Logo BookMeInk" />
				</div>
				<div className={"stepFirstLogin"}>
					<h3>3 / 3</h3>
				</div>
				<Loading isLoading={this.props.isFetching} />
				<h1>Connect your stripe</h1>
				<p>Finally, to use the app, you need to connect your stripe account (create one if you don't have any).</p>
				<p>This will allow you to collect payment directly from your customer's booking.</p>
				<a href={"https://connect.stripe.com/oauth/authorize?response_type=code&client_id=" + process.env.REACT_APP_STRIPE_CLIENT_ID + "&scope=read_write"}><img src={StripeConnectButton} alt="Stripe Connect" className={"connectStripeBtn"} /></a> 
				<button className='button skip' onClick={() => this.props.handleSkip()}>Skip set-up</button>
				<button className={"button logout"} onClick={() => this.props.auth.logout()}>Logout</button>
			</div> 
		);
	}
}

const mapStateToProps = state => ({
	isFetching: state.stripe.isFetching,
	stripeAccount: state.stripe.stripeAccount,
});

export default connect(mapStateToProps, { postStripeToken })(StripeConnect);