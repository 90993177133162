import React, { Component } from 'react';
import { changeCurrency, editWebsite, fetchCurrentUserWebsiteInfo, unlinkGoogleCalendar, changeWebsiteUrl } from '../../../actions';
import { connect } from 'react-redux';
import Loading from './loading';
import _ from 'lodash';
import Dropdown from 'react-dropdown';
const countryJson = require('countrycitystatejson');

class AdvancedSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            website: {
                is24HourConvention: null,
                pickupInOtherLocation: true,
                pickupAddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    postal: "",
                    country: null,
                    province: null
                }
            },
            selectedCurrency: null,
            provinceOptions: [],
            countryOptions: [],
            errorMessage: "",
            errorCountry: false,
            errorName: false,
            errorAddressLine1: false,
            errorCity: false,
            errorProvince: false,
            errorPostal: false,
            newWebsiteUrl: null
        }
        this.currencySelector = React.createRef();
        this.handleTimeConventionChange = this.handleTimeConventionChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentDidMount() {
        this.props.fetchCurrentUserWebsiteInfo().then(() => {

            let country = this.props.websiteInfo.websiteInfo.data[0].address.country

            this.setState({
                website: this.props.websiteInfo.websiteInfo.data[0],
                countryOptions: this.getCountries(),
                provinceOptions: !!country ? this.getProvinceOptions(this.props.websiteInfo.websiteInfo.data[0].address.country) : []
            })
        });

        if (!!this.props.currentUser) {
            if (this.props.currentUser.data.currency !== null) {
                this.currencySelector.current.value = this.props.currentUser.data.currency;
            }
        }
    }

    getCountries() {
        let countries = [];
        const list = countryJson.getAll();
        for (const countryItem in list) {
            countries.push({ value: countryItem, label: list[countryItem].name })
        }
        return countries;
    }

    getProvinceOptions(pCountry) {
        let provinceOptions = [];
        const country = countryJson.getCountryByShort(pCountry);
        for (const provinceItem in country.states) {
            provinceOptions.push({ value: provinceItem, label: provinceItem })
        }
        return provinceOptions;
    }

    handleInputChange(event) {
        const newWebsiteData = this.state.website;
        if (event.target.name === 'addressLine1' || event.target.name === 'addressLine2' || event.target.name === 'city' || event.target.name === 'postal') {
            newWebsiteData.pickupAddress[event.target.name] = event.target.value;
        } else {
            newWebsiteData[event.target.name] = event.target.value;
        }
        this.setState({ website: newWebsiteData }, () => console.log(this.state));
    }

    handleCountrySelection(item) {
        let provinceOptions = this.getProvinceOptions(item.value)
        let newWebsiteData = this.state.website;
        newWebsiteData.pickupAddress.country = item.value
        this.setState({
            website: newWebsiteData,
            provinceOptions: provinceOptions,
            shippingProvince: this.state.website.pickupAddress.country !== item.value ? null : this.state.website.pickupAddress.province
        });
    }


    handleProvinceSelection(item) {
        let newWebsite = this.state.website;
        newWebsite.pickupAddress.province = item.value
        this.setState({ website: newWebsite });
    }


    handleVerifyInputFields() {

        if (this.state.website.pickupInOtherLocation) {
            if (this.state.website.pickupAddress.country === null || this.state.website.pickupAddress.addressLine1 === "" || this.state.website.pickupAddress.city === "" || this.state.website.pickupAddress.postal === "" || (this.state.provinceOptions.length > 0 && this.state.website.pickupAddress.province === null)) {

                let errorMessage = "";
                let errorCountry = false;
                let errorProvince = false;
                let errorAddressLine1 = false;
                let errorCity = false;
                let errorPostal = false;

                if (this.state.website.pickupAddress.country === null) errorCountry = true;
                if (this.state.provinceOptions.length > 0 && this.state.website.pickupAddress.province === null) errorProvince = true;
                if (this.state.website.pickupAddress.addressLine1 === "") errorAddressLine1 = true;
                if (this.state.website.pickupAddress.city === "") errorCity = true;
                if (this.state.website.pickupAddress.postal === "") errorPostal = true;

                const errorsArray = [errorCountry, errorProvince, errorAddressLine1, errorCity, errorPostal];
                var errorsCount = 0;
                for (var i = 0; i < errorsArray.length; ++i) {
                    if (errorsArray[i] === true) errorsCount++;
                }

                if (errorsCount >= 2) {
                    errorMessage = 'Please fill all the required fields.'
                } else if (errorCountry) {
                    errorMessage = 'Please select a country.'
                } else if (errorProvince) {
                    errorMessage = 'Please select a province / state.'
                } else if (errorAddressLine1) {
                    errorMessage = 'Please enter the address.'
                } else if (errorCity) {
                    errorMessage = "Please enter the city."
                } else if (errorPostal) {
                    errorMessage = "Please enter the postal code."
                }

                this.setState({
                    errorCountry: errorCountry,
                    errorProvince: errorProvince,
                    errorAddressLine1: errorAddressLine1,
                    errorCity: errorCity,
                    errorPostal: errorPostal,
                    errorMessage: errorMessage
                });

            } else {

                this.setState({
                    errorCountry: false,
                    errorProvince: false,
                    errorAddressLine1: false,
                    errorCity: false,
                    errorPostal: false,
                    errorMessage: '',
                    isLoading: true
                }, () => {
                    this.handleSubmit()
                });


            }
        } else {
            this.setState({
                errorCountry: false,
                errorProvince: false,
                errorAddressLine1: false,
                errorCity: false,
                errorPostal: false,
                errorMessage: '',
                isLoading: true
            }, () => {
                this.handleSubmit()
            });
        }

    }

    handleTimeConventionChange(e) {
        if (e.target.name === "time-convention") {
            let siteData = this.state.website;
            siteData.is24HourConvention = e.target.value === "24-hour";

            this.setState({
                website: siteData
            });
        }
    }


    handlePickupInOtherLocation() {
        const newWebsiteData = this.state.website;
        newWebsiteData.pickupInOtherLocation = !this.state.website.pickupInOtherLocation;
        this.setState({ website: newWebsiteData });
    }

    handleUnlinkGoogle() {
        if (window.confirm("Are you sure you want to unlink your Google Calendar?")) {
            this.props.unlinkGoogleCalendar().then(() => {
                if (this.props.unlinkResponse.status === 1) {
                    window.location.reload();
                } else {
                    alert(this.props.unlinkResponse.message);
                }
            });
        }
    }

    handleChangeSiteStatus() {
        let newSite = this.state.website;
        if (newSite.status === 1) {
            newSite.status = 0;
        } else {
            newSite.status = 1;
        }
        this.setState({ website: newSite });
    }

    async handleSubmit() {
        if (!!this.state.newWebsiteUrl)
            await this.props.changeWebsiteUrl(this.state.newWebsiteUrl)

        //if (this.props.websiteUrl.data.status !== 1) {
        //    this.setState({ error: this.props.websiteUrl.data.message })
        //    alert(this.props.websiteUrl.data.message)
        //}
        if (!!this.state.selectedCurrency) {
            let data = {
                currency: this.state.selectedCurrency
            }
            this.props.changeCurrency(data);
        }
        this.props.editWebsite(this.state.website).then(() => this.props.fetchCurrentUserWebsiteInfo().then(() => {
            this.setState({
                website: this.props.websiteInfo.websiteInfo.data[0]
            });
        }));
    }

    componentDidUpdate() {
        if (!!this.props.currentUser) {
            if (this.props.currentUser.data.currency !== null) {
                this.currencySelector.current.value = this.props.currentUser.data.currency;
            }
        }
    }

    handleUrlChange(e) {
        if (e.target.value === this.props.websiteInfo.websiteInfo.data[0].url) {
            this.setState({ newWebsiteUrl: null })
        } else {
            this.setState({ newWebsiteUrl: e.target.value })
        }
    }

    render() {
        return (
            <div>
                <Loading isLoading={this.props.isFetching} />
                {
                    !!this.props.currentUser &&
                    <>
                        <h1>Advanced settings</h1>

                        <div className="field-container">
                            <h2>Currency</h2>
                            <select ref={this.currencySelector} name="currency" onChange={e => this.setState({ selectedCurrency: e.target.value })} value={!!this.state.selectedCurrency ? this.state.selectedCurrency : "cad"}>
                                <option value="cad">CAD</option>
                                <option value="eur">EUR</option>
                                <option value="usd">USD</option>
                                <option value="gbp">GBP</option>
                                <option value="aud">AUD</option>
                                <option value="nzd">NZD</option>
                                <option value="mxn">MXN</option>
                                <option value="dkk">DKK</option>
                                <option value="sek">SEK</option>
                                <option value="brl">BRL</option>
                                <option value="chf">CHF</option>
                                <option value="ars">ARS</option>
                                <option value="nok">NOK</option>
                                <option value="isk">ISK</option>
                            </select>
                        </div>


                        <div className="field-container">
                            <h2>Time convention</h2>

                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="12-hour"
                                    name="time-convention"
                                    value="12-hour"
                                    checked={!this.state.website.is24HourConvention}
                                    onChange={this.handleTimeConventionChange}
                                />
                                <label className="radio-label" for="male">12-hour</label>

                                <p className="details">(Example: 2:00 pm)</p>
                            </div>

                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="24-hour"
                                    name="time-convention"
                                    value="24-hour"
                                    checked={this.state.website.is24HourConvention}
                                    onChange={this.handleTimeConventionChange}
                                />
                                <label className="radio-label" for="24-hour">24-hour</label>
                                <p className="details">(Example: 14:00)</p>
                            </div>
                        </div>


                        <div className="field-container">
                            <h2>Pickup location</h2>
                            <p className="details">Pickup location for the products bought using the "pickup" shipping option.</p>
                            <div>
                                <div className="radio-container">
                                    <div className="radio-item">
                                        <input type="radio" id="radio-pickup-in-other-yes" checked={!this.state.website.pickupInOtherLocation} onChange={() => this.handlePickupInOtherLocation()} />
                                        <label htmlFor="radio-pickup-in-other-yes" className="radio-label">In store</label>
                                    </div>

                                    <div className="radio-item">
                                        <input type="radio" id="radio-pickup-in-other-no" checked={this.state.website.pickupInOtherLocation} onChange={() => this.handlePickupInOtherLocation()} />
                                        <label htmlFor="radio-pickup-in-other-no" className="radio-label">Other address</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field-container">
                            <h2>Website status</h2>
                            <p className="details">Disabling your website will make it fully unavailable.</p>
                            <div>
                                <div className="radio-container">
                                    <div className="radio-item">
                                        <input type="radio" id="radio-website-enable" checked={this.state.website.status} onChange={() => this.handleChangeSiteStatus()} />
                                        <label htmlFor="radio-website-enable" className="radio-label">Enable</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" id="radio-website-disable" checked={!this.state.website.status} onChange={() => this.handleChangeSiteStatus()} />
                                        <label htmlFor="radio-website-disable" className="radio-label">Disable</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !_.isEmpty(this.props.websiteInfo.websiteInfo) &&
                            <div className="field-container">
                                <h2>Website url</h2>
                                <p className="details">app.bookme.ink/{`${!!this.state.newWebsiteUrl ? this.state.newWebsiteUrl : this.props.websiteInfo.websiteInfo.data[0].url}`}</p>
                                <input type="text" nsame="newWebsiteUrl" onInput={(e) => this.handleUrlChange(e)} placeholder="Website url" defaultValue={this.props.websiteInfo.websiteInfo.data[0].url} />
                            </div>
                        }
                        {
                            !!this.props.currentUser.data.googleRefreshToken &&
                            <div className="field-container">
                                <h2>Google Calendar</h2>
                                <button onClick={() => this.handleUnlinkGoogle()}>Unlink my Google Calendar</button>
                            </div>
                        }

                        {
                            this.state.website.pickupInOtherLocation &&
                            <>
                                <div className="field-container">
                                    <h2>Other Pickup Address</h2>

                                    <label for="address-line1-input">Address line 1 *</label>
                                    <input
                                        className={this.state.errorAddressLine1 ? "invalid" : ""}
                                        id="address-line1-input"
                                        type="text"
                                        name="addressLine1"
                                        placeholder={"Address (line 1)"}
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                        value={this.state.website.pickupAddress.addressLine1}
                                        required
                                    />

                                    <label for="address-line2-input">Address line 2</label>
                                    <input
                                        id="address-line2-input"
                                        type="text"
                                        name="addressLine2"
                                        placeholder={"Address (line 2)"}
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                        value={this.state.website.pickupAddress.addressLine2}
                                        required
                                    />

                                    <label for="city-input">City *</label>
                                    <input
                                        className={this.state.errorCity ? "invalid" : ""}
                                        id="city-input"
                                        type="text"
                                        name="city"
                                        placeholder={"City"}
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                        value={this.state.website.pickupAddress.city}
                                        required
                                    />

                                    <label for="postal-input">Postal code *</label>
                                    <input
                                        className={this.state.errorPostal ? "invalid" : ""}
                                        id="postal-input"
                                        type="text"
                                        name="postal"
                                        placeholder={"Postal code"}
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                        value={this.state.website.pickupAddress.postal}
                                        required
                                    />

                                    <label>Country *</label>
                                    <Dropdown
                                        className={this.state.errorCountry ? "invalid" : ""}
                                        options={this.state.countryOptions}
                                        onChange={(item) => this.handleCountrySelection(item)}
                                        value={this.state.website.pickupAddress.country}
                                        placeholder="Select a country"
                                    />

                                    {this.state.provinceOptions.length !== 0 &&
                                        <>
                                            <label>Province</label>
                                            <Dropdown
                                                className={this.state.errorProvince ? "invalid" : ""}
                                                options={this.state.provinceOptions}
                                                onChange={(item) => this.handleProvinceSelection(item)}
                                                value={this.state.website.pickupAddress.province}
                                                placeholder="Select a province"

                                            />
                                        </>
                                    }
                                </div>

                                {!!this.state.errorMessage &&
                                    <div className="error-container">
                                        <div className="error-wrapper">
                                            <p className="error">{this.state.errorMessage}</p>
                                        </div>
                                    </div>
                                }
                            </>
                        }




                        <button className="button" style={{ marginTop: 25 }} onClick={() => this.handleVerifyInputFields()}>Save</button>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProp = state => ({
    currentUser: state.user.user,
    websiteInfo: state.websiteInfo,
    websiteUrl: state.websiteInfo.websiteUrl,
    isFetching: state.user.isFetching,
    unlinkResponse: state.googleCalendar.unlink.data
});

export default connect(mapStateToProp, { changeCurrency, fetchCurrentUserWebsiteInfo, editWebsite, unlinkGoogleCalendar, changeWebsiteUrl })(AdvancedSettings);