import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postProduct, upload, fetchCurrentUserWebsiteInfo } from '../../../actions';
import _ from 'lodash';
import Dropdown from 'react-dropdown';
import Loading from "../partials/loading";
import { Link } from 'react-router-dom';
import { hasStoreAddress } from '../../../services/addressServices';

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class AddProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            websiteInfo: null,
            file: null,
            title: '',
            description: '',
            price: "0.00",
            quantity: 0,
            localShippingCost: "0.00",
            internationalShippingCost: "0.00",
            image: null,
            errorRequired: false,
            editorState: EditorState.createEmpty(),
            shippingOption: false,
            pickupOption: false
        }

        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleWysiwygChange = this.handleWysiwygChange.bind(this)
    }


    componentDidMount() {
        this.props.fetchCurrentUserWebsiteInfo().then(() => this.setState({ websiteInfo: this.props.websiteInfo.websiteInfo.data[0] }));
    }


    handleFileChange(e) {
        this.setState({ file: e.target.files[0] }, function () {
            this.fileUpload();
        });
    }


    fileUpload() {
        const formData = new FormData();
        formData.append('files', this.state.file);
        this.props.upload(formData).then(() => this.props.uploadResponse.fileUrl !== null && this.setProductImgState());
    }


    handleImageDelete() {
        this.setState({ image: null });
    }


    setProductImgState() {
        let data = {
            imageUrl: this.props.uploadResponse.fileUrl
        }
        this.setState({ image: data });
    }


    handleShippingCheckboxes(e) {
        if (e.target.name === "parcelShipping") {
            // parcelShipping
            if (this.state.shippingOption) {
                this.setState({
                    localShippingCost: 0,
                    internationalShippingCost: 0,
                    shippingOption: false
                });

            } else {
                this.setState({
                    shippingOption: true
                });
            }
        } else {
            // parcelPickup
            if (!hasStoreAddress(this.state.websiteInfo.address)) {
                alert('A store address must be added to your website\'s general settings in order to activate the "Parcel pickup" option.')
            } else {
                this.setState({ pickupOption: !this.state.pickupOption })

            }
        }
    }


    handleInputChange(event) {

        if (event.target.name === "quantity") {
            let newQuantity = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value);
            this.setState({ [event.target.name]: newQuantity });

        } else if (
            event.target.name === "internationalShippingCost" ||
            event.target.name === "localShippingCost" ||
            event.target.name === "price"
        ) {
            if (
                this.state[event.target.name] === "0.00" || 
                (
                    this.state[event.target.name].toString() === "0" &&
                    !isNaN(
                        parseInt(
                            event.target.value.toString().charAt(event.target.value.toString().length - 1)
                        )
                    ) &&
                    event.target.value.toString().indexOf('.') === -1
                )
            ) {


                this.setState({ [event.target.name]: event.target.value.toString().charAt(event.target.value.toString().length - 1) });

            } else if (event.target.value.toString() === "") {
                this.setState({ [event.target.name]: 0 });

            } else if (
                (
                    event.target.value.toString().indexOf('.') !== -1 &&
                    event.target.value.toString().split('.')[1].length <= 2
                ) || event.target.value.toString().indexOf('.') === -1

            ) {
                let newPrice = isNaN(event.target.value) ? 0 : event.target.value;
                this.setState({ [event.target.name]: newPrice });
            }

        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }


    handleWysiwygChange = (editorState) => {
        this.setState({ editorState }, () => {
            const newDescription = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            this.setState({ description: newDescription });
        });
    }


    handleSubmit() {
        let data = {
            title: this.state.title,
            description: this.state.description,
            quantity: this.state.quantity,
            image: this.state.image !== null ? this.state.image : null,
            status: 1,
            price: parseFloat(this.state.price),
            localShippingCost: parseFloat(this.state.localShippingCost),
            internationalShippingCost: parseFloat(this.state.internationalShippingCost),
            shippingOption: this.state.shippingOption,
            pickupOption: this.state.pickupOption
        }

        if (data.title !== "" && data.price !== 0 &&
            (
                (data.shippingOption &&
                    (data.localShippingCost !== 0 && data.internationalShippingCost !== 0)
                ) || (data.pickupOption && !data.shippingOption)
            )
        ) {
            this.setState({ errorRequired: false });
            this.props.postProduct(data).then(() => this.props.history.push("/admin/products/myproducts"));

        } else {
            // All required fields must be filled
            this.setState({ errorRequired: true });
        }
    }



    render() {
        console.log(this.state);

        let uploadBtnText = this.state.image !== null ? 'Modify image' : 'Upload Image';
        if (this.state.websiteInfo !== null) {

            return (
                <section className="addProducts">
                    {
                        <>
                            <Loading isLoading={this.props.isFetching || this.props.uploadResponse.isFetching} />
                            <div>
                                <h1>Add a product</h1>

                                <h2>Information</h2>
                                {
                                    this.props.products.data.length >= 10 ?
                                        <>
                                            <Link className="back-btn" to="/admin/products/myproducts">
                                                <img className="checked-image" src={require('../../../assets/images/dashboard/icons/back-arrow_4x.png')} />
                                                <span>Back to my products</span>
                                            </Link>
                                            <p>The maximum of 10 products has been reached.</p>
                                        </>
                                        :
                                        <>
                                            <div className="field-container">
                                                <div className={(this.state.errorRequired && this.state.title === "") ? "error input-container" : "input-container"}>
                                                    <label>Name *</label>
                                                    <input type="text" name="title" onChange={(text) => this.handleInputChange(text)} placeholder={"Name"} />
                                                </div>

                                                <label>Description</label>
                                                <div className="wysiwyg-container">
                                                    <Editor
                                                        editorState={this.state.editorState}
                                                        onEditorStateChange={this.handleWysiwygChange}
                                                    />
                                                </div>

                                                <div className="input-container">
                                                    <label>Image</label>
                                                    <p className="details">Recommended size (500 x 500)</p>
                                                    {
                                                        <img className={this.state.image !== null ? 'visible image-upload-preview' : 'image-upload-preview'} src={this.state.image !== null ? this.state.image.imageUrl : null} />
                                                    }
                                                    <div className="buttons-container">
                                                        <label className="upload-button" htmlFor="product-image-upload">{uploadBtnText}</label><input id="product-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                                        {this.state.image !== null && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                                    </div>
                                                </div>

                                                <div className="input-container">
                                                    <label>Available quantity</label>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        value={this.state.quantity.toString()}
                                                        name="quantity"
                                                        onChange={(text) => this.handleInputChange(text)}
                                                        placeholder={"Quantity"}
                                                    />
                                                </div>
                                            </div>


                                            <h2>Pricing</h2>
                                            <div className="field-container">

                                                <div className={(this.state.errorRequired && parseFloat(this.state.price) === 0) ? "input-container error" : "input-container"}>
                                                    <label>Price *</label>
                                                    <input
                                                        type="number"
                                                        value={this.state.price.toString()}
                                                        min="0"
                                                        name="price"
                                                        onChange={(text) => this.handleInputChange(text)} placeholder={"Pricing"}
                                                    />
                                                </div>

                                                <div classname="input-container">
                                                    <label>Currency</label>
                                                    <div className="columns">
                                                        {
                                                            (!!this.props.currentUser && this.props.currentUser.data.currency !== null) ?
                                                                <div className="input currency">
                                                                    <p>{this.props.currentUser.data.currency.toUpperCase()}</p>
                                                                </div>
                                                                :
                                                                <div className="input currency">
                                                                    <p>CAD</p>
                                                                </div>
                                                        }

                                                        <Link className="button" to="/admin/settings/advanced">
                                                            <span>Change currency</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>


                                            <h2>Shipping</h2>

                                            <div className="field-container">
                                                <div className={(!this.state.pickupOption && !this.state.shippingOption && this.state.errorRequired) ? "input-container error" : "input-container"}>
                                                    <label>Shipping options *</label>
                                                    <div className="checkbox-item">
                                                        <input
                                                            type="checkbox"
                                                            name="parcelShipping"
                                                            id="checkbox-parcel-shipping"
                                                            checked={this.state.shippingOption}
                                                            onChange={(e) => this.handleShippingCheckboxes(e)}
                                                        />
                                                        <label htmlFor="checkbox-parcel-shipping" className="checkbox-label">Parcel shipping</label>
                                                    </div>

                                                    <div className={hasStoreAddress(this.state.websiteInfo.address) ? "checkbox-item" : "checkbox-item disabled"}>
                                                        <input
                                                            type="checkbox"
                                                            name="parcelPickup"
                                                            id="checkbox-parcel-pickup"
                                                            checked={this.state.pickupOption}
                                                            onChange={(e) => this.handleShippingCheckboxes(e)}
                                                        />
                                                        <label htmlFor="checkbox-parcel-pickup" className="checkbox-label">Parcel pickup</label>
                                                    </div>
                                                </div>

                                                {this.state.shippingOption &&
                                                    <>
                                                        <div className={(this.state.errorRequired && parseFloat(this.state.localShippingCost) === 0) ? "input-container error" : "input-container"}>
                                                            <label>Same country shipping cost *</label>
                                                            <input
                                                                type="number"
                                                                value={this.state.localShippingCost.toString()}
                                                                min="0"
                                                                name="localShippingCost"
                                                                onChange={(text) => this.handleInputChange(text)}
                                                                placeholder={"Local shipping"}
                                                            />
                                                        </div>

                                                        <div className={(this.state.errorRequired && parseFloat(this.state.internationalShippingCost) === 0) ? "input-container error" : "input-container"}>
                                                            <label>International shipping cost *</label>
                                                            <input
                                                                type="number"
                                                                value={this.state.internationalShippingCost.toString()}
                                                                min="0"
                                                                name="internationalShippingCost" onChange={(text) => this.handleInputChange(text)}
                                                                placeholder={"International shipping"}
                                                            />
                                                        </div>
                                                    </>
                                                }

                                            </div>

                                            <div className="error-container">
                                                {!!this.state.errorRequired &&
                                                    <p className="error">All * fields are required. </p>
                                                }
                                            </div>

                                            <button onClick={() => this.handleSubmit()}>Add product</button>
                                        </>
                                }

                            </div>
                        </>
                    }
                </section>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    product: state.product.product,
    isFetching: state.product.isFetching,
    uploadResponse: state.upload,
    websiteInfo: state.websiteInfo
});

export default connect(mapStateToProps, { postProduct, upload, fetchCurrentUserWebsiteInfo })(AddProducts);