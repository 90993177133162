import { FETCHING_WEBSITEINFO_REQUEST, FETCHING_WEBSITEINFO_SUCCESS, FETCHING_WEBSITEINFO_FAILURE, FETCHING_CURRENT_USER_WEBSITE_INFO_REQUEST, FETCHING_CURRENT_USER_WEBSITE_INFO_SUCCESS, FETCHING_CURRENT_USER_WEBSITE_INFO_FAILURE, CREATE_WEBSITE_REQUEST, CREATE_WEBSITE_SUCCESS, CREATE_WEBSITE_FAILURE, EDIT_WEBSITE_REQUEST, EDIT_WEBSITE_SUCCESS, EDIT_WEBSITE_FAILURE, CHANGE_URL_REQUEST, CHANGE_URL_SUCCESS, CHANGE_URL_FAILURE } from '../actions/types';

const initialState = {
    websiteInfo: null,
    editWebsite: null,
    websiteUrl: {},
    websiteCreation: null,
    isFetching: false,
    errors: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCHING_CURRENT_USER_WEBSITE_INFO_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_CURRENT_USER_WEBSITE_INFO_SUCCESS:
            return { ...state, isFetching: false, websiteInfo: action.payload };
        case FETCHING_CURRENT_USER_WEBSITE_INFO_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case CREATE_WEBSITE_REQUEST:
            return { ...state, isFetching: true };
        case CREATE_WEBSITE_SUCCESS:
            return { ...state, isFetching: false, websiteCreation: action.payload };
        case CREATE_WEBSITE_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case FETCHING_WEBSITEINFO_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_WEBSITEINFO_SUCCESS:
            return { ...state, isFetching: false, websiteInfo: action.payload };
        case FETCHING_WEBSITEINFO_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case EDIT_WEBSITE_REQUEST:
            return { ...state, isFetching: true };
        case EDIT_WEBSITE_SUCCESS:
            return { ...state, isFetching: false, editWebsite: action.payload };
        case EDIT_WEBSITE_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case CHANGE_URL_REQUEST:
            return { ...state, isFetching: true };
        case CHANGE_URL_SUCCESS:
            return { ...state, isFetching: false, websiteUrl: action.payload };
        case CHANGE_URL_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;
    }
}