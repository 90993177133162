import axios from 'axios';
import { UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE } from './types';

export const upload = data => async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_REQUEST });
      const response = await axios({
            url: '/image',
            method: 'post',
            data: data,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        });
      dispatch({ type: UPLOAD_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: UPLOAD_FAILURE, payload: error });
    }
};