import axios from 'axios';
import { FETCHING_CATEGORIES_REQUEST, FETCHING_CATEGORIES_SUCCESS, FETCHING_CATEGORIES_FAILURE, FETCHING_CATEGORY_REQUEST, FETCHING_CATEGORY_SUCCESS, FETCHING_CATEGORY_FAILURE, PUT_CATEGORY_REQUEST, PUT_CATEGORY_SUCCESS, PUT_CATEGORY_FAILURE, DELETE_CATEGORY_REQUEST, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAILURE, POST_CATEGORY_REQUEST, POST_CATEGORY_SUCCESS, POST_CATEGORY_FAILURE } from './types';

export const fetchCurrentUserCategories = () => {
    return async dispatch => {
        try {
            dispatch({ type: FETCHING_CATEGORIES_REQUEST });
            const response = await axios.get("/categories");
            dispatch({ type: FETCHING_CATEGORIES_SUCCESS, payload: response });
        }
        catch (error) {
            dispatch({ type: FETCHING_CATEGORIES_FAILURE, payload: error });
        }
    };
}

export const fetchCategoryById = (catId) => {
    return async dispatch => {
        try {
            dispatch({ type: FETCHING_CATEGORY_REQUEST });
            const response = await axios.get(`/category/${catId}`);
            dispatch({ type: FETCHING_CATEGORY_SUCCESS, payload: response });
        }
        catch (error) {
            dispatch({ type: FETCHING_CATEGORY_FAILURE, payload: error });
        }
    };
}

export const postCategory = data => async (dispatch) => {
    try {
      dispatch({ type: POST_CATEGORY_REQUEST });
      const response = await axios.post(`/category`, data);
      dispatch({ type: POST_CATEGORY_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: POST_CATEGORY_FAILURE, payload: error });
    }
};

export const putCategory = data => async (dispatch) => {
    try {
      dispatch({ type: PUT_CATEGORY_REQUEST });
      const response = await axios.put(`/category/${data.id}`, data);
      dispatch({ type: PUT_CATEGORY_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: PUT_CATEGORY_FAILURE, payload: error });
    }
};

export const deleteCategory = data => async (dispatch) => {
    try {
      dispatch({ type: DELETE_CATEGORY_REQUEST });
      const response = await axios.delete(`/category/${data}`);
      dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: DELETE_CATEGORY_FAILURE, payload: error });
    }
};