import React, { Component } from 'react';
import { fetchFlashById, fetchGoogleCalendar, fetchAvailability, postGoogleCalendarEvent, /*postStripePaymentConnect, */getPurchases, createPaymentIntent } from '../../actions';
import _ from "lodash";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "./partials/partial.loading";
import DateDisplay from "./partials/partial.date-display";
import { animateScroll as scroll } from 'react-scroll';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import Moment from "react-moment";
import InjectedForm from "./partials/injected-form";
import { convertTo12h } from '../../services/timeServices';


class SitesSingleFlash extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeView: 0,
			isLoading: false,
			excludeDates: null,
			minDate: null,
			maxDate: null,
			bookingHours: null,
			pickerDate: null,
			selectorDate: null,
			error: false,
			paymentSuccess: false,
			paymentError: false,
			bookingError: false,
			eventPostError: false,
			firstName: '',
			lastName: '',
			email: '',
			socialUrl: '',
			confirmEmail: '',
			phoneNumber: '',
			pronouns: '',
			errorFirstName: false,
			errorLastName: false,
			errorEmail: false,
			errorPhoneNumber: false,
			errorSocialUrl: false,
			errorStripeField: false,
			errorPaymentForm: '',
			errorStripePaymentForm: '',
			errorCodePaymentForm: '',
		};

		this.product = {
			data: null,
			isLoading: false
		}

		this.calendarExceptions = [];
		this.bannerData = null;

		this.submitPayment = this.submitPayment.bind(this);

		this.injectedform = React.createRef();
	}


	componentDidMount() {

		const removeLoading = () => {
			if (this.props.flash !== null && this.props.purchases !== null) {
				this.product.isLoading = false;
			}
		}

		scroll.scrollToTop();
		this.product.isLoading = true;
		const flashId = this.props.flashId;
		this.props.fetchFlashById(flashId).then(() => removeLoading());
		this.props.getPurchases(this.props.siteUrl).then(() => {
			if (this.props.purchases.status !== 1) {
				// Error
				alert('An error occured, please refresh the page');
				console.error('getPurchases error. ' + this.props.purchases.message);
			} else {
				// Success


				// Reorganise availability to be used by the calendar generator
				let purchases = {};
				if (!this.props.purchases.data.length) {
					purchases = null;
				} else {
					for (let i = 0; i < this.props.purchases.data.length; i += 1) {
						const purchase = this.props.purchases.data[i];

						//Reformat to day-month-year
						const dateString = purchase.date.split("T")[0];
						const dateArray = dateString.split('-');
						const formattedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;

						purchases[formattedDate] = purchase.quantity;
					}
				}

				this.purchases = purchases;
				removeLoading();
			}
		});
	}

	addDays(daysAmount) {
		var newDate = new Date();
		newDate.setDate(newDate.getDate() + daysAmount);
		return newDate;
	}

	handleChange = (e) => {

		this.setState({ [e.target.name]: e.target.value }, () => {
			if (this.state.error !== "") {

				if (this.state.errorFirstName && this.state.firstName !== "") {
					this.setState({ errorFirstName: false, errorPaymentForm: "" });

				} else if (this.state.errorLastName && this.state.lastName !== "") {
					this.setState({ errorLastName: false, errorPaymentForm: "" });

				} else if (this.state.errorEmail && this.state.email !== "") {
					this.setState({ errorEmail: false, errorPaymentForm: "" });

				} else if (this.state.errorPhoneNumber && this.state.phoneNumber !== "") {
					this.setState({ errorPhoneNumber: false, errorPaymentForm: "" })

				} else if (this.state.errorSocialUrl && this.state.socialUrl !== "") {
					this.setState({ errorSocialUrl: false, errorPaymentForm: "" })

				}
			}
		});
	}

	generateDate(date) {
		const dateArray = date.split("-");
		const formattedDate = `${dateArray[2]}-${dateArray[1]}-${parseInt(dateArray[0]) + 1}`;
		return new Date(formattedDate);
	}


	render(props) {
		console.log(this.props);
		console.log(this.state);
		// Define productsListingData products
		if (!_.isEmpty(this.props.flash)) {
			this.product.data = this.props.flash.data;
		}

		this.product.isLoading = this.props.isFetchingFlash ? true : false

		if (this.product.data !== null && !this.product.isLoading) {


			if (this.product.data.status === 1) {
				return (
					<>
						<Loading isLoading={this.state.isLoading} />
						<main className="page-single-flash">
							<section className="flash-details">
								<div className="wrapper">
									<div className="image-container">
										{!!this.product.data.image ?
											<img src={this.product.data.image.imageUrl} alt="Flash" /> :
											<img src={require("../../assets/images/sites/placeholders/product-placeholder.png")} alt="Placeholder"></img>
										}
									</div>

									{this.state.activeView === 0 &&
										<div className="content-container content-details">
											{!!this.product.data.title && <h1>{this.product.data.title}</h1>}
											{this.product.data.category !== null && <p className="category">Category: {this.product.data.category.name}</p>}

											<h2>Description</h2>
											{!!this.product.data.description &&
												<div className="description-container">
													<div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: this.product.data.description }}></div>
												</div>
											}
											{!this.product.data.isRecurring && <p className="recurrence">This design is unique and will only be tattooed once by the artist. </p>}


											{(!!this.product.data.deposit || !!this.product.data.pricing || !!this.product.data.time) &&
												<div className="details">

													{!!this.product.data.time &&
														<>
															<div className="detail-item ">
																<div className="icon-container">
																	<img className="duration-icon icon" src={require("../../assets/images/sites/icons/details-duration2x.png")} alt="Duration"></img>
																</div>

																<p className="detail-title">(Duration)</p>
																<p className="detail-value">{this.minutesToHours(this.product.data.time)}</p>
															</div>
														</>
													}

													{!!this.product.data.deposit &&
														<>
															<div className="detail-item">
																<div className="icon-container">
																	<img className="deposit-icon icon" src={require("../../assets/images/sites/icons/details-deposit2x.png")} alt="Deposit"></img>
																</div>
																<p className="detail-title">(Deposit)</p>
																<p className="detail-value">{this.product.data.deposit} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
															</div>
														</>
													}

													{!!this.product.data.pricingMin &&
														<div className="detail-item">
															<div className="icon-container">
																<img className="instore-icon icon" src={require("../../assets/images/sites/icons/details-instore2x.png")} alt="In store"></img>
															</div>

															<p className="detail-title">(In store)</p>
															{
																(!!this.product.data.pricingMax && (this.product.data.pricingMax > this.product.data.pricingMin)) ?
																	<p className="detail-value">{this.product.data.pricingMin} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()} - {this.product.data.pricingMax} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
																	:
																	<p className="detail-value">{this.product.data.pricingMin} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
															}

														</div>
													}
												</div>
											}

											<p className="small-chars">The deposit is required to book your tattoo appointment.<br />The In Store amount is the balance that must be paid to your tattoo artist during the appointment.</p>

											<p className="small-chars">Please read the artist’s terms and condition before booking.</p>

											<p className="terms-container">
												<Link
													className="terms"
													to={{
														pathname: `/${this.props.siteUrl}/terms-and-conditions`,
														backTo: `/${this.props.siteUrl}/flash/${this.props.flashId}`
													}}
												>Terms and conditions</Link>
											</p>


											<div className="buttons-container">
												<Link className="cancel button" to={`/${this.props.siteUrl}/flashes`}>Cancel</Link>
												<button className="button dark" onClick={() => {
													this.setState({
														isLoading: true
													}, function () {
														this.props.fetchGoogleCalendar(this.props.siteUrl).then(() => {

															this.props.fetchAvailability(this.props.siteUrl).then(() => {

																if (this.props.availability.data.statusCode !== 404 && !!this.props.calendar.data.calendars) {

																	this.generateSchedule(
																		this.props.calendar.data.calendars.primary.busy,
																		this.props.availability.data
																	);
																} else {
																	this.setState({
																		isLoading: false,
																		error: true
																	})
																}

															});

														});

													});

												}}>Book now</button>

											</div>
											{
												this.state.error &&
												<div className="error-container">
													<div className="error-wrapper">
														<p className="error">No availabilities were found.<br />Please try again later.</p>
													</div>
												</div>
											}
										</div>
									}


									{this.state.activeView === 1 &&
										<div className="content-container content-schedule">
											{!!this.product.data.title && <h1>{this.product.data.title}</h1>}
											{this.product.data.category !== null && <p className="category">Category: {this.product.data.category.name}</p>}

											<h2>Availabilities</h2>
											<div className="information-container">
												<p>Pick an availability for your tattoo appointment.<br />The booking hours depend on the artist's timezone.</p>
											</div>

											<div className="date-container">
												<div className="arrow-container">
													{(this.state.selectorDate !== this.state.availabilityDays[0]) &&
														<div
															className="arrow-action"
															onClick={() => {
																const currentDayIndex = this.state.availabilityDays.indexOf(this.state.selectorDate);
																const prevDay = this.state.availabilityDays[currentDayIndex - 1];
																const prevDayArray = prevDay.split("-");
																const prevDayMonth = parseInt(prevDayArray[1]) - 1;
																const prevDate = new Date(prevDayArray[2], prevDayMonth, prevDayArray[0]);
																this.setState({
																	pickerDate: prevDate,
																	selectorDate: prevDay
																})
															}}
														><i className="arrow left"></i></div>
													}
												</div>

												<DatePicker
													selected={this.state.pickerDate}
													onChange={(date) => {
														const year = date.getYear() + 1900;
														const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
														const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
														const selectorDate = `${day}-${month}-${year}`;

														this.setState({
															pickerDate: date,
															selectorDate: selectorDate
														});
													}}
													excludeDates={this.state.excludeDates}
													minDate={this.addDays(this.state.minDate)}
													maxDate={this.addDays(this.state.maxDate - 1)}
													customInput={<DateDisplay />}
												/>

												<div className="arrow-container">
													{this.state.selectorDate !== this.state.availabilityDays[(this.state.availabilityDays.length - 1)] &&
														<div
															className="arrow-action"
															onClick={() => {
																const currentDayIndex = this.state.availabilityDays.indexOf(this.state.selectorDate);
																const nextDay = this.state.availabilityDays[currentDayIndex + 1];
																const nextDayArray = nextDay.split("-");
																const nextDayMonth = parseInt(nextDayArray[1]) - 1;
																const nextDate = new Date(nextDayArray[2], nextDayMonth, nextDayArray[0]);
																this.setState({
																	pickerDate: nextDate,
																	selectorDate: nextDay
																})
															}}
														><i className="arrow right"></i></div>
													}
												</div>
											</div>


											<div className="hoursSelector">
												{

													this.state.bookingHours[this.state.selectorDate].map((pItem, key) => {

														const selectedTime = {
															date: this.state.selectorDate,
															start: pItem.start,
															end: pItem.end
														};

														return (
															<button
																className="button hour"
																key={key}
																onClick={() => {

																	this.setState({
																		activeView: 2,
																		selectedTime: selectedTime
																	}, function () {
																		scroll.scrollToTop();
																	})
																}}
															>
																{this.props.websiteInfo.data.is24HourConvention ?
																	`${pItem.start} - ${pItem.end}`
																	:
																	<>
																		{convertTo12h(pItem.start)}<br />-<br />
																		{convertTo12h(pItem.end)}
																	</>
																}
															</button>
														)
													})

												}
											</div>
											<div className="buttons-container">
												<button className="button" onClick={() => {
													this.setState({
														activeView: 0
													}, function () { scroll.scrollToTop(); });

												}}>&#60;&nbsp;&nbsp;Back</button>
											</div>
										</div>
									}


									{this.state.activeView === 2 &&
										<div className="content-container content-payment">
											{!!this.product.data.title && <h1>{this.product.data.title}</h1>}

											<div className="details-container">

												<p className="payment-subtitle"><b>Selected appointment</b></p>
												{
													this.props.websiteInfo.data.is24HourConvention ?
														<p><Moment format="LL" date={this.state.pickerDate} /> from {this.state.selectedTime.start} to {this.state.selectedTime.end}</p>
														:
														<p><Moment format="LL" date={this.state.pickerDate} /> from {convertTo12h(this.state.selectedTime.start)} to {convertTo12h(this.state.selectedTime.end)}</p>
												}


												<p className="payment-subtitle"><b>Payment details</b></p>
												<p>Deposit: {(this.product.data.deposit).toFixed(2)} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
												<p>Service fees: {(Math.ceil(((this.product.data.deposit / 100) * 5) * 100) / 100).toFixed(2)} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
												<p>Total: {((Math.ceil(((this.product.data.deposit / 100) * 5) * 100) / 100) + this.product.data.deposit).toFixed(2)} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
											</div>

											<p className="payment-subtitle"><b>Payment</b></p>
											<input
												className={this.state.errorFirstName ? "invalid" : ""}
												type="text"
												name="firstName"
												autoComplete="off"
												placeholder={"First name *"}
												onChange={this.handleChange}
												required
											/>

											<input
												className={this.state.errorLastName ? "invalid" : ""}
												type="text"
												name="lastName"
												autoComplete="off"
												placeholder={"Last name *"}
												onChange={this.handleChange}
												required
											/>

											<input
												className={this.state.errorEmail ? "invalid" : ""}
												type="email"
												name="email"
												placeholder={"Email *"}
												onChange={this.handleChange}
												autoComplete="off"
												required
											/>
											<input
												className={this.state.errorEmail ? "invalid" : ""}
												type="email"
												name="confirmEmail"
												placeholder={"Confirm email *"}
												onChange={this.handleChange}
												autoComplete="off"
												required
											/>
											<input
												className={this.state.errorPhoneNumber ? "invalid" : ""}
												type="tel"
												name="phoneNumber"
												placeholder={"Phone number *"}
												onChange={this.handleChange}
												autoComplete="off"
												required
											/>
											<input
												className={this.state.errorSocialUrl ? "invalid" : ""}
												type="text"
												name="socialUrl"
												placeholder={"Facebook/Instagram link"}
												onChange={this.handleChange}
												autoComplete="off"
											/>
											<input
												type="text"
												name="pronouns"
												placeholder={"Pronouns"}
												onChange={this.handleChange}
												autoComplete="off"
											/>

											<div className={this.state.errorStripeField ? 'invalid stripe-input-container' : 'stripe-input-container'}>
												{/* <CardElement /> */}
												{
													<ElementsConsumer>
														{({ stripe, elements }) => (
															<InjectedForm ref={this.injectedform} stripe={stripe} elements={elements} cardHolder={`${this.state.firstName} ${this.state.lastName}`} />
														)}
													</ElementsConsumer>
												}
											</div>

											{
												!!this.state.errorPaymentForm &&
												<div className="error-container">
													<div className="error-wrapper">
														<p className="error">
															{this.state.errorPaymentForm}
															{
																!!this.state.errorStripePaymentForm &&
																<> <br /> {this.state.errorStripePaymentForm} </>
															}
															{
																!!this.state.errorCodePaymentForm &&
																<> <br /> Error : {this.state.errorCodePaymentForm} </>
															}
														</p>
													</div>
												</div>
											}


											{
												this.state.paymentError &&
												<div className="error-container">
													<div className="error-wrapper">
														<p className="error">There was an issue with your payment<br />Please try again.</p>
													</div>
												</div>
											}

											{
												this.state.bookingError &&
												<div className="error-container">
													<div className="error-wrapper">
														<p className="error">There was an issue with the booking.<br />This tattoo or booking time is not available anymore.<br />
															Please go back and try again.</p>
													</div>
												</div>
											}

											<div className="terms-container">
												<p>By sending the deposit payment, I agree to both the artist’s and bookme.ink’s terms and conditions.</p>
												<p>I understand that the current payment is a deposit, and an additional sum must be paid in-store at the time of the appointment. I also understand that fees from bookme.ink are non-refundable under any circumstances.</p>
											</div>

											<div className="buttons-container">
												<button className="button" onClick={() => {
													this.setState({
														activeView: 1,
														firstName: '',
														lastName: '',
														email: '',
														errorFirstName: false,
														errorLastName: false,
														errorEmail: false,
														errorPaymentForm: '',
														errorCodePaymentForm: '',
														errorStripeField: false
													}, function () { scroll.scrollToTop(); });

												}}>&#60;&nbsp;&nbsp;Back</button>
												<button onClick={() => this.handleSubmit()} className="button dark">Send payment</button>
											</div>
										</div>
									}

									{this.state.activeView === 3 &&
										<div className="content-container content-confirmation">
											{!!this.product.data.title && <h1>{this.product.data.title}</h1>}

											<h2>Confirmation</h2>

											<h3>Thank you for your order!</h3>


											{!this.state.eventPostError &&
												<>
													{
														this.props.websiteInfo.data.is24HourConvention ?
															<p>Your appointment on <Moment format="LL" date={this.state.momentDate} /> from {this.state.selectedTime.start} to {this.state.selectedTime.end} was successfully scheduled.</p>
															:
															<p>Your appointment on <Moment format="LL" date={this.state.momentDate} /> from {convertTo12h(this.state.selectedTime.start)} to {convertTo12h(this.state.selectedTime.end)} was successfully scheduled.</p>
													}

												</>
											}

											{this.state.eventPostError &&
												<>
													{
														this.props.websiteInfo.data.is24HourConvention ?
															<p>Your payment was successfully received but we were unable to book the appointment on <Moment format="LL" date={this.state.momentDate} /> from {this.state.selectedTime.start} to {this.state.selectedTime.end}. Please contact the artist directly to confirm the time of your appointment.</p>
															:
															<p>Your payment was successfully received but we were unable to book the appointment on <Moment format="LL" date={this.state.momentDate} /> from {convertTo12h(this.state.selectedTime.start)} to {convertTo12h(this.state.selectedTime.end)}. Please contact the artist directly to confirm the time of your appointment.</p>
													}

												</>
											}

											<div className="buttons-container">
												<Link className="cancel button" to={`/${this.props.siteUrl}/flashes`}>Book another flash</Link>
												{
													this.state.eventPostError &&
													<Link className="button dark" to={`/${this.props.siteUrl}/contact`}>Contact</Link>
												}
											</div>
										</div>
									}
								</div>
							</section>
						</main>
					</>
				);
			} else {
				return (
					<>
						<main className="page-single-flash">
							<section className="flash-details">
								<div className="wrapper">
									<p className="no-avail">This design is no longer available</p>
								</div>
							</section>
						</main>

					</>
				)
			}


		} else { return <main className="page-single-flash"></main> }
	}

	handleSubmit() {

		function validateEmail(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		}

		function validatePhoneNumber(number) {
			var re = /^([+]?)((\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
			return re.test(String(number))
		}

		function validateSocialUrl(url) {
			var re = /facebook|instagram/
			return re.test(url)
		}

		if (this.state.firstName === "" || this.state.lastName === "" || this.state.email === "" || this.state.phoneNumber === "") {

			let errorMessage = ""
			let errorFirstName = false;
			let errorLastName = false;
			let errorEmail = false;
			let errorPhoneNumber = false;


			if (this.state.firstName === "") errorFirstName = true;
			if (this.state.lastName === "") errorLastName = true;
			if (this.state.email === "") errorEmail = true;
			if (this.state.phoneNumber === "") errorPhoneNumber = true;

			const errorsArray = [errorFirstName, errorLastName, errorEmail, errorPhoneNumber];
			var errorsCount = 0;
			for (var i = 0; i < errorsArray.length; ++i) {
				if (errorsArray[i] === true)
					errorsCount++;
			}

			if (errorsCount >= 2) {
				errorMessage = 'All fields are required.'
			} else if (errorFirstName) {
				errorMessage = 'Please enter your first name.'
			} else if (errorLastName) {
				errorMessage = 'Please enter your last name.'
			} else if (errorEmail) {
				errorMessage = 'Please enter your email.'
			} else if (errorPhoneNumber) {
				errorMessage = "Please enter your phone number."
			}

			this.setState({
				errorFirstName: errorFirstName,
				errorLastName: errorLastName,
				errorEmail: errorEmail,
				errorPhoneNumber: errorPhoneNumber,
				errorPaymentForm: errorMessage
			});

			this.validateStripeField(errorMessage)

		} else if (!validateEmail(this.state.email)) {
			const errorMessage = "The email address is invalid."
			this.setState(
				{
					errorEmail: true,
					errorPaymentForm: errorMessage
				}, () => this.validateStripeField()
			);

		} else if (!validatePhoneNumber(this.state.phoneNumber)) {
			const errorMessage = "The phone number is invalid."
			this.setState(
				{
					errorPhoneNumber: true,
					errorPaymentForm: errorMessage
				}, () => this.validateStripeField()
			);
		} else if (this.state.email !== this.state.confirmEmail) {
			const errorMessage = "Your email and email confirmation do not match."
			this.setState(
				{
					errorEmail: true,
					errorPaymentForm: errorMessage
				}, () => this.validateStripeField()
			)
		} else if (this.state.socialUrl !== "" && !validateSocialUrl(this.state.socialUrl)) {
			const errorMessage = "The Facebook/Instagram link is not valid."
			this.setState(
				{
					errorSocialUrl: true,
					errorPaymentForm: errorMessage
				}, () => this.validateStripeField()
			);
		} else {
			this.setState({ errorPaymentForm: "", errorStripePaymentForm: '' }, () => {
				this.submitPayment();
			})
		}
	}

	async validateStripeField() {
		// Only executes when a previous field already causes an error
		//this.setState({ isLoading: true });

		/*this.props.stripe.createToken({ name: `${this.state.firstName} ${this.state.lastName}` }).then(({ token, error }) => {
			if (error) {
				// handle error with stripe validation
	
				if (error.hasOwnProperty('type') && error.type === "validation_error") {
	
					let errorMessage = `${this.createStripeErrorMessage(error)}`;
	
					this.setState({
						errorStripeField: true,
						errorStripePaymentForm: errorMessage,
						errorCodePaymentForm: '',
						isLoading: false
					})
				} else {
					this.setState({
						errorStripeField: true,
						errorStripePaymentForm: "Your payment information could not be processed.",
						errorCodePaymentForm: error.code,
						isLoading: false
					})
				}
	
			} else {
				this.setState({
					errorStripeField: false,
					errorStripePaymentForm: '',
					errorCodePaymentForm: '',
					isLoading: false
				})
			}
		});*/

	}

	async submitPayment(ev) {
		const paymentAmount = this.product.data.deposit * 100;
		this.setState({ isLoading: true });
		const dateArray = this.state.selectedTime.date.split("-");
		const formattedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
		const start = `${formattedDate}T${this.state.selectedTime.start}:00`;
		const end = `${formattedDate}T${this.state.selectedTime.end}:00`;
		let data = {
			amount: paymentAmount,
			description: this.product.data.title,
			receiptEmail: this.state.email,
			productId: this.product.data.id,
			start: start,
			end: end,
			phone: this.state.phoneNumber,
			pronouns: this.state.pronouns,
			socialUrl: this.state.socialUrl
		};
		let siteUrl = this.props.siteUrl;
		this.props.createPaymentIntent(siteUrl, data).then(async () => {
			if (this.props.paymentIntent.status === 1) {
				var paymentSuccess = await this.injectedform.current.handlePayment(this.props.paymentIntent.data);
				if (paymentSuccess) {
					let image = !!this.product.data.image ? this.product.data.image.imageUrl : "../../assets/images/sites/placeholders/product-placeholder.png";

					let eventData = {
						summary: `BOOKMEINK: ${this.product.data.title}`,
						description: image,
						start: start,
						end: end,
						attendees: this.state.email,
						phoneNumber: this.state.phoneNumber,
						socialUrl: this.state.socialUrl,
						pronouns: this.state.pronouns,
						firstName: this.state.firstName,
						lastName: this.state.lastName
					}

					this.props.postGoogleCalendarEvent(this.props.siteUrl, eventData).then(() => {
						if (this.props.eventConfirmation.data) {
							// Event was successfully posted
							this.setState({
								activeView: 3,
								eventPostError: false,
								isLoading: false,
								momentDate: formattedDate,
								paymentError: false,
								errorStripeField: false,
								errorPaymentForm: '',
								errorCodePaymentForm: ''
							}, function () {
								scroll.scrollToTop();
							})

						} else {
							// Event post error
							this.setState({
								activeView: 3,
								eventPostError: true,
								isLoading: false,
								paymentError: false,
								errorStripeField: false,
								errorPaymentForm: '',
								errorCodePaymentForm: ''
							}, function () {
								scroll.scrollToTop();
							})
						}
					});
				} else {
					//alert("Error payment");
					this.setState({ isLoading: false });
				}
			} else if (this.props.paymentIntent.status === 2) {
				alert("Flash or schedule is not available");
				this.setState({ isLoading: false, activeView: 0 });
			} else {
				alert(this.props.paymentIntent.message);
				this.setState({ isLoading: false, activeView: 0 });
			}
		});

		/*this.props.stripe.createToken({ name: `${this.state.firstName} ${this.state.lastName}` }).then(({ token, error }) => {
			if (error) {
				// handle error with stripe validation
				this.setState({ isLoading: false });
				if (error.hasOwnProperty('type') && error.type === "validation_error") {
					let errorMessage = this.createStripeErrorMessage(error);
					this.setState({
						errorStripeField: true,
						errorPaymentForm: errorMessage,
						errorCodePaymentForm: ''
	
					})
				} else {
					this.setState({
						errorStripeField: true,
						errorPaymentForm: "Your payment could not be processed.",
						errorCodePaymentForm: error.code
					})
				}
			} else {
				// handle token
				const dateArray = this.state.selectedTime.date.split("-");
				const formattedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
				const start = `${formattedDate}T${this.state.selectedTime.start}:00`;
				const end = `${formattedDate}T${this.state.selectedTime.end}:00`;
	
				let data = {
					amount: paymentAmount,
					description: this.product.data.title,
					sourceId: token.id,
					receiptEmail: this.state.email,
					productId: this.product.data.id,
					start: start,
					end: end
				}
	
				let siteUrl = this.props.siteUrl;
				this.props.postStripePaymentConnect(data, siteUrl).then(() => {
					if (this.props.stripeResponse.hasOwnProperty('paid') && this.props.stripeResponse.paid) {
						// Payment success
	
						let image = !!this.product.data.image ? this.product.data.image.imageUrl : "../../assets/images/sites/placeholders/product-placeholder.png";
	
						let eventData = {
							summary: `BOOKMEINK: ${this.product.data.title}`,
							description: image,
							start: start,
							end: end,
							attendees: this.state.email,
							phoneNumber: this.state.phoneNumber,
							firstName: this.state.firstName,
							lastName: this.state.lastName
						}
	
						this.props.postGoogleCalendarEvent(this.props.siteUrl, eventData).then(() => {
							if (this.props.eventConfirmation.data) {
								// Event was successfully posted
								this.setState({
									activeView: 3,
									eventPostError: false,
									isLoading: false,
									momentDate: formattedDate,
									paymentError: false,
									errorStripeField: false,
									errorPaymentForm: '',
									errorCodePaymentForm: ''
								}, function () {
									scroll.scrollToTop();
								})
	
							} else {
								// Event post error
								this.setState({
									activeView: 3,
									eventPostError: true,
									isLoading: false,
									paymentError: false,
									errorStripeField: false,
									errorPaymentForm: '',
									errorCodePaymentForm: ''
								}, function () {
									scroll.scrollToTop();
								})
							}
						});
					} else if (this.props.stripeResponse.statusCode === 2) {
						console.error("user not available");
						this.setState({
							bookingError: true,
							isLoading: false
						})
					} else {
						// Stripe payment failure						
						this.setState({
							paymentError: true,
							isLoading: false
						})
					}
				})
			}
		});*/
		console.log("This is a test of a trigger");
	}

	createStripeErrorMessage(error) {
		switch (error.code) {
			case 'incomplete_number' ||
				'invalid_number':
				return "The credit card number is invalid.";
				break;
			case "incomplete_expiry" ||
				"invalid_expiry_year" ||
				"invalid_expiry_year_past" ||
				"invalid_expiry_month_past":
				return "The credit card expiration date is invalid or expired.";
				break;
			case "incomplete_cvc":
				return "The credit card security number is invalid."
				break;
			case "incomplete_zip":
				return "The zip code linked to the credit card is invalid."
				break;
			default:
				return "There seems to be an error in your credit card information."
				break;
		}
	}

	minutesToHours(min) {
		if (min <= 60) {
			return min + " minutes";
		} else if (min % 60 !== 0) {
			return Math.floor(min / 60) + " hours and " + min % 60 + " minutes"
		} else {
			return Math.floor(min / 60) + " hours";
		}
	}

	generateSchedule(events, availability) {

		let booking = {};
		let excludeDates = [];

		// Manipulate events data so that they are seperated in single day events.
		let eventsToRemove = [];
		let newEvents = [];

		let bookingRangeMax = availability.bookingRangeMax === null ? 30 : availability.bookingRangeMax;
		let bookingRangeMin = availability.bookingRangeMin === null ? 7 : availability.bookingRangeMin;

		for (let i = 0; i < events.length; i++) {

			let start = events[i].start;
			let startString = start.toString();
			let startArray = startString.split(/-|T|:/);

			let end = events[i].end;
			let endString = end.toString();
			let endArray = endString.split(/-|T|:/);

			let startYear = startArray[0];
			let endYear = endArray[0];
			let startMonth = startArray[1];
			let endMonth = endArray[1];
			let startDay = startArray[2];
			let endDay = endArray[2];

			if (startDay !== endDay || startMonth !== endMonth || startYear !== endYear) {
				eventsToRemove.push(i);

				let startYearInt = parseInt(startYear);
				let endYearInt = parseInt(endYear);
				let startMonthInt = parseInt(startMonth)
				let endMonthInt = parseInt(endMonth);
				let startDayInt = parseInt(startDay)
				let endDayInt = parseInt(endDay)

				let startHours = startArray[3];
				let endHours = endArray[3];
				let startMinutes = startArray[4];
				let endMinutes = endArray[4];
				let startSeconds = startArray[5];
				let endSeconds = endArray[5];

				let timezoneArray = startString.split("-");
				let timezone = timezoneArray[3];

				let loopYear = startYearInt;
				let loopMonth = startMonthInt;
				let loopDay = startDayInt;

				function getDaysInMonth(month, year) {
					return new Date(year, month, 0).getDate();
				};

				function twoDigits(number) {
					if (number < 10) {
						return `0${number}`
					} else {
						return `${number}`
					}
				}

				while (loopYear !== endYearInt || loopMonth !== endMonthInt || loopDay !== endDayInt) {
					const daysInMonth = getDaysInMonth(loopMonth, loopYear);

					// Create new day object
					let newEventStart = "";
					let newEventEnd = "";

					if (loopYear === startYearInt && loopMonth === startMonthInt && loopDay === startDayInt) {
						// First day 
						newEventStart = `${startYearInt}-${twoDigits(startMonthInt)}-${twoDigits(startDayInt)}T${startHours}:${startMinutes}:${startSeconds}-${timezone}`;
						newEventEnd = `${startYearInt}-${twoDigits(startMonthInt)}-${twoDigits(startDayInt)}T23:45:00-${timezone}`;

					} else if (loopYear === endYearInt && loopMonth === endMonthInt && loopDay === endDayInt) {
						// Last day
						if (endHours !== "00" || endMinutes !== "00") {
							newEventStart = `${endYearInt}-${twoDigits(endMonthInt)}-${twoDigits(endDayInt)}T00:00:00-${timezone}`;
							newEventEnd = `${endYearInt}-${twoDigits(endMonthInt)}-${twoDigits(endDayInt)}T${endHours}:${endMinutes}:${endSeconds}-${timezone}`;
						}

					} else {
						// Days inbetween
						newEventStart = `${loopYear}-${twoDigits(loopMonth)}-${twoDigits(loopDay)}T00:00:00-${timezone}`;
						newEventEnd = `${loopYear}-${twoDigits(loopMonth)}-${twoDigits(loopDay)}:T23:45:00-${timezone}`;
					}

					if (!!newEventStart && !!newEventEnd) {
						let newEvent = {
							start: newEventStart,
							end: newEventEnd
						}
						newEvents.push(newEvent);
					}

					// Change to next loopday					
					if (daysInMonth === loopDay) {
						loopDay = 1;
						if (loopMonth === 12) {
							loopMonth = 1;
							loopYear += 1;
						} else {
							loopMonth += 1;
						}
					} else {
						loopDay += 1;
					}
				}
			}
		}


		// Remove multidays events
		if (eventsToRemove.length > 0) {
			for (let r = eventsToRemove.length - 1; r >= 0; r--) {
				events.splice(eventsToRemove[r], 1);
			}
		}


		// Add singleday events made from multidays events
		for (let a = 0; a < newEvents.length; a++) {
			events.push(newEvents[a]);
		}

		// Create organized unavailabilities object
		let unavailabilities = {};
		for (let i = 0; i < events.length; i++) {

			let start = events[i].start;
			let startString = start.toString();
			let startArray = startString.split(/-|T|:/);

			let end = events[i].end;
			let endString = end.toString();
			let endArray = endString.split(/-|T|:/);

			let startIntM = 0;
			let startIntH = parseInt(startArray[3]);
			let endIntM = 0;
			let endIntH = parseInt(endArray[3]);

			// Interpret minutes values to create fit 30 minutes ranges
			if (startArray[4] !== "00" && startArray[4] !== "30") {
				const intVal = parseInt(startArray[4]);
				if (intVal < 30) {
					startIntM = 0;
					startArray[4] = "00";
				} else {
					startIntM = 0.5;
					startArray[4] = "30";
				}
			} else {
				startIntM = startArray[4] === "30" ? 0.5 : 0;
			}

			if (endArray[4] !== "00" && endArray[4] !== "30") {
				const intVal = parseInt(endArray[4]);

				if (intVal < 30) {
					endIntM = 0.5;
					endArray[4] = "30";
				} else {
					endIntH = parseInt(endArray[3]) + 1;
					endIntM = 0;
					endArray[3] = (endIntH < 10) ? `0${endIntH}` : `${endIntH}`;
					endArray[4] = "00";
				}

			} else { endIntM = endArray[4] === "30" ? 0.5 : 0; }

			let unavailabilityObj = {
				start: `${startArray[3]}:${startArray[4]}`,
				end: `${endArray[3]}:${endArray[4]}`,
				startInt: startIntM + startIntH,
				endInt: endIntM + endIntH
			}


			let key = `${startArray[2]}-${startArray[1]}-${startArray[0]}`;

			if (unavailabilities.hasOwnProperty(key)) {
				unavailabilities[key].push(unavailabilityObj);

			} else {
				unavailabilities[key] = [unavailabilityObj];
			}
		}


		// Creates array of days in the availability bookingRange.
		let availabilityDays = [];

		for (let i = bookingRangeMin; i <= bookingRangeMax; i++) {
			let date = new Date();
			date.setDate(date.getDate() + i);
			let dateString = date.toString();
			let dateArray = dateString.split(" ");

			let monthNumber = "";
			switch (dateArray[1]) {
				case "Jan":
					monthNumber = "01";
					break;
				case "Feb":
					monthNumber = "02";
					break;
				case "Mar":
					monthNumber = "03";
					break;
				case "Apr":
					monthNumber = "04";
					break;
				case "May":
					monthNumber = "05";
					break;
				case "Jun":
					monthNumber = "06";
					break;
				case "Jul":
					monthNumber = "07";
					break;
				case "Aug":
					monthNumber = "08";
					break;
				case "Sep":
					monthNumber = "09";
					break;
				case "Oct":
					monthNumber = "10";
					break;
				case "Nov":
					monthNumber = "11";
					break;
				case "Dec":
					monthNumber = "12";
					break;
				default:
					monthNumber = "";
			}

			let key = `${dateArray[2]}-${monthNumber}-${dateArray[3]}`;

			if (!availabilityDays.includes(key)) {
				availabilityDays.push(key);
			}
		}


		// Create booking object and calendar exceptions
		this.calendarExceptions = [];
		for (let i = 0; i < availabilityDays.length; i++) {

			// Get current week day
			const curDayInfo = availabilityDays[i].split("-");
			const currentDay = new Date(curDayInfo[2], (curDayInfo[1] - 1), curDayInfo[0]);
			const currentDayString = currentDay.toString();
			const currentDayInfo = currentDayString.split(" ");
			const currentWeekDayMin = currentDayInfo[0];
			let currentWeekDay = "";

			switch (currentWeekDayMin) {
				case "Mon":
					currentWeekDay = "monday";
					break;
				case "Tue":
					currentWeekDay = "tuesday";
					break;
				case "Wed":
					currentWeekDay = "wednesday";
					break;
				case "Thu":
					currentWeekDay = "thursday";
					break;
				case "Fri":
					currentWeekDay = "friday";
					break;
				case "Sat":
					currentWeekDay = "saturday";
					break;
				case "Sun":
					currentWeekDay = "sunday";
					break;
				default:
					currentWeekDay = "";
			}

			let storeHours = null;
			let maxBookings = null;
			let maxBookingReached = false;

			// Verify max bookings per day 
			if (availability[currentWeekDay] !== null) {
				const maxBookingCharIndex = availability[currentWeekDay].indexOf("|");
				if (maxBookingCharIndex !== -1) {
					// Has max booking per day
					maxBookings = parseInt(availability[currentWeekDay].split('|')[1]);
					let availabilityString = availability[currentWeekDay].substring(0, maxBookingCharIndex);
					storeHours = availabilityString.split(",");

					if (this.purchases !== null && this.purchases.hasOwnProperty(availabilityDays[i]) && this.purchases[availabilityDays[i]] >= maxBookings) {
						// Max bookings reached 
						excludeDates.push(currentDay);
						maxBookingReached = true;
					}

				} else {
					storeHours = availability[currentWeekDay].split(",");
				}
			}


			if (storeHours !== null && !maxBookingReached) {
				// Store Opened

				let openHInt = parseInt(storeHours[0].split(":")[0]);
				let openMInt = 1 / (60 / parseInt(storeHours[0].split(":")[1]));
				let openInt = openHInt + openMInt;
				let closeHInt = parseInt(storeHours[1].split(":")[0]);
				let closeMInt = 1 / (60 / parseInt(storeHours[1].split(":")[1]));
				let closeInt = closeHInt + closeMInt;

				// Remove unavailabilities that are fully out of storehours
				if (unavailabilities.hasOwnProperty(availabilityDays[i])) {

					// Find events out of store hours.
					let outOfStoreHoursIndexes = [];
					for (let z = 0; z < unavailabilities[availabilityDays[i]].length; z++) {
						let currentUnavailability = unavailabilities[availabilityDays[i]][z];

						if (currentUnavailability.endInt <= openInt || currentUnavailability.startInt >= closeInt) {
							outOfStoreHoursIndexes.push(z);
						}
					}

					// Remove the events out of store hours.
					for (var n = outOfStoreHoursIndexes.length - 1; n >= 0; n--) {
						unavailabilities[availabilityDays[i]].splice(outOfStoreHoursIndexes[n], 1);
					}

					// Delete full unavailability days that have no event during store hours.
					if (unavailabilities[availabilityDays[i]].length === 0) {
						delete unavailabilities[availabilityDays[i]];
					}
				}

				// Flash booking duration
				let durationHInt = Math.trunc(this.props.flash.data.time / 60);
				let durationMInt = (this.props.flash.data.time % 60) / 60;
				let durationInt = durationHInt + durationMInt;

				if (unavailabilities.hasOwnProperty(availabilityDays[i])) {
					// Partially available day

					// Find availabilities of any duration for that day.
					// Sort unavailabilities from the earliest to the latest
					unavailabilities[availabilityDays[i]].sort((a, b) => a.startInt - b.startInt);

					// Remake events that are partially during store hours 
					for (let r = 0; r < unavailabilities[availabilityDays[i]].length; r++) {
						const currentUnavailability = unavailabilities[availabilityDays[i]][r];

						if (currentUnavailability.startInt < openInt && currentUnavailability.endInt > openInt) {
							unavailabilities[availabilityDays[i]][r].startInt = openInt;
							unavailabilities[availabilityDays[i]][r].start = storeHours[0];

						} else if (currentUnavailability.startInt < closeInt && currentUnavailability.endInt > closeInt) {
							unavailabilities[availabilityDays[i]][r].endInt = closeInt;
							unavailabilities[availabilityDays[i]][r].end = storeHours[1];
						}
					}

					// Find availabilities ranges.
					let currentDayAvailabilities = [];

					// From opening to first start event
					if (unavailabilities[availabilityDays[i]][0].startInt !== openInt) {
						let tempFreeTime = {};
						tempFreeTime.startInt = openInt;
						tempFreeTime.start = storeHours[0];
						tempFreeTime.endInt = unavailabilities[availabilityDays[i]][0].startInt;
						tempFreeTime.end = unavailabilities[availabilityDays[i]][0].start;

						if (tempFreeTime.endInt - tempFreeTime.startInt >= durationInt) {
							findAvailabilities(tempFreeTime);
						}
					}

					// From last end event to closing
					let lastIndex = unavailabilities[availabilityDays[i]].length - 1;
					if (unavailabilities[availabilityDays[i]][lastIndex].endInt !== closeInt) {
						let tempFreeTime = {};
						tempFreeTime.startInt = unavailabilities[availabilityDays[i]][lastIndex].endInt;
						tempFreeTime.start = unavailabilities[availabilityDays[i]][lastIndex].end;
						tempFreeTime.endInt = closeInt;
						tempFreeTime.end = storeHours[1];

						if (tempFreeTime.endInt - tempFreeTime.startInt >= durationInt) {
							findAvailabilities(tempFreeTime);
						}
					}

					// During the day
					for (let r = 0; r < unavailabilities[availabilityDays[i]].length - 1; r++) {
						let nextR = r + 1
						const currentUnavailability = unavailabilities[availabilityDays[i]][r];
						const nextUnavailability = unavailabilities[availabilityDays[i]][nextR];

						if (currentUnavailability === null) {
							continue;
						}

						let tempFreeTime = {};
						tempFreeTime.startInt = currentUnavailability.endInt;
						tempFreeTime.start = currentUnavailability.end;
						tempFreeTime.endInt = nextUnavailability.startInt;
						tempFreeTime.end = nextUnavailability.start;

						if (tempFreeTime.endInt - tempFreeTime.startInt >= durationInt) {
							findAvailabilities(tempFreeTime);
						}
					}

					// Add the availabilities to the booking object only if there are availabilities

					if (currentDayAvailabilities.length !== 0) {
						booking[availabilityDays[i]] = currentDayAvailabilities;
					} else {
						// Add the date to the exclusions that will be sent to the datepicker
						excludeDates.push(currentDay);
					}

					function findAvailabilities(freetime) {
						for (let a = 0; a + freetime.startInt + durationInt <= freetime.endInt; a += 0.5) {
							let currentNewAvailability = {};
							currentNewAvailability.startInt = freetime.startInt + a;
							currentNewAvailability.endInt = freetime.startInt + durationInt + a;

							let availStartM = (currentNewAvailability.startInt % 1 === 0) ? "00" : "30";
							let availStartH = (Math.trunc(currentNewAvailability.startInt) < 10) ?
								`0${Math.trunc(currentNewAvailability.startInt)}` : `${Math.trunc(currentNewAvailability.startInt)}`;

							currentNewAvailability.start = `${availStartH}:${availStartM}`;

							let availEndM = (currentNewAvailability.endInt % 1 === 0) ? "00" : "30";
							let availEndH = (Math.trunc(currentNewAvailability.endInt) < 10) ?
								`0${Math.trunc(currentNewAvailability.endInt)}` : `${Math.trunc(currentNewAvailability.endInt)}`;

							currentNewAvailability.end = `${availEndH}:${availEndM}`;
							currentDayAvailabilities.push(currentNewAvailability);
						}
					}

				} else {
					// find open time
					let openingDuration = 0;
					if (openMInt === closeMInt) {
						openingDuration = closeHInt - openHInt;
					} else if (openMInt < closeMInt) {
						openingDuration = (closeHInt - openHInt) + closeMInt;
					} else {
						openingDuration = (closeHInt - openHInt) - openMInt;
					}

					// find availability Amount
					let availabilityAmount = 0;
					if (openingDuration >= (durationHInt + durationMInt)) {
						if (!durationMInt) {
							availabilityAmount = ((openingDuration - durationHInt) / 0.5) + 1;
						} else {
							availabilityAmount = (((openingDuration - durationHInt) - 0.5) / 0.5) + 1;
						}
					}

					// Create availability in booking object
					let currentDayAvailabilities = [];
					for (let a = 0; a < (availabilityAmount * 0.5); a += 0.5) {
						let availStartMInt = openMInt;
						let availStartHInt = openHInt;
						let availEndMInt = openMInt + durationMInt === 1 ? 0 : openMInt + durationMInt;
						let availEndHInt = openMInt + durationMInt === 1 ? openHInt + durationHInt + 1 : openHInt + durationHInt;

						if (a % 1 === 0) {
							availStartHInt += a;
							availEndHInt += a;

						} else {
							if (availStartMInt + (a % 1) === 1) {
								availStartHInt += (Math.trunc(a) + 1);
								availStartMInt = 0;
							} else {
								availStartHInt += Math.trunc(a);
								availStartMInt += a % 1;
							}

							if (availEndMInt + (a % 1) === 1) {
								availEndHInt += (Math.trunc(a) + 1);
								availEndMInt = 0;
							} else {
								availEndHInt += Math.trunc(a);
								availEndMInt += a % 1;
							}
						}

						let availStartH = (availStartHInt < 10) ? `0${availStartHInt}` : `${availStartHInt}`;
						let availStartM = ((availStartMInt * 60) < 10) ? `0${availStartMInt * 60}` : `${availStartMInt * 60}`;
						let availStart = `${availStartH}:${availStartM}`;
						let availEndM = (availEndMInt === 0) ? "00" : "30";
						let availEndH = (availEndHInt < 10) ? `0${availEndHInt}` : `${availEndHInt}`;
						let availEnd = `${availEndH}:${availEndM}`;

						let availabilityObj = {
							start: availStart,
							end: availEnd
						}
						currentDayAvailabilities.push(availabilityObj)
					}

					if (currentDayAvailabilities.length !== 0) {
						booking[availabilityDays[i]] = currentDayAvailabilities;
					} else {
						// Add the date to the exclusions that will be sent to the datepicker
						excludeDates.push(currentDay);
					}

				}
			} else {
				// Store Closed
				excludeDates.push(currentDay);
			}
		}


		// Loop in booking array. Remove propreties that are empty arrays and add their date value to the excludeDates

		if (Object.keys(booking).length === 0 && booking.constructor === Object) {
			// Doesn't have availabilities
			console.log("ici");
			this.setState({
				isLoading: false,
				error: true
			});

		} else {
			// Has availabilities

			let selectorDate = null;
			// Remove days without availabilities from availabilityDays.
			let availabilityDaysToRemove = [];
			for (let availIndex = 0; availIndex < availabilityDays.length; availIndex++) {
				if (!booking.hasOwnProperty(availabilityDays[availIndex])) {
					availabilityDaysToRemove.push(availIndex);
				}
			}
			for (var n = availabilityDaysToRemove.length - 1; n >= 0; n--) {
				availabilityDays.splice(availabilityDaysToRemove[n], 1);
			}

			selectorDate = availabilityDays[0];

			let firstAvailabilityArray = null;
			let firstAvailableDate = null;

			if (selectorDate !== null) {
				firstAvailabilityArray = selectorDate.split("-");
				firstAvailableDate = new Date(firstAvailabilityArray[2], (firstAvailabilityArray[1] - 1), firstAvailabilityArray[0]);
			}

			this.setState({
				excludeDates: excludeDates,
				bookingHours: booking,
				activeView: 1,
				isLoading: false,
				minDate: bookingRangeMin,
				maxDate: bookingRangeMax,
				pickerDate: firstAvailableDate,
				selectorDate: selectorDate,
				availabilityDays: availabilityDays,
				error: false

			}, function () {
				scroll.scrollToTop();
			})
		}
	}
}

const mapStateToProp = state => {
	return {
		flash: state.flash.flash,
		isFetchingFlash: state.flash.isFetching,
		calendar: state.googleCalendar.calendar,
		isFetchingCalendar: state.googleCalendar.isFetching,
		availability: state.availability.availability,
		isFetchingAvailability: state.availability.isFetching,
		isPostingCalendarEvent: state.googleCalendar.isPosting,
		eventConfirmation: state.googleCalendar.eventConfirmation,
		stripeResponse: state.stripe.stripeResponse,
		isFecthingStripe: state.stripe.isFetching,
		websiteInfo: state.websiteInfo.websiteInfo,
		purchases: state.stripe.purchases,
		paymentIntent: state.stripe.paymentIntent
	}
}

export default connect(mapStateToProp, { fetchFlashById, fetchGoogleCalendar, fetchAvailability, postGoogleCalendarEvent, getPurchases, createPaymentIntent })(SitesSingleFlash);
