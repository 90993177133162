import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUserCategories, postCategory } from '../../actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Loading from "./partials/loading";


class Categories extends Component {
	state = {
		name: null,
		error: false
	}
	componentDidMount() {
		this.props.fetchCurrentUserCategories();
	}
	handleInputChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	
	handleSubmit() {
		let data = {
			name: this.state.name
		}
		if (this.state.name !== null) {
			this.props.postCategory(data).then(() => {
				this.props.fetchCurrentUserCategories();
				this.setState({ name: null });
				this.inputName.value = "";
			});
		}
	}
	render() {

		return (
		
				<section className="categories">
					<h1>Categories</h1>
					<Loading isLoading={_.isEmpty(this.props.categories) || this.props.isFetching} />

					{( this.props.categories !== null && this.props.categories.data.length > 0)  &&
						<div className="field-container">
							<h2>Edit categories</h2>
							{
								this.props.categories.data.map((item, key) => {
									return <Link className="button" key={key} to={`/admin/flashes/category/${item.categoryId}`}>{item.name}</Link>
								})
							}
						</div>
					}

					<div className="field-container">
						<h2>Add a category</h2>
						<input ref={el => this.inputName = el} type="text" name="name" placeholder="category name" onChange={(text) => this.handleInputChange(text)} />
					</div>


					<button className={!!this.state.name ? '' : 'prevent-click'} onClick={() => this.handleSubmit()}>Add category</button>
				</section>
		
		);
	}
}

const mapStateToProps = state => ({
	categories: state.category.categories,
	category: state.category.category,
	isFetching: state.category.isFetching,
});

export default connect(mapStateToProps, { fetchCurrentUserCategories, postCategory })(Categories);