import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUserProducts } from '../../actions';
import _ from 'lodash';
import Navigation from './partials/navigation';
import MyProducts from './partials/myProducts';
import AddProducts from './partials/addProducts';
import Loading from "./partials/loading";

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.fetchCurrentUserProducts();
    }


    fetchUserProducts() {
        this.props.fetchCurrentUserProducts();
    }

    render(props) {
        return (

            <div className="dashboard-wrapper">
                <Navigation active="products" subMenu={true} pathname={this.props.location.pathname} subMenuItems={[{ name: "My products", link: "/admin/products/myproducts" }, { name: "Add a product", link: "/admin/products/addproducts" }]} subMenuTitle={"Products"}/>
                
                {
                    <div className="dashboard-content-wrapper">
                        <Loading isLoading={this.props.isFetching} />

                        {!_.isEmpty(this.props.products) && 
                            <> 
                                {
                                    this.props.location.pathname === '/admin/products/myproducts' && <MyProducts fetchUserProducts={() => this.fetchUserProducts()} products={this.props.products.data} location={this.props.location} currentUser={this.props.currentUser}/>
                                }
                                {
                                    this.props.location.pathname === "/admin/products/addproducts" && <AddProducts history={this.props.history} products={this.props.products.data} currentUser={this.props.currentUser}/>
                                }
                            </>
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    products: state.product.products,
    isFetching: state.product.isFetching
});

export default connect(mapStateToProps, { fetchCurrentUserProducts })(Products);