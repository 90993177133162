


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchProductById, putProduct, upload, fetchCurrentUserWebsiteInfo } from '../../actions';
import _ from 'lodash';
import Navigation from './partials/navigation';
import Loading from "./partials/loading";
import { Link } from 'react-router-dom';
import { hasStoreAddress } from '../../services/addressServices'

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class ProductsSingle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            websiteInfo: null,
            file: null,
            product: null,
            imageChange: false,
            errorRequired: false,
            editorState: EditorState.createEmpty()
        }

        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleWysiwygChange = this.handleWysiwygChange.bind(this)
    }

    componentDidMount() {
        this.props.fetchProductById(this.props.match.params.id).then(() => {

            let newEditorState = EditorState.createEmpty();

            if (!!this.props.product.data.data.description) {
                const blockFromHtml = convertFromHTML(this.props.product.data.data.description);
                if (blockFromHtml.contentBlocks.length > 0) {
                    const newContentState = ContentState.createFromBlockArray(
                        blockFromHtml.contentBlocks,
                        blockFromHtml.entityMap
                    );
                    newEditorState = EditorState.createWithContent(newContentState);
                }
            }

            let newProductData = this.props.product.data;

            newProductData.data.price = newProductData.data.price === 0 ? "0.00" : newProductData.data.price;
            newProductData.data.localShippingCost = newProductData.data.localShippingCost === 0 ? "0.00" : newProductData.data.localShippingCost;
            newProductData.data.internationalShippingCost = newProductData.data.internationalShippingCost === 0 ? "0.00" : newProductData.data.internationalShippingCost;

            this.setState({
                product: this.props.product.data,
                editorState: newEditorState
            });
        });

        this.props.fetchCurrentUserWebsiteInfo().then(() => this.setState({ websiteInfo: this.props.websiteInfo.websiteInfo.data[0] }));
    }


    handleFileChange(e) {
        this.setState({ file: e.target.files[0] }, function () {
            this.fileUpload();
        });
    }


    fileUpload() {
        const formData = new FormData();
        formData.append('files', this.state.file);
        this.props.upload(formData).then(() => this.props.uploadResponse.fileUrl !== null && this.setProductImgState());
    }


    handleImageDelete() {
        const newProductData = this.state.product;
        newProductData.data.image.imageUrl = null;
        this.setState({ product: newProductData, imageChange: true });
    }


    setProductImgState() {
        const newProductData = this.state.product;
        newProductData.data.image = {
            imageUrl: this.props.uploadResponse.fileUrl
        }
        this.setState({ pageContent: newProductData, imageChange: true });
    }


    handleShippingCheckboxes(e) {
        let newProduct = this.state.product;

        if (e.target.name === "parcelShipping") {
            // parcelShipping
            if (this.state.product.data.shippingOption) {
                newProduct.data.localShippingCost = "0.00";
                newProduct.data.internationalShippingCost = "0.00";
                newProduct.data.shippingOption = false;
            } else {
                newProduct.data.shippingOption = true;
            }
            this.setState({ product: newProduct });


        } else {
            // parcelPickup
            if (!hasStoreAddress(this.state.websiteInfo.address)) {
                alert('A store address must be added to your website\'s general settings in order to activate the "Parcel pickup" option.')
            } else {
                newProduct.data.pickupOption = !this.state.product.data.pickupOption;
                this.setState({ product: newProduct });
            }
        }
    }


    handleInputChange(event) {
        let newProductData = this.state.product;

        if (event.target.name === "quantity") {
            let newQuantity = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value);
            newProductData.data[event.target.name] = newQuantity;
            this.setState({ product: newProductData });

        } else if (
            event.target.name === "internationalShippingCost" ||
            event.target.name === "localShippingCost" ||
            event.target.name === "price"
        ) {

            if (
                this.state.product.data[event.target.name] === "0.00" ||
                (
                    this.state.product.data[event.target.name].toString() === "0" &&
                    !isNaN(
                        parseInt(
                            event.target.value.toString().charAt(event.target.value.toString().length - 1)
                        )
                    ) &&
                    event.target.value.toString().indexOf('.') === -1
                )

            ) {
                newProductData.data[event.target.name] = event.target.value.toString().charAt(event.target.value.toString().length - 1);
                this.setState({ product: newProductData });

            } else if (event.target.value.toString() === "") {
                newProductData.data[event.target.name] = 0;
                this.setState({ product: newProductData });

            } else if (
                (
                    event.target.value.toString().indexOf('.') !== -1 &&
                    event.target.value.toString().split('.')[1].length <= 2
                ) || event.target.value.toString().indexOf('.') === -1

            ) {
                let newPrice = isNaN(event.target.value) ? 0 : event.target.value;
                newProductData.data[event.target.name] = newPrice;
                this.setState({ product: newProductData });
            }

        } else {
            newProductData.data[event.target.name] = event.target.value;
            this.setState({ product: newProductData });
        }
    }


    handleWysiwygChange = (editorState) => {

        this.setState({ editorState }, () => {
            const newProductData = this.state.product;
            newProductData.data.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

            //REMOVE SPAN 
            newProductData.data.description = newProductData.data.description.replace(/<\/?span[^>]*>/g, "");

            //REMOVE STYLE ATTR
            newProductData.data.description = newProductData.data.description.replace(/<([^ >]+)[^>]*>/ig, '<$1>');

            this.setState({ product: newProductData });
        });
    }


    handleSubmit() {
        let newProductData = this.state.product;

        newProductData.data.price = parseFloat(this.state.product.data.price);
        newProductData.data.localShippingCost = parseFloat(this.state.product.data.localShippingCost);
        newProductData.data.internationalShippingCost = parseFloat(this.state.product.data.internationalShippingCost);

        console.log(newProductData.data);

        if (newProductData.data.title !== "" &&
            newProductData.data.price !== 0 &&
            (
                (newProductData.data.shippingOption &&
                    (
                        newProductData.data.localShippingCost !== 0 &&
                        newProductData.data.internationalShippingCost !== 0)

                ) || (newProductData.data.pickupOption && !newProductData.data.shippingOption)
            )
        ) {

            // Form validation passed
            this.setState({ errorRequired: false });
            if (!this.state.imageChange) delete newProductData.data.image;

            this.props.putProduct(newProductData.data).then(() => {
                if (this.props.product.data.status === 0) {
                    // Error
                    alert('We were unable to save your modifications. Please try again.');
                    console.error('Error in method handleSubmit(). ' + this.props.product.data.message);

                } else {
                    // Success
                    this.props.history.push("/admin/products/myproducts")
                }
            });

        } else {
            // All required fields must be filled
            this.setState({ errorRequired: true });
        }
    }


    render() {

        console.log(this.state);
        let uploadBtnText = this.props.uploadResponse.fileUrl !== null ? 'Modify image' : 'Upload Image';

        return (
            <div className="dashboard-wrapper">
                <Navigation subMenu={true} subMenuItems={[{ name: "My products", link: "/admin/products/myproducts" }, { name: "Add product", link: "/admin/products/addproducts" }]} subMenuTitle={"Products"} />

                <Loading isLoading={this.state.product === null || this.props.isFetching || this.props.uploadResponse.isFetching} />

                <div className="dashboard-content-wrapper">
                    <section className="single-product">

                        <Link className="back-btn" to="/admin/products/myproducts">
                            <img className="checked-image" src={require('../../assets/images/dashboard/icons/back-arrow_4x.png')} />
                            <span>Back to my products</span>
                        </Link>

                        { // EDITABLE PRODUCT
                            (this.state.product !== null && this.state.websiteInfo !== null) &&
                            <>
                                <h2>Information</h2>
                                <div className="field-container">

                                    <div className={(this.state.errorRequired && this.state.product.data.title === "") ? "error input-container" : "input-container"}>
                                        <label>Name *</label>
                                        <input type="text" name="title" onChange={(text) => this.handleInputChange(text)} placeholder={"Name"} defaultValue={this.state.product.data.title} />
                                    </div>

                                    <label>Description</label>
                                    <div className="wysiwyg-container">
                                        <Editor
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.handleWysiwygChange}
                                        />
                                    </div>

                                    <div className="input-container">
                                        <label>Image</label>
                                        <p className="details">Recommended size (500 x 500)</p>
                                        {
                                            this.state.product.data.hasOwnProperty('image') &&
                                            <img className={(this.state.product.data.image !== null && this.state.product.data.image.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.product.data.image !== null && this.state.product.data.image.imageUrl !== null) ? this.state.product.data.image.imageUrl : null} />
                                        }

                                        <div className='buttons-container'>
                                            <label className="upload-button" htmlFor="product-image-upload">{uploadBtnText}</label><input id="product-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                            {(this.state.product.data.hasOwnProperty('image') && this.state.product.data.image !== null && this.state.product.data.image.imageUrl !== null) && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                        </div>
                                    </div>

                                    <div className="input-container">
                                        <div className="field-container">
                                            <label>Available quantity</label>
                                            <input
                                                type="number"
                                                min="0"
                                                name="quantity"
                                                onChange={(text) => this.handleInputChange(text)}
                                                placeholder={"Quantity"}
                                                value={this.state.product.data.quantity.toString()}
                                            />
                                        </div>
                                    </div>
                                </div>



                                <h2>Pricing</h2>
                                <div className="field-container">

                                    <div className={(this.state.errorRequired && parseFloat(this.state.product.data.price) === 0) ? "input-container error" : "input-container"}>
                                        <label>Price * </label>
                                        <input
                                            type="number"
                                            min="0"
                                            name="price"
                                            onChange={(text) => this.handleInputChange(text)}
                                            placeholder={"Product price"}
                                            value={this.state.product.data.price.toString()}
                                        />
                                    </div>


                                    <div classname="input-container">
                                        <label>Currency</label>
                                        <div className="columns">  {
                                            (!!this.props.currentUser && this.props.currentUser.data.currency !== null) ?
                                                <div className="input currency">
                                                    <p>{this.props.currentUser.data.currency.toUpperCase()}</p>
                                                </div>
                                                :
                                                <div className="input currency">
                                                    <p>CAD</p>
                                                </div>
                                        }

                                            <Link className="button" to="/admin/settings/advanced">
                                                <span>Change currency</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>


                                <h2>Shipping</h2>

                                <div className="field-container">
                                    <div className={(!this.state.product.data.pickupOption && !this.state.product.data.shippingOption && this.state.errorRequired) ? "input-container error" : "input-container"}>
                                        <label>Shipping options *</label>
                                        <div className="checkbox-item">
                                            <input
                                                type="checkbox"
                                                name="parcelShipping"
                                                id="checkbox-parcel-shipping"
                                                checked={this.state.product.data.shippingOption}
                                                onChange={(e) => this.handleShippingCheckboxes(e)}
                                            />
                                            <label htmlFor="checkbox-parcel-shipping" className="checkbox-label">Parcel shipping</label>
                                        </div>

                                        <div className={hasStoreAddress(this.state.websiteInfo.address) ? "checkbox-item" : "checkbox-item disabled"}>
                                            <input
                                                type="checkbox"
                                                name="parcelPickup"
                                                id="checkbox-parcel-pickup"
                                                checked={this.state.product.data.pickupOption}
                                                onChange={(e) => this.handleShippingCheckboxes(e)}
                                            />
                                            <label htmlFor="checkbox-parcel-pickup" className="checkbox-label">Parcel pickup</label>
                                        </div>
                                    </div>

                                    {this.state.product.data.shippingOption &&
                                        <>
                                            <div className={(this.state.errorRequired && parseFloat(this.state.product.data.localShippingCost) === 0) ? "input-container error" : "input-container"}>
                                                <label>Same country shipping cost *</label>
                                                <input
                                                    type="number"
                                                    value={this.state.product.data.localShippingCost.toString()}
                                                    min="0"
                                                    name="localShippingCost"
                                                    onChange={(text) => this.handleInputChange(text)}
                                                    placeholder={"Local shipping"}
                                                />
                                            </div>

                                            <div className={(this.state.errorRequired && parseFloat(this.state.product.data.internationalShippingCost) === 0) ? "input-container error" : "input-container"}>
                                                <label>International shipping cost *</label>
                                                <input
                                                    type="number"
                                                    value={this.state.product.data.internationalShippingCost.toString()}
                                                    min="0"
                                                    name="internationalShippingCost"
                                                    onChange={(text) => this.handleInputChange(text)}
                                                    placeholder={"International shipping"}
                                                />
                                            </div>
                                        </>
                                    }

                                </div>

                                <div className="error-container">
                                    {!!this.state.errorRequired &&
                                        <p className="error">All * fields are required. </p>
                                    }
                                </div>

                                <button onClick={() => this.handleSubmit()}>Modify</button>
                            </>
                        }

                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    product: state.product.product,
    uploadResponse: state.upload,
    websiteInfo: state.websiteInfo,
    isFetching: state.product.isFetching
});

export default connect(mapStateToProps, { fetchProductById, putProduct, upload, fetchCurrentUserWebsiteInfo })(ProductsSingle);