import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteProduct } from '../../../actions';
 
class MyProducts extends Component {

    componentDidMount() {
        this.props.fetchUserProducts();
    }

    handleDelete(id) {
        if (window.confirm("Are you sure you want to delete this product?")) {
            this.props.deleteProduct(id).then(() => {
                this.props.fetchUserProducts();
            })
        }
    }

    render() {
        return (
            <section className='myProducts'>
                <h1>My products</h1>
                {
                    this.props.products.data.length !== 0 ? this.props.products.data.map((item, key) => {
                        return <div key={key} className="productItemContainer" >
                            <Link className={(item.quantity === 0) ? 'sold' : ''} key={key} to={`/admin/products/myproducts/${item.id}`}>

                                <div className="image-container">
                                    {(item.hasOwnProperty('image') && item.image !== null && item.image.imageUrl !== null) ?
                                        <img src={item.image.imageUrl} /> :
                                        <img src={require('../../../assets/images/sites/placeholders/product-placeholder.png')} />
                                    }
                                </div>

                                <div className="details-container">
                                    {(item.quantity !== 0) ?
                                    <>
                                        <h3>{item.title}</h3>
                                        <p>{item.quantity} in inventory</p>
                                    </>
                                    :
                                    <h3><span>Sold out</span>{item.title}</h3>

                                    }
                                </div>
                            </Link>
                            <button className="delete-btn" onClick={() => this.handleDelete(item.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" stroke="black" strokeWidth="2" className="react-timerange-picker__clear-button__icon react-timerange-picker__button__icon"><line x1="4" y1="4" x2="15" y2="15"></line><line x1="15" y1="4" x2="4" y2="15"></line></svg>
                            </button>

                        </div>

                    }) :
                        <>
                            <p>No products were found.</p>
                            <Link className="button" to="/admin/products/addproducts">Create your first product</Link>
                        </>

                }
            </section> 
        );
    }

}

const mapStateToProps = state => ({
    product: state.product,
    isFetching: state.product
});

export default connect(mapStateToProps, { deleteProduct })(MyProducts);


