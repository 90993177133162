import { FETCHING_CATEGORIES_REQUEST, FETCHING_CATEGORIES_SUCCESS, FETCHING_CATEGORIES_FAILURE, FETCHING_CATEGORY_REQUEST, FETCHING_CATEGORY_SUCCESS, FETCHING_CATEGORY_FAILURE, PUT_CATEGORY_REQUEST, PUT_CATEGORY_SUCCESS, PUT_CATEGORY_FAILURE, DELETE_CATEGORY_REQUEST, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAILURE, POST_CATEGORY_REQUEST, POST_CATEGORY_SUCCESS, POST_CATEGORY_FAILURE } from '../actions/types';

const initialState = {
    categories: null,
    category: null,
    deleted: null,
    isFetching: false,
    errors: null 
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCHING_CATEGORIES_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_CATEGORIES_SUCCESS:
            return { ...state, isFetching: false, categories: action.payload };
        case FETCHING_CATEGORIES_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case FETCHING_CATEGORY_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_CATEGORY_SUCCESS:
            return { ...state, isFetching: false, category: action.payload };
        case FETCHING_CATEGORY_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case PUT_CATEGORY_REQUEST:
            return { ...state, isFetching: true };
        case PUT_CATEGORY_SUCCESS:
            return { ...state, isFetching: false, category: action.payload };
        case PUT_CATEGORY_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case DELETE_CATEGORY_REQUEST:
            return { ...state, isFetching: true };
        case DELETE_CATEGORY_SUCCESS:
            return { ...state, isFetching: false, deleted: action.payload };
        case DELETE_CATEGORY_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case POST_CATEGORY_REQUEST:
            return { ...state, isFetching: true };
        case POST_CATEGORY_SUCCESS:
            return { ...state, isFetching: false, category: action.payload };
        case POST_CATEGORY_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;
    }
}