import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postPages, putPages, upload } from '../../../actions';
import _ from 'lodash';
import Loading from "../partials/loading";


import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class SitePagesItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            pageContent: {
                banner: null,
                title: null,
                pageType: this.props.pageType,
                content1: null,
                content2: null,
                content3: null,
                boolean1: false,
                description: null,
                status: 1
            },
            pageExist: false,
            imageChange: false,
            error: false,
            additionalContentParagraphs: 0,
            key: Date.now(),
            counter: 0,
            editorState: EditorState.createEmpty()
        }
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleWysiwygChange = this.handleWysiwygChange.bind(this);
    }

    handleFileChange(e) {
        this.setState({ file: e.target.files[0] }, function () {
            this.fileUpload();
        });
    }

    fileUpload() {
        const formData = new FormData();
        formData.append('files', this.state.file);
        this.props.upload(formData).then(() => this.props.uploadResponse.fileUrl !== null && this.setBannerState());
    }

    handleImageDelete() {
        const newPageData = this.state.pageContent;
        newPageData.banner.imageUrl = null;
        this.setState({ pageContent: newPageData, imageChange: true });
    }

    setBannerState() { 
        const newPageData = this.state.pageContent;
        newPageData.banner = {
            imageUrl: this.props.uploadResponse.fileUrl
        }
        this.setState({ pageContent: newPageData, imageChange: true });
    }

    componentDidMount() {
        this.handleSeedState();
    }

    handleSeedState() {
        for (let i = 0; i < this.props.pages.data.length; i += 1) {
            if (this.props.pages.data[i].pageType === this.props.pageType) {

                let newEditorState = EditorState.createEmpty();
                if (this.props.pages.data[i].content2 !== null && this.props.pages.data[i].content2 !== "") {

                    const blockFromHtml = convertFromHTML(this.props.pages.data[i].content2);


                    if (blockFromHtml.contentBlocks.length > 0) {
                        const newContentState = ContentState.createFromBlockArray(
                            blockFromHtml.contentBlocks,
                            blockFromHtml.entityMap
                        );
                        newEditorState = EditorState.createWithContent(newContentState);
                    }
                }


                this.setState({
                    pageContent: this.props.pages.data[i],
                    pageExist: true,
                    error: false,
                    editorState: newEditorState
                });

                this.updateCounterAndReset()
                break;
            }
        }
    }

    isWysiwygEmpty(content) {
        const blockFromHtml = convertFromHTML(content);
        if (blockFromHtml.contentBlocks.length > 0) {
            return false;
        } else {
            return true;
        }
    }


    handleInputChange(event) {
        const newPageData = this.state.pageContent;
        newPageData[event.target.name] = event.target.value;
        this.setState({ pageContent: newPageData });
    }


    handleWysiwygChange = (editorState) => {
        this.setState({ editorState }, () => {
            const newPageData = this.state.pageContent;
            newPageData.content2 = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            this.setState({ pageContent: newPageData });
        });
    }


    handleSubmit() {
        const scope = this;
        if (this.state.pageContent.pageType === 'homepage') {

            if (!!this.state.pageContent.content1 && !!this.state.pageContent.content2 && !!this.state.pageContent.title) {
                submit();
            } else {
                this.setState({ error: true });
            }
        } else if (this.state.pageContent.pageType === 'flashes') {
            if (!!this.state.pageContent.content1 && !!this.state.pageContent.title) {
                submit();
            } else {
                this.setState({ error: true });
            }
        } else if (this.state.pageContent.pageType === 'products') {
            if (!!this.state.pageContent.content1 && !!this.state.pageContent.title) {
                submit();
            } else {
                this.setState({ error: true });
            }
        } else if (this.state.pageContent.pageType === 'about') {
            if (!!this.state.pageContent.content1 && !this.isWysiwygEmpty(this.state.pageContent.content2) && !!this.state.pageContent.title) {
                submit();
            } else {
                this.setState({ error: true });
            }
        } else if (this.state.pageContent.pageType === 'contact') {
            if (!!this.state.pageContent.content1 && !this.isWysiwygEmpty(this.state.pageContent.content2) && !!this.state.pageContent.title) {
                submit();
            } else {
                this.setState({ error: true });
            }
        } else if (this.state.pageContent.pageType === 'terms-and-conditions') {
            if (!!this.state.pageContent.content1 && !this.isWysiwygEmpty(this.state.pageContent.content2) && !!this.state.pageContent.title) {
                submit();
            } else {
                this.setState({ error: true });
            }
        }

        function submit() {
            if (scope.state.pageExist) {
                let newPageData = scope.state.pageContent
                if (!scope.state.imageChange) {
                    delete newPageData.banner
                }

                scope.props.putPages(newPageData).then(() => scope.setState({ error: false, imageChange: false }, function () { scope.handleSeedState() }));
            } else {

                scope.props.postPages(scope.state.pageContent).then(() => scope.handleSeedState());
            }
        }
    }

    handleAddParagraph() {
        if (this.state.additionalContentParagraphs < 4) {
            let newAmount = this.state.additionalContentParagraphs += 1;
            this.setState({ additionalContentParagraphs: newAmount });
        }
    }

    updateCounter() {
        this.setState({ counter: this.state.counter + 1 });
    }

    updateCounterAndReset() {
        this.updateCounter();
        this.setState({ key: Date.now() });

    }

    render() {
        let uploadBtnText = (this.state.pageContent.banner !== undefined && this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? 'Edit' : 'Upload';

        return (
            <div>
                <Loading isLoading={!!this.props.uploadResponse.isFetching || this.props.isFetching} />
                {
                    <div>
                        {
                            this.props.pageType === "homepage" &&
                            <div>
                                <h1>Home</h1>
                                <div className={(this.state.error && !this.state.pageContent.title) ? "field-container error" : "field-container"}>
                                    <h2>Menu name *</h2>
                                    <p className="details">Displayed name for « home » in the menu page link</p>
                                    <input type="text" name="title" onInput={(text) => this.handleInputChange(text)} placeholder="Menu name" defaultValue={this.state.pageContent.title !== null ? this.state.pageContent.title : null} />
                                </div>

                                <div className={this.state.error && !this.state.pageContent.content1 ? "field-container error" : "field-container"}>
                                    <h2>Title *</h2>
                                    <input type="text" name="content1" onInput={(text) => this.handleInputChange(text)} placeholder="Title" defaultValue={this.state.pageContent.content1 !== null ? this.state.pageContent.content1 : null} />
                                </div>

                                <div className={this.state.error && !this.state.pageContent.content2 ? "field-container error" : "field-container"}>
                                    <h2>Subtitle *</h2>
                                    <input type="text" name="content2" onInput={(text) => this.handleInputChange(text)} placeholder="Subtitle" defaultValue={this.state.pageContent.content2 !== null ? this.state.pageContent.content2 : null} />
                                </div>

                                <div className="field-container">
                                    <h2>Banner image</h2>
                                    <p className="details">Recommended size (1920 x 500)</p>
                                    {!!this.state.pageContent.banner &&
                                        <img className={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? this.state.pageContent.banner.imageUrl : null} />
                                    }
                                    <div className="buttons-container">
                                        <label className="upload-button" htmlFor="flash-image-upload">{uploadBtnText}</label><input id="flash-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                        {(!!this.state.pageContent.banner && this.state.pageContent.banner.imageUrl !== null) && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                    </div>
                                </div>

                                {this.state.error &&
                                    <div className="error-container">
                                        <p className="error">All * fields are required</p>
                                    </div>
                                }

                                <button onClick={() => this.handleSubmit()}>Save</button>
                            </div>
                        }
                        {
                            this.props.pageType === "flashes" &&
                            <div>
                                <h1>Flash gallery</h1>

                                <div className={this.state.error && !this.state.pageContent.title ? "field-container error" : "field-container"}>
                                    <h2>Menu name *</h2>
                                    <p className="details">Displayed name for « flash gallery » in the menu page link</p>
                                    <input type="text" name="title" onInput={(text) => this.handleInputChange(text)} placeholder="Menu name" defaultValue={this.state.pageContent.title !== null ? this.state.pageContent.title : null} />
                                </div>

                                <div className={this.state.error && !this.state.pageContent.content1 ? "field-container error" : "field-container"}>
                                    <h2>Title *</h2>
                                    <input type="text" name="content1" onInput={(text) => this.handleInputChange(text)} placeholder="Title" defaultValue={this.state.pageContent.content1 !== null ? this.state.pageContent.content1 : null} />
                                </div>


                                <div className="field-container">
                                    <h2>Banner image</h2>
                                    <p className="details">Recommended size (1920 x 300)</p>
                                    {!!this.state.pageContent.banner &&
                                        <img className={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? this.state.pageContent.banner.imageUrl : null} />
                                    }
                                    <div className="buttons-container">
                                        <label className="upload-button" htmlFor="flash-image-upload">{uploadBtnText}</label><input id="flash-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                        {(!!this.state.pageContent.banner && this.state.pageContent.banner.imageUrl !== null) && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                    </div>
                                </div>

                                {this.state.error &&
                                    <div className="error-container">
                                        <p className="error">All * fields are required</p>
                                    </div>
                                }

                                <button onClick={() => this.handleSubmit()}>Save</button>
                            </div>
                        }

{
                            this.props.pageType === "products" &&
                            <div>
                                <h1>Products</h1>

                                <div className={this.state.error && !this.state.pageContent.title ? "field-container error" : "field-container"}>
                                    <h2>Menu name *</h2>
                                    <p className="details">Displayed name for « products » in the menu page link</p>
                                    <input type="text" name="title" onInput={(text) => this.handleInputChange(text)} placeholder="Menu name" defaultValue={this.state.pageContent.title !== null ? this.state.pageContent.title : null} />
                                </div>

                                <div className={this.state.error && !this.state.pageContent.content1 ? "field-container error" : "field-container"}>
                                    <h2>Title *</h2>
                                    <input type="text" name="content1" onInput={(text) => this.handleInputChange(text)} placeholder="Title" defaultValue={this.state.pageContent.content1 !== null ? this.state.pageContent.content1 : null} />
                                </div>


                                <div className="field-container">
                                    <h2>Banner image</h2>
                                    <p className="details">Recommended size (1920 x 300)</p>
                                    {!!this.state.pageContent.banner &&
                                        <img className={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? this.state.pageContent.banner.imageUrl : null} />
                                    }
                                    <div className="buttons-container">
                                        <label className="upload-button" htmlFor="product-image-upload">{uploadBtnText}</label><input id="product-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                        {(!!this.state.pageContent.banner && this.state.pageContent.banner.imageUrl !== null) && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                    </div>
                                </div>

                                {this.state.error &&
                                    <div className="error-container">
                                        <p className="error">All * fields are required</p>
                                    </div>
                                }

                                <button onClick={() => this.handleSubmit()}>Save</button>
                            </div>
                        }


                        {
                            this.props.pageType === "about" &&
                            <div key={`about-${this.state.key}`}>
                                <h1>About</h1>
                                <div className={this.state.error && !this.state.pageContent.title ? "field-container error" : "field-container"}>
                                    <h2>Menu name *</h2>
                                    <p className="details">Displayed name for « about » in the menu page link</p>
                                    <input type="text" name="title" onInput={(text) => this.handleInputChange(text)} placeholder="Menu name" defaultValue={this.state.pageContent.title !== null ? this.state.pageContent.title : null} />
                                </div>

                                <div className={this.state.error && !this.state.pageContent.content1 ? "field-container error" : "field-container"}>
                                    <h2>Title *</h2>
                                    <input type="text" name="content1" onInput={(text) => this.handleInputChange(text)} placeholder="Title" defaultValue={this.state.pageContent.content1 !== null ? this.state.pageContent.content1 : null} />
                                </div>

                                <div className={(this.state.error && this.isWysiwygEmpty(this.state.pageContent.content2)) ? "field-container error" : "field-container"}>
                                    <h2>Content *</h2>
                                    <div className="wysiwyg-container">
                                        <Editor
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.handleWysiwygChange}
                                        />
                                    </div>
                                </div>

                                <div className="field-container">
                                    <h2>Banner image</h2>
                                    <p className="details">Recommended size (1920 x 300)</p>
                                    {
                                        !!this.state.pageContent.banner && <img className={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? this.state.pageContent.banner.imageUrl : null} />
                                    }
                                    <div className="buttons-container">
                                        <label className="upload-button" htmlFor="flash-image-upload">{uploadBtnText}</label><input id="flash-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                        {(!!this.state.pageContent.banner && this.state.pageContent.banner.imageUrl !== null) && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                    </div>

                                </div>

                                {this.state.error &&
                                    <div className="error-container">
                                        <p className="error">All * fields are required</p>
                                    </div>
                                }

                                <button onClick={() => this.handleSubmit()}>Save</button>
                            </div>
                        }


                        {
                            this.props.pageType === "contact" &&
                            <div key={`contact-${this.state.key}`}>
                                <h1>Contact</h1>

                                <div className={this.state.error && !this.state.pageContent.title ? "field-container error" : "field-container"}>
                                    <h2>Menu name *</h2>
                                    <p className="details">Displayed name for « contact » in the menu page link</p>
                                    <input type="text" name="title" onInput={(text) => this.handleInputChange(text)} placeholder="Menu name" defaultValue={this.state.pageContent.title !== null ? this.state.pageContent.title : null} />
                                </div>

                                <div className={this.state.error && !this.state.pageContent.content1 ? "field-container error" : "field-container"}>
                                    <h2>Title *</h2>
                                    <input type="text" name="content1" onInput={(text) => this.handleInputChange(text)} placeholder="Title" defaultValue={this.state.pageContent.content1 !== null ? this.state.pageContent.content1 : null} />
                                </div>

                                <div className={(this.state.error && this.isWysiwygEmpty(this.state.pageContent.content2)) ? "field-container error" : "field-container"}>
                                    <h2>Content *</h2>
                                    <div className="wysiwyg-container">
                                        <Editor
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.handleWysiwygChange}
                                        />
                                    </div>
                                </div>

                                <div className="field-container">
                                    <h2>Banner image</h2>
                                    <p className="details">Recommended size (1920 x 300)</p>
                                    {
                                        !!this.state.pageContent.banner && <img className={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? this.state.pageContent.banner.imageUrl : null} />
                                    }
                                    <div className="buttons-container">
                                        <label className="upload-button" htmlFor="flash-image-upload">{uploadBtnText}</label><input id="flash-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                        {(!!this.state.pageContent.banner && this.state.pageContent.banner.imageUrl !== null) && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                    </div>

                                </div>

                                {this.state.error &&
                                    <div className="error-container">
                                        <p className="error">All * fields are required</p>
                                    </div>
                                }

                                <button onClick={() => this.handleSubmit()}>Save</button>

                            </div>
                        }
                        {
                            this.props.pageType === "terms-and-conditions" &&
                            <div key={`terms-${this.state.key}`}>
                                <h1>Terms and conditions</h1>

                                <div className={this.state.error && !this.state.pageContent.title ? "field-container error" : "field-container"}>
                                    <h2>Menu name *</h2>
                                    <p className="details">Displayed name for « terms and conditions » in the menu page link</p>
                                    <input type="text" name="title" onInput={(text) => this.handleInputChange(text)} placeholder="Menu name" defaultValue={this.state.pageContent.title !== null ? this.state.pageContent.title : null} />
                                </div>

                                <div className={this.state.error && !this.state.pageContent.content1 ? "field-container error" : "field-container"}>
                                    <h2>Title *</h2>
                                    <input type="text" name="content1" onInput={(text) => this.handleInputChange(text)} placeholder="Title" defaultValue={this.state.pageContent.content1 !== null ? this.state.pageContent.content1 : null} />
                                </div>




                                <div className={(this.state.error && this.isWysiwygEmpty(this.state.pageContent.content2)) ? "field-container error" : "field-container"}>
                                    <h2>Content *</h2>

                                    <p className="details">Please note here all the important things your customers need to agree to before booking.<br />Ex: non-refundable deposits, no tattoos for minors, ID required, cash only, etc…</p>

                                    <div className="wysiwyg-container">
                                        <Editor
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.handleWysiwygChange}
                                        />
                                    </div>
                                </div>







                                <div className="field-container">
                                    <h2>Banner image</h2>
                                    <p className="details">Recommended size (1920 x 300)</p>
                                    {
                                        !!this.state.pageContent.banner && <img className={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? 'visible image-upload-preview' : 'image-upload-preview'} src={(this.state.pageContent.banner !== null && this.state.pageContent.banner.imageUrl !== null) ? this.state.pageContent.banner.imageUrl : null} />
                                    }
                                    <div className="buttons-container">
                                        <label className="upload-button" htmlFor="flash-image-upload">{uploadBtnText}</label><input id="flash-image-upload" className="fileupload" type="file" onChange={this.handleFileChange} accept="image/*" />
                                        {(!!this.state.pageContent.banner && this.state.pageContent.banner.imageUrl !== null) && <button onClick={() => this.handleImageDelete()}>Delete</button>}
                                    </div>

                                </div>

                                {this.state.error &&
                                    <div className="error-container">
                                        <p className="error">All * fields are required</p>
                                    </div>
                                }

                                <button onClick={() => this.handleSubmit()}>Save</button>

                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    uploadResponse: state.upload,
    pages: state.page.pages,
    isFetching: state.page.isFetching,
});

export default connect(mapStateToProps, { postPages, putPages, upload })(SitePagesItem);