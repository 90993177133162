import React, { Component } from 'react';

class BannerSmall extends Component {
	render(props) {

		if (!!this.props.data) {
		return (
			<section className="banner-small" style={ !!this.props.data.background ? {backgroundImage:'url(' + this.props.data.background + ')'} : null}>
				{ this.props.data.title && <h1>{this.props.data.title}</h1> }
			</section>
		);
		} else { return null; }
	}
} 

export default BannerSmall;