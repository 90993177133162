import axios from 'axios';
import { 
  ADD_STRIPE_ACCOUNT_REQUEST, ADD_STRIPE_ACCOUNT_SUCCESS, ADD_STRIPE_ACCOUNT_FAILURE, 
  /*PROCESS_PAYMENT_CONNECT_REQUEST, PROCESS_PAYMENT_CONNECT_SUCCESS, PROCESS_PAYMENT_CONNECT_FAILURE, */
  GET_CARD_REQUEST, GET_CARD_SUCCESS, GET_CARD_FAILURE, 
  CREATE_CARD_REQUEST, CREATE_CARD_SUCCESS, CREATE_CARD_FAILURE, 
  DELETE_CARD_REQUEST, DELETE_CARD_SUCCESS, DELETE_CARD_FAILURE, 
  /*PROCESS_PRODUCT_PAYMENT_CONNECT_REQUEST, PROCESS_PRODUCT_PAYMENT_CONNECT_SUCCESS, PROCESS_PRODUCT_PAYMENT_CONNECT_FAILURE, */
  GET_ADDRESS_REQUEST, GET_ADDRESS_SUCCESS, GET_ADDRESS_FAILURE, 
  GET_PURCHASES_REQUEST, GET_PURCHASES_SUCCESS, GET_PURCHASES_FAILURE,
  POST_INTENT_REQUEST, POST_INTENT_SUCCESS, POST_INTENT_FAILURE,
  POST_INTENT_PRODUCT_REQUEST, POST_INTENT_PRODUCT_SUCCESS, POST_INTENT_PRODUCT_FAILURE
} from './types';

export const postStripeToken = data => async (dispatch) => {
    try {
      dispatch({ type: ADD_STRIPE_ACCOUNT_REQUEST });
      const response = await axios.post('/stripe', data);
      dispatch({ type: ADD_STRIPE_ACCOUNT_SUCCESS, payload: response.data });
    } catch (error) {
      alert('Error', error);
      dispatch({ type: ADD_STRIPE_ACCOUNT_FAILURE, payload: error });
    }
};

/*export const postStripePaymentConnect = (data, siteUrl) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_PAYMENT_CONNECT_REQUEST });
    const response = await axios.post(`/stripe/${siteUrl}/charge`, data);
    dispatch({ type: PROCESS_PAYMENT_CONNECT_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: PROCESS_PAYMENT_CONNECT_FAILURE, payload: error });
  }
};*/

/*export const postStripeProductPaymentConnect = (data, siteUrl) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_PRODUCT_PAYMENT_CONNECT_REQUEST });
    const response = await axios.post(`/stripe/${siteUrl}/product/charge`, data);
    dispatch({ type: PROCESS_PRODUCT_PAYMENT_CONNECT_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: PROCESS_PRODUCT_PAYMENT_CONNECT_FAILURE, payload: error });
  }
};*/

export const getuserCard = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CARD_REQUEST });
    const response = await axios.get('/stripe/card');
    dispatch({ type: GET_CARD_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: GET_CARD_FAILURE, payload: error });
  }
};

export const createUserCard = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CARD_REQUEST });
    const response = await axios.post('/stripe/card', data);
    dispatch({ type: CREATE_CARD_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: CREATE_CARD_FAILURE, payload: error });
  }
};

export const deleteUserCard = (data) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CARD_REQUEST });
    const response = await axios.delete('/stripe/card', {data: data});
    dispatch({ type: DELETE_CARD_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: DELETE_CARD_FAILURE, payload: error });
  }
};

export const getUserAddress = (siteUrl) => async (dispatch) => {
  try {
    dispatch({ type: GET_ADDRESS_REQUEST });
    const response = await axios.get(`/stripe/${siteUrl}`);
    dispatch({ type: GET_ADDRESS_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: GET_ADDRESS_FAILURE, payload: error });
  }
};

export const getPurchases = (siteUrl) => async (dispatch) => {
  try {
    dispatch({ type: GET_PURCHASES_REQUEST });
    const response = await axios.get(`/purchase/${siteUrl}`);
    dispatch({ type: GET_PURCHASES_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: GET_PURCHASES_FAILURE, payload: error });
  }
};

export const createPaymentIntent = (siteUrl, data) => async (dispatch) => {
  try {
    dispatch({ type: POST_INTENT_REQUEST });
    const response = await axios.post(`/stripe/${siteUrl}/intent`, data);
    dispatch({ type: POST_INTENT_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: POST_INTENT_FAILURE, payload: error });
  }
};

export const createPaymentIntentProduct = (siteUrl, data) => async (dispatch) => {
  try {
    dispatch({ type: POST_INTENT_PRODUCT_REQUEST });
    const response = await axios.post(`/stripe/${siteUrl}/intent/product`, data);
    dispatch({ type: POST_INTENT_PRODUCT_SUCCESS, payload: response.data });
  } catch (error) {
    alert('Error', error);
    dispatch({ type: POST_INTENT_PRODUCT_FAILURE, payload: error });
  }
};