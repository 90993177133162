import React, { Component } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

class InjectedForm extends Component {
    async handlePayment(intent) {
        const { stripe, elements } = this.props;
        const result = await stripe.confirmCardPayment(intent.client_secret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: this.props.cardHolder,
              },
            }
          });
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            alert(result.error.message + " " + result.error.code);
            return false;
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
              return true;
            }
          }
    }
    render() {
        return <>
            <CardElement />
        </>
    };
}

export default InjectedForm;