import React, { Component } from 'react';
import moment from 'moment';

class DateDisplay extends Component {

    render(props) {
      return(
          <p className="date-display" onClick={this.props.onClick}>
          {moment(this.props.value).format('LL')}</p>
      )
    }
}


export default DateDisplay; 