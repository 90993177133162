import { UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE } from '../actions/types';

const initialState = {
    fileUrl: null,
	isFetching: false,
	errors: null
}

export default function(state = initialState, action) {
	switch(action.type) {
        case UPLOAD_REQUEST:
            return { ...state, isFetching: true };
        case UPLOAD_SUCCESS:
            return { ...state, isFetching: false, fileUrl: action.payload };
        case UPLOAD_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;
    }
}