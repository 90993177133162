import React, { Component } from 'react';

import { fetchPages, fetchWebsiteInfo } from './actions';
import _ from "lodash";
import { connect } from 'react-redux';

// Navigation components
import SitesHeader from "./components/sites/navigation/header";
import SitesFooter from "./components/sites/navigation/footer";

// Content components mini-site
import Sites from './components/sites';
import SitesAbout from './components/sites/about';
import SitesProducts from './components/sites/products';
import SitesSingleProduct from './components/sites/single-product';
import SitesFlashes from './components/sites/flashes';
import SitesSingleFlash from './components/sites/single-flash';
import SitesContact from './components/sites/contact';
import SitesTermsAndConditions from './components/sites/terms-and-conditions';
import Sites404 from './components/sites/404';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

class Body extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.pagesData = null;
    this.contentData = null;
    this.websiteData = null;

    this.navigationData = {
      siteUrl: this.props.match.params.site,
      pages: null,
      logo: null,
      currentPage: this.props.pageType
    }

    this.setContentData = this.setContentData.bind(this);
    this.setNavigationPagesData = this.setNavigationPagesData.bind(this);
    this.generateContent = this.generateContent.bind(this);
  }

  componentDidMount(props) {
    /* FETCH PAGES DATA */
    if (_.isEmpty(this.props.pages)) {
      this.setState({ isLoadingPages: true });
      this.props.fetchPages(this.props.match.params.site);
    } else {
      this.setState({ isLoadingPages: false });
    }

    /* FETCH WEBSITE INFO */
    if (_.isEmpty(this.props.websiteInfo)) {
      this.setState({ isLoadingWebsiteInfo: true });
      this.props.fetchWebsiteInfo(this.props.match.params.site);
    } else {
      this.setState({ isLoadingWebsiteInfo: false });
    }
  }


  render(props) {

    /* DEFINE DATA */
    if (!_.isEmpty(this.props.pages)) this.pagesData = this.props.pages.data;
    if (!_.isEmpty(this.props.websiteInfo)) {
      this.websiteData = this.props.websiteInfo.data;
      if (!!this.websiteData.logo) this.navigationData.logoUrl = this.websiteData.logo.imageUrl;
      if (!!this.props.websiteInfo.data.facebookURL) this.navigationData.facebookURL = this.props.websiteInfo.data.facebookURL;
      if (!!this.props.websiteInfo.data.instagramURL) this.navigationData.instagramURL = this.props.websiteInfo.data.instagramURL;
    }

    this.contentData = this.setContentData();
    this.navigationData.pages = this.setNavigationPagesData();
    this.navigationData.currentPage = this.props.pageType;

    /* GENERATE COMPONENT */
    this.content = this.generateContent();

    /* RETURN */
    return (
      <div className="app" >
        <SitesHeader data={this.navigationData} />
        {this.content}
        <SitesFooter data={this.navigationData} />
      </div>
    );
  }


  /* SET CONTENT DATA HELPER*/
  setContentData() {
    if (!!this.pagesData && this.contentData === null) {
      let contentDataObj = {};
      let hasContentData = false;

      for (var i = 0; i < this.pagesData.length; i++) {
        if (this.pagesData[i].hasOwnProperty('pageType')) {
          contentDataObj[this.pagesData[i].pageType] = this.pagesData[i];
          if (!hasContentData) hasContentData = true;
        }
      }

      if (!!hasContentData) {
        return contentDataObj;
      } else { return null; }

    } else { return this.contentData; }

  }


  /* SET NAVIGATION PAGES DATA HELPER*/
  setNavigationPagesData() {

    if (!!this.pagesData && this.navigationData.pages === null) {
      let navigationPagesDataArray = [];
      for (var i = 0; i < this.pagesData.length; i++) {
        if (this.pagesData[i].hasOwnProperty('status') && !!this.pagesData[i].status) {

          const pageObj = {
            type: this.pagesData[i].pageType,
            name: this.pagesData[i].title
          }
          navigationPagesDataArray.push(pageObj);
        }
      }

      if (!_.isEmpty(navigationPagesDataArray)) {
        return navigationPagesDataArray;
      } else { return null; }

    } else { return this.navigationData.pages; }
  }


  /* GENERATE CONTENT COMPONENT HELPER */
  generateContent() {

    const contentObj = ((pageType, backTo) => {
      const backToUrl = !!this.props.location.backTo ? this.props.location.backTo : null;
      let PageContentData = null;

      if (!!this.contentData && this.contentData.hasOwnProperty(pageType)) {
        PageContentData = this.contentData[pageType];
      }


      if (this.pagesData !== null && this.websiteData !== null) {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: this.websiteData.websiteStripeId });
        if ((pageType === "single-flash" || pageType === "single-product" || (!!PageContentData && !!PageContentData.status)) && this.websiteData.status === 1) {
          switch (pageType) {

            /* Mini-site */
            case 'homepage':
              return <Sites {...this.props} data={PageContentData} siteUrl={this.props.match.params.site} />;
            case 'about':
              return <SitesAbout {...this.props} data={PageContentData} siteUrl={this.props.match.params.site} />;
            case 'products':
              return <SitesProducts {...this.props} data={PageContentData} siteUrl={this.props.match.params.site} />;
            case 'single-product':
              return <Elements stripe={stripePromise}><SitesSingleProduct {...this.props} productId={this.props.match.params.productid} siteUrl={this.props.match.params.site} /></Elements>;
            case 'contact':
              return <SitesContact {...this.props} data={PageContentData} siteUrl={this.props.match.params.site} />;
            case 'flashes':
              return <SitesFlashes {...this.props} data={PageContentData} siteUrl={this.props.match.params.site} />;
            case 'single-flash':
              return <Elements stripe={stripePromise}><SitesSingleFlash {...this.props} flashId={this.props.match.params.flashid} siteUrl={this.props.match.params.site} /></Elements>;
            case 'terms-and-conditions':
              return <SitesTermsAndConditions {...this.props} data={PageContentData} siteUrl={this.props.match.params.site} backTo={backToUrl} />;
            case '404':
              return <Sites404 {...this.props} data={PageContentData} siteUrl={this.props.match.params.site} />;
            default:
              console.error(`The page type "${pageType}" doesn't currently have a content component.`);
              return null;
          }

        } else {
          return <Sites404 {...this.props} data={PageContentData} siteUrl={this.props.match.params.site} />;
        }
      } else {
        // is loading the page
        return null;
      }
    }
    )(this.props.pageType, this.props.location.backTo);
    return contentObj;
  }
}


const mapStateToProp = state => {
  return {
    pages: state.page.pages,
    websiteInfo: state.websiteInfo.websiteInfo,
    isFetchingPages: state.page.isFetching,
    isFetchingWebsiteInfo: state.websiteInfo.isFetching
  }
}

export default connect(mapStateToProp, { fetchPages, fetchWebsiteInfo })(Body);
