import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUserFlashes } from '../../actions';
import _ from 'lodash';
import Navigation from './partials/navigation';
import MyFlashes from './partials/myFlashes';
import AddFlashes from './partials/addFlashes';
import Categories from './categories';
import Loading from "./partials/loading";

class Flashes extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.fetchCurrentUserFlashes();
    }

    fetchUserFlashes() {
        this.props.fetchCurrentUserFlashes();
    }

    render(props) {
        return (

            <div className="dashboard-wrapper">
                <Navigation active="flashes" subMenu={true} pathname={this.props.location.pathname} subMenuItems={[{ name: "My designs", link: "/admin/flashes/myflashes" }, { name: "Add a flash", link: "/admin/flashes/addflashes" }, { name: "Manage categories", link: "/admin/flashes/categories" }]} subMenuTitle={"Tattoos"}/>
                
                {
                    <div className="dashboard-content-wrapper">
                        <Loading isLoading={this.props.isFetching} />

                        {!_.isEmpty(this.props.flashes) &&
                            <>
                                {
                                    this.props.location.pathname === '/admin/flashes/myflashes' && <MyFlashes fetchUserFlashes={() => this.fetchUserFlashes()} flashes={this.props.flashes.data} location={this.props.location} currentUser={this.props.currentUser}/>
                                }
                                {
                                    this.props.location.pathname === "/admin/flashes/addflashes" && <AddFlashes history={this.props.history} currentUser={this.props.currentUser}/>
                                }
                                {
                                    this.props.location.pathname === "/admin/flashes/categories" && <Categories location={this.props.location} />
                                }
                            </>
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    flashes: state.flash.flashes,
    isFetching: state.flash.isFetching
});

export default connect(mapStateToProps, { fetchCurrentUserFlashes })(Flashes);