import React, { Component } from 'react';
import _ from "lodash";
import Loading from "./components/dashboard/partials/loading";

import Modal from 'react-modal';
import FirstLogin from './components/dashboard/partials/firstLogin';
import { connect } from 'react-redux';
import { fetchUser, fetchCurrentUserWebsiteInfo } from './actions';

// Conten components dashboard
import Admin from './components/dashboard';
import Settings from './components/dashboard/settings';
import SitePages from './components/dashboard/sitePages';
import Flashes from './components/dashboard/flashes';
import Products from './components/dashboard/products';
import SingleCategory from './components/dashboard/singleCategory';
import SingleFlash from './components/dashboard/flashesSingle';
import SingleProduct from './components/dashboard/productsSingle';
import Availability from './components/dashboard/availability';
import Support from './components/dashboard/support';

class DashboardBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWebsiteCreated: false,
      isGoogleConnected: false,
      isModalSkip: false
    };
    this.generateContent = this.generateContent.bind(this);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.currentUser)) {
      this.props.fetchUser().then(() => {

        if (_.isEmpty(this.props.websiteInfo)) {
          this.props.fetchCurrentUserWebsiteInfo()
        }
      })
    }
  }

  handleShowModal() {
    this.setState({ isModalSkip: false });
  }

  render(props) {
    /* GENERATE COMPONENT */
    this.content = this.generateContent();
    /* RETURN */
    return (
      <>
        <Loading isLoading={(_.isEmpty(this.props.currentUser) && _.isEmpty(this.props.websiteInfo))} />
        {
          (!_.isEmpty(this.props.currentUser) && !_.isEmpty(this.props.websiteInfo)) &&
          (
            (this.props.websiteInfo.data.length === 0 && !this.state.isWebsiteCreated)
            || (this.props.currentUser.data.googleRefreshToken === null && !this.state.isGoogleConnected && !this.state.isModalSkip)
            || (this.props.currentUser.data.stripeId === null && !this.state.isModalSkip)
          ) &&
          <Modal
            isOpen={true}
          ><FirstLogin initialSetupState={this.props} location={this.props.location} isWebsiteCreated={(data) => this.setState({ isWebsiteCreated: data })} isGoogleConnected={(data) => this.setState({ isGoogleConnected: data })} auth={this.props.auth} handleModalSkip={() => this.setState({ isModalSkip: true })} /></Modal>
        }

        {this.content}

      </>);

  }


  /* GENERATE CONTENT COMPONENT HELPER */
  generateContent() {

    const contentObj = ((pageType) => {

      switch (pageType) {
        case 'dashboard-admin':
          return <Admin {...this.props} auth={this.props.auth} showModal={() => this.handleShowModal()} />
        case 'dashboard-settings':
          return <Settings {...this.props} auth={this.props.auth} />
        case 'dashboard-availability':
          return <Availability {...this.props} />
        case 'dashboard-sitePages':
          return <SitePages {...this.props} />
        case 'dashboard-singleFlash':
          return <SingleFlash {...this.props} />
        case 'dashboard-singleProduct':
          return <SingleProduct {...this.props} />
        case 'dashboard-singleCategory':
          return <SingleCategory {...this.props} />
        case 'dashboard-flashes':
          return <Flashes {...this.props} />
        case 'dashboard-products':
          return <Products {...this.props} />
        case 'dashboard-support':
          return <Support {...this.props} />
        default:
          console.error(`The page type "${pageType}" doesn't currently have a content component.`);
          return null;
      }
    }
    )(this.props.pageType, this.props.location.backTo);
    return contentObj;
  }
}

const mapStateToProps = state => ({
  isFetchingUser: state.user.isFetching, 
  currentUser: state.user.user,
  websiteInfo: state.websiteInfo.websiteInfo,
  availability: state.availability.availability,
  isFetchingAvailability: state.availability.isFetching,
  flashes: state.flash.flashes,
  isFlashesFetching: state.flash.isFetching,
  websitePages: state.page.pages,
  isWebsitePagesFetching: state.page.pages
});

export default connect(mapStateToProps, { fetchUser, fetchCurrentUserWebsiteInfo })(DashboardBody);
