import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';


class Navigation extends Component {
	constructor() {
		super();
		this.state = {
			hamburgerOpened: false
		}
	}

	hamburgerHandler() {
		this.setState({ hamburgerOpened: !this.state.hamburgerOpened });
	}

	closeHamburger() {
		this.setState({ hamburgerOpened: false });
	}

	render(props) {
		return (
			<>
				<button className="hamburger-btn" onClick={() => this.hamburgerHandler()}>
					<figure></figure>
					<figure></figure>
					<figure></figure>
				</button>
				{/* <div className={this.state.hamburgerOpened ? "menu-offset active" : "menu-offset"}></div> */}

				<div onClick={() => this.closeHamburger()} className={this.state.hamburgerOpened ? "navigation-overlay active" : "navigation-overlay"}></div>

				<div className={this.state.hamburgerOpened ? "navigation active" : "navigation"}>
					<div className="nav-main-wrapper">
						<nav className="nav-main">
							<div className="nav-main-top">
								<ul>
									<li className={this.props.active === 'index' ? 'active' : ''}>
										<Link to="/admin"><img draggable={false} className="icon-dash" src={require('../../../assets/images/dashboard/icons/dashboard_2x.png')} /></Link>
									</li>
									<li className={this.props.active === 'flashes' ? 'active' : ''}>
										<Link to="/admin/flashes/myflashes" ><img draggable={false} className="icon-flashes" src={require('../../../assets/images/dashboard/icons/grip_2x.png')} /></Link>
									</li>
									<li className={this.props.active === 'products' ? 'active' : ''}>
										<Link to="/admin/products/myproducts" ><img draggable={false} className="icon-products" src={require('../../../assets/images/dashboard/icons/products_2x.png')} /></Link>
									</li>
									<li className={this.props.active === 'availability' ? 'active' : ''}>
										<Link to="/admin/availabilities" ><img draggable={false} className="icon-avail" src={require('../../../assets/images/dashboard/icons/availability_2x.png')} /></Link>
									</li>
									<li className={this.props.active === 'pages' ? 'active' : ''}>
										<Link to="/admin/site-pages/home" ><img draggable={false} className="icon-page" src={require('../../../assets/images/dashboard/icons/page_2x.png')} /></Link>
									</li>
									<li className={this.props.active === 'support' ? 'active' : ''}>
										<Link to="/admin/support" ><img draggable={false} className="icon-support" src={require('../../../assets/images/dashboard/icons/question_2x.png')} /></Link>
									</li>
									<li className={this.props.active === 'settings' ? 'active' : ''}>
										<Link to="/admin/settings/general" ><img draggable={false} className="icon-settings" src={require('../../../assets/images/dashboard/icons/settings_2x.png')} /></Link>
									</li>
								</ul>
							</div>
						</nav>
					</div>
					{
						this.props.subMenu &&
						<div className="nav-sec-wrapper">
							<nav className="nav-sec">
								{
									this.props.subMenuTitle !== undefined ? <h1>{this.props.subMenuTitle}</h1> :
										<h1>SubMenu</h1>
								}
								{
									<ul>
										{(!_.isEmpty(this.props.websiteInfo) && this.props.websiteInfo.data.length > 0) &&
											<li><Link to={`/${this.props.websiteInfo.data[0].url}`} target="_blank">Visit website</Link></li>
										}

										{this.props.subMenuItems !== undefined &&
											this.props.subMenuItems.map((items, key) => (
												<li key={key} className={this.props.pathname === items.link ? "active" : ""} >
													<Link onClick={() => this.closeHamburger()} to={items.link} key={key}>{items.name}</Link>
												</li>
											))
										}
									</ul>
								}
							</nav>
						</div>
					}
				</div>
			</>
		);
	}
}

export default Navigation;
