import React, { Component } from 'react';

import BannerSmall from "./sections/section.banner-small";
import ContentTermsAndConditions from "./sections/section.content-terms-and-conditions";
import { animateScroll as scroll } from 'react-scroll'


class SitesTermsAndConditions extends Component {
	constructor( props ) {
		super( props );
		this.state = {}

		this.bannerData = {
			title: "Terms and conditions",
			background: null
		};

		this.contentData = {
			terms: null,
			backTo: this.props.backTo
		};
	}

	componentDidMount(){
		scroll.scrollToTop();
	}


	render() {
		// Define components data
		if (this.props.data !== null) {

			// bannerData
			if (!!this.props.data.content1) this.bannerData.title = this.props.data.content1;
			if (!!this.props.data.banner) this.bannerData.background = this.props.data.banner.imageUrl;

			// contentData
			if (!!this.props.data.content2) this.contentData.terms = this.props.data.content2;
		}

		return (
			<main className="page-about">
				<BannerSmall data={this.bannerData} />
				<ContentTermsAndConditions data={this.contentData} />
			</main>
		);
	}
}

export default SitesTermsAndConditions;