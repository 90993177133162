import React, { Component } from 'react';
import { fetchProductById, /*postStripeProductPaymentConnect, */getUserAddress, createPaymentIntentProduct } from '../../actions';
import _ from "lodash";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from "./partials/partial.loading";
import { animateScroll as scroll } from 'react-scroll';
import Dropdown from 'react-dropdown';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import InjectedForm from "./partials/injected-form";

const countryJson = require('countrycitystatejson');

class SitesSingleProduct extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeView: 0,
			isLoading: false,
			error: false,
			paymentSuccess: false,
			paymentError: false,
			firstName: '',
			lastName: '',
			email: '',
			confirmEmail: '',
			phoneNumber: '',
			quantity: 1,
			errorQuantity: false,
			errorFirstName: false,
			errorLastName: false,
			errorEmail: false,
			errorPhoneNumber: false,
			errorStripeField: false,
			errorPaymentForm: '',
			errorStripePaymentForm: '',
			errorCodePaymentForm: '',
			shippingCountry: null,
			shippingName: '',
			shippingAddress: '',
			shippingCity: '',
			shippingProvince: null,
			shippingPostal: '',
			shippingPhone: '',
			errorShippingForm: false,
			errorShippingCountry: false,
			errorShippingName: false,
			errorShippingAddress: false,
			errorShippingCity: false,
			errorShippingProvince: false,
			errorShippingPostal: false,
			errorShippingPhone: false,
			provinceOptions: [],
			countryOptions: [],
			parcelShipping: true
		};

		this.product = {
			data: null,
			isLoading: false
		}

		this.calendarExceptions = [];
		this.bannerData = null;

		this.submitPayment = this.submitPayment.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.injectedform = React.createRef();
	}


	componentDidMount() {
		scroll.scrollToTop();
		this.product.isLoading = true;
		const productId = this.props.productId;
		this.props.fetchProductById(productId).then(
			() => {
				this.product.isLoading = false;

				let parcelShipping = true;
				if (!this.props.product.data.data.shippingOption) {
					parcelShipping = false;
				}

				this.setState({
					countryOptions: this.getCountries(),
					parcelShipping: parcelShipping
				})

			}
		);
	}

	addDays(daysAmount) {
		var newDate = new Date();
		newDate.setDate(newDate.getDate() + daysAmount);
		return newDate;
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value }, () => {

			if (this.state.error !== "") {

				//Payment form
				if (this.state.errorFirstName && this.state.firstName !== "") {
					this.setState({ errorFirstName: false, errorPaymentForm: "" });

				} else if (this.state.errorLastName && this.state.lastName !== "") {
					this.setState({ errorLastName: false, errorPaymentForm: "" });

				} else if (this.state.errorEmail && this.state.email !== "") {
					this.setState({ errorEmail: false, errorPaymentForm: "" });

				} else if (this.state.errorPhoneNumber && this.state.phoneNumber !== "") {
					this.setState({ errorPhoneNumber: false, errorPaymentForm: "" })

				} else if (this.state.errorQuantity && parseFloat(this.state.quantity) <= this.props.product.data.data.quantity && parseFloat(this.state.quantity) !== 0 && this.state.quantity !== "" && parseFloat(this.state.quantity) % 1 === 0) {
					this.setState({ errorQuantity: false, errorPaymentForm: "" })

					// Shipping form
				} else if (this.state.errorShippingCountry && this.state.shippingCountry !== null) {
					this.setState({ errorShippingCountry: false, errorShippingForm: "" })

				} else if (this.state.errorShippingName && this.state.shippingName !== "") {
					this.setState({ errorShippingName: false, errorShippingForm: "" })

				} else if (this.state.errorShippingAddress && this.state.shippingAddress !== "") {
					this.setState({ errorShippingAddress: false, errorShippingForm: "" })

				} else if (this.state.errorShippingCity && this.state.shippingCity !== "") {
					this.setState({ errorShippingCity: false, errorShippingForm: "" })

				} else if (this.state.errorShippingProvince && ((this.state.provinceOptions.length > 0 && this.state.shippingProvince !== null) || (this.state.provinceOptions.length === 0))) {
					this.setState({ errorShippingProvince: false, errorShippingForm: "" })

				} else if (this.state.errorShippingPostal && this.state.shippingPostal !== "") {
					this.setState({ errorShippingPostal: false, errorShippingForm: "" })

				} else if (this.state.errorShippingPhone && this.state.shippingPhone !== "") {
					this.setState({ errorShippingPhone: false, errorShippingForm: "" })
				}
			}
		});
	}


	handleVerifyShippingForm() {

		function validatePhoneNumber(number) {
			var re = /^([+]?)((\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
			return re.test(String(number))
		}

		if (this.state.shippingCountry === null || this.state.shippingName === "" || this.state.shippingAddress === "" || this.state.shippingCity === "" || this.state.shippingPostal === "" || !validatePhoneNumber(this.state.shippingPhone) || (this.state.provinceOptions.length > 0 && this.state.shippingProvince === null)) {

			let errorMessage = "";
			let errorShippingCountry = false;
			let errorShippingProvince = false;
			let errorShippingName = false;
			let errorShippingAddress = false;
			let errorShippingCity = false;
			let errorShippingPostal = false;
			let errorShippingPhone = false;

			if (this.state.shippingCountry === null) errorShippingCountry = true;
			if (this.state.provinceOptions.length > 0 && this.state.shippingProvince === null) errorShippingProvince = true;
			if (this.state.shippingName === "") errorShippingName = true;
			if (this.state.shippingAddress === "") errorShippingAddress = true;
			if (this.state.shippingCity === "") errorShippingCity = true;
			if (this.state.shippingPostal === "") errorShippingPostal = true;
			if (!validatePhoneNumber(this.state.shippingPhone)) errorShippingPhone = true;

			const errorsArray = [errorShippingCountry, errorShippingProvince, errorShippingName, errorShippingAddress, errorShippingCity, errorShippingPostal, errorShippingPhone];
			var errorsCount = 0;
			for (var i = 0; i < errorsArray.length; ++i) {
				if (errorsArray[i] === true) errorsCount++;
			}

			if (errorsCount >= 2) {
				errorMessage = 'Please fill all the required fields.'
			} else if (errorShippingCountry) {
				errorMessage = 'Please select a country.'
			} else if (errorShippingProvince) {
				errorMessage = 'Please select a province / state.'
			} else if (errorShippingName) {
				errorMessage = 'Please enter the name.'
			} else if (errorShippingAddress) {
				errorMessage = 'Please enter the address.'
			} else if (errorShippingCity) {
				errorMessage = "Please enter the city."
			} else if (errorShippingPostal) {
				errorMessage = "Please enter the postal code."
			} else if (errorShippingPhone) {
				errorMessage = "Please enter a valid phone number."
			}

			this.setState({
				errorShippingCountry: errorShippingCountry,
				errorShippingProvince: errorShippingProvince,
				errorShippingName: errorShippingName,
				errorShippingAddress: errorShippingAddress,
				errorShippingCity: errorShippingCity,
				errorShippingPostal: errorShippingPostal,
				errorShippingPhone: errorShippingPhone,
				errorShippingForm: errorMessage
			});

		} else {
			if (this.props.address === null || this.props.address.status === 0) {
				this.setState({
					errorShippingCountry: false,
					errorShippingProvince: false,
					errorShippingName: false,
					errorShippingAddress: false,
					errorShippingCity: false,
					errorShippingPostal: false,
					errorShippingPhone: false,
					errorShippingForm: '',
					isLoading: true

				}, () => {
					this.props.getUserAddress(this.props.siteUrl).then(() => {

						if (this.props.address.status === 0) {
							// Error
							this.setState({
								isLoading: false
							}, () => {
								alert('An error occured, please try again');
								console.error('getUserAddress error. ' + this.props.address.message);
							})

						} else {
							this.setState({
								activeView: 2,
								isLoading: false
							})
						}
					})
				});

			} else {
				this.setState({
					errorShippingCountry: false,
					errorShippingProvince: false,
					errorShippingName: false,
					errorShippingAddress: false,
					errorShippingCity: false,
					errorShippingPostal: false,
					errorShippingPhone: false,
					errorShippingForm: '',
					activeView: 2
				})
			}
		}
	}

	generateDate(date) {
		const dateArray = date.split("-");
		const formattedDate = `${dateArray[2]}-${dateArray[1]}-${parseInt(dateArray[0]) + 1}`;
		return new Date(formattedDate);
	}

	getCountries() {
		let countries = [];
		const list = countryJson.getAll();
		for (const countryItem in list) {
			countries.push({ value: countryItem, label: list[countryItem].name })
		}
		return countries;
	}

	handleCountrySelection(item) {
		let provinceOptions = [];
		const country = countryJson.getCountryByShort(item.value);

		for (const provinceItem in country.states) {
			provinceOptions.push({ value: provinceItem, label: provinceItem })
		}

		this.setState({
			shippingCountry: item.value,
			provinceOptions: provinceOptions,
			shippingProvince: this.state.shippingCountry !== item.value ? null : this.state.shippingProvince
		});
	}

	handleProvinceSelection(item) {
		this.setState({ shippingProvince: item.value });
	}

	handleShippingMethodSelection(e) {
		this.setState({ parcelShipping: !this.state.parcelShipping }, () => console.log(this.state.parcelShipping));
	}

	getTotal() {
		const shippingCost = this.state.parcelShipping ? (this.state.shippingCountry === this.props.address.data.country ? this.product.data.localShippingCost : this.product.data.internationalShippingCost) : 0;
		return ((parseFloat(this.state.quantity) * parseFloat(this.product.data.price)) + 2.5 + parseFloat(shippingCost)).toFixed(2);
	}

	stripeAmountToDollars(amount) {
		return (amount / 100).toFixed(2);
	}

	render(props) {

		//Define pickup address
		let pickupAddress = this.props.websiteInfo.data.pickupInOtherLocation ? "pickupAddress" : "address";

		console.log(this.props);
		// Define productsListingData products
		if (!_.isEmpty(this.props.product)) {
			this.product.data = this.props.product.data.data;
			this.product.status = this.props.product.data.status;
		}

		this.product.isLoading = this.props.isFetchingProduct ? true : false

		if (this.product.data !== null && !this.product.isLoading) {

			if (this.product.status === 1) {
				return (
					<>
						<Loading isLoading={this.state.isLoading} />
						<main className="page-single-product">
							<section className="product-details">
								<div className="wrapper">
									<div className="image-container">
										{!!this.product.data.image ?
											<img src={this.product.data.image.imageUrl} alt="product" /> :
											<img src={require("../../assets/images/sites/placeholders/product-placeholder.png")} alt="Placeholder"></img>
										}
									</div>

									{this.state.activeView === 0 &&
										<div className="content-container content-details">
											{!!this.product.data.title && <h1>{this.product.data.title}</h1>}

											<h2>Description</h2>
											{!!this.product.data.description &&
												<div className="description-container">
													<div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: this.product.data.description }}></div>
												</div>
											}

											{(!!this.product.data.price) &&
												<div className="details">
													<div className="detail-item">
														<div className="icon-container">
															<img className="deposit-icon icon" src={require("../../assets/images/sites/icons/details-deposit2x.png")} alt="Deposit"></img>
														</div>
														<p className="detail-title">(Price)</p>
														<p className="detail-value">{this.product.data.price} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
													</div>
												</div>
											}

											<p className="small-chars">Please read the artist’s terms and condition before payment.</p> 

											<p className="terms-container">
												<Link
													className="terms"
													to={{
														pathname: `/${this.props.siteUrl}/terms-and-conditions`,
														backTo: `/${this.props.siteUrl}/flash/${this.props.flashId}`
													}}
												>Terms and conditions</Link>
											</p>

											<div className="buttons-container">
												<Link className="cancel button" to={`/${this.props.siteUrl}/products`}>Cancel</Link>
												<button className="button dark" onClick={() => {
													this.setState({ activeView: 1 });
												}}>Buy</button>
											</div>
										</div>
									}


									{this.state.activeView === 1 &&
										<div className="content-container content-shipping">
											{!!this.product.data.title && <h1>{this.product.data.title}</h1>}

											{
												(this.product.data.shippingOption && this.product.data.pickupOption) &&
												<>
													<p className="payment-subtitle"><b>Shipping method</b></p>
													<div className="radio-container">

														<div className="radio-item">
															<input
																type="radio"
																id="radio-shipping"
																checked={this.state.parcelShipping}
																onChange={() => this.handleShippingMethodSelection()}
															/>
															<label htmlFor="radio-shipping" className="radio-label">Shipping</label>
														</div>

														<div className="radio-item">
															<input
																type="radio"
																id="radio-pickup"
																checked={!this.state.parcelShipping} onChange={() => this.handleShippingMethodSelection()}
															/>
															<label htmlFor="radio-pickup" className="radio-label">Pickup</label>
														</div>
													</div>
												</>
											}

											{
												!this.state.parcelShipping &&
												<>
													<p className="payment-subtitle"><b>Pickup location</b></p>

													<p>{this.props.websiteInfo.data[pickupAddress].addressLine1}{this.props.websiteInfo.data[pickupAddress].addressLine2 !== "" && <>, {this.props.websiteInfo.data[pickupAddress].addressLine2}</>}, {this.props.websiteInfo.data[pickupAddress].city}</p>

													<p>{countryJson.getCountryByShort(this.props.websiteInfo.data[pickupAddress].country).name}{this.props.websiteInfo.data[pickupAddress].province !== null && <>, {this.props.websiteInfo.data[pickupAddress].province}</>}</p>

													<p>{this.props.websiteInfo.data[pickupAddress].postal}</p>

												</>
											}


											{
												this.state.parcelShipping ?
													<p className="payment-subtitle"><b>Shipping address</b></p>
													:
													<>
														<p className="payment-subtitle"><b>Pickup identification</b></p>
														<p className="details">At the discretion of the artist, a proof of residency could be required at the moment of the pickup.</p>
													</>

											}


											<input
												className={this.state.errorShippingName ? "invalid" : ""}
												type="text"
												name="shippingName"
												autoComplete="off"
												placeholder={"Name *"}
												onChange={this.handleChange}
												value={this.state.shippingName}
												required
											/>

											<input
												className={this.state.errorShippingPhone ? "invalid" : ""}
												type="tel"
												name="shippingPhone"
												placeholder={"Phone number *"}
												onChange={this.handleChange}
												autoComplete="off"
												value={this.state.shippingPhone}
												required
											/>

											<input
												className={this.state.errorShippingAddress ? "invalid" : ""}
												type="text"
												name="shippingAddress"
												placeholder={"Address *"}
												onChange={this.handleChange}
												autoComplete="off"
												value={this.state.shippingAddress}
												required
											/>

											<input
												className={this.state.errorShippingCity ? "invalid" : ""}
												type="text"
												name="shippingCity"
												placeholder={"City *"}
												onChange={this.handleChange}
												autoComplete="off"
												value={this.state.shippingCity}
												required
											/>

											<input
												className={this.state.errorShippingPostal ? "invalid" : ""}
												type="text"
												name="shippingPostal"
												placeholder={"Zip code *"}
												onChange={this.handleChange}
												autoComplete="off"
												value={this.state.shippingPostal}
												required
											/>

											<Dropdown
												className={this.state.errorShippingCountry ? "invalid" : ""}
												options={this.state.countryOptions}
												onChange={(item) => this.handleCountrySelection(item)}
												value={this.state.shippingCountry}
												placeholder="Select a country *"
											/>

											{this.state.provinceOptions.length !== 0 &&
												<Dropdown
													className={this.state.errorShippingProvince ? "invalid" : ""}
													options={this.state.provinceOptions}
													onChange={(item) => this.handleProvinceSelection(item)}
													value={this.state.shippingProvince}
													placeholder="Select a province *"

												/>
											}



											{!!this.state.errorShippingForm &&
												<div className="error-container">
													<div className="error-wrapper">
														<p className="error">{this.state.errorShippingForm}</p>
													</div>
												</div>
											}

											<div className="buttons-container">
												<button className="button" onClick={() => {
													this.setState({
														activeView: 0,
														shippingCountry: '',
														shippingName: '',
														shippingAddress: '',
														shippingCity: '',
														shippingProvince: '',
														shippingPostal: '',
														shippingPhone: '',
														errorShippingForm: false,
														errorShippingCountry: false,
														errorShippingName: false,
														errorShippingAddress: false,
														errorShippingCity: false,
														errorShippingProvince: false,
														errorShippingPostal: false,
														errorShippingPhone: false,
													}, function () { scroll.scrollToTop(); });

												}}>&#60;&nbsp;&nbsp;Back</button>
												<button onClick={() => this.handleVerifyShippingForm()} className="button dark">Proceed to payment</button>
											</div>
										</div>
									}


									{this.state.activeView === 2 &&
										<div className="content-container content-payment">
											{!!this.product.data.title && <h1>{this.product.data.title}</h1>}

											<div className="details-container">
												<p className="payment-subtitle"><b>Quantity</b></p>
												<input
													className={this.state.errorQuantity ? "quantity-field invalid" : "	quantity-field"}
													type="number"
													min="1"
													max={this.product.data.quantity}
													name="quantity"
													onChange={this.handleChange}
													value={this.state.quantity}
													required
												/>
											</div>

											<div className="details-container">
												<p className="payment-subtitle"><b>Payment details</b></p>
												{
													this.state.quantity !== "" && parseFloat(this.state.quantity) !== 0 ?
														<>
															<p>Cost: {(parseFloat(this.state.quantity) * parseFloat(this.product.data.price)).toFixed(2)} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
															{
																this.state.parcelShipping &&
																<>
																	{
																		this.props.address.data.country === this.state.shippingCountry ?
																			<p>Local shipping: {this.product.data.localShippingCost.toFixed(2)} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
																			:
																			<p>International Shipping: {this.product.data.internationalShippingCost.toFixed(2)} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
																	}
																</>
															}



															<p>Service fees: 2.50 {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
															<p>Total: {this.getTotal()} {this.props.websiteInfo.data.ownerCurrency.toUpperCase()}</p>
														</>
														:
														<p>Enter a quantity to display the payment details.</p>

												}

											</div>

											<p className="payment-subtitle"><b>Payment</b></p>
											<input
												className={this.state.errorFirstName ? "invalid" : ""}
												type="text"
												name="firstName"
												autoComplete="off"
												placeholder={"First name"}
												onChange={this.handleChange}
												required
											/>

											<input
												className={this.state.errorLastName ? "invalid" : ""}
												type="text"
												name="lastName"
												autoComplete="off"
												placeholder={"Last name"}
												onChange={this.handleChange}
												required
											/>

											<input
												className={this.state.errorEmail ? "invalid" : ""}
												type="email"
												name="email"
												placeholder={"Email"}
												onChange={this.handleChange}
												autoComplete="off"
												required
											/>
											<input
												className={this.state.errorEmail ? "invalid" : ""}
												type="email"
												name="confirmEmail"
												placeholder={"Confirm email"}
												onChange={this.handleChange}
												autoComplete="off"
												required
											/>
											<input
												className={this.state.errorPhoneNumber ? "invalid" : ""}
												type="tel"
												name="phoneNumber"
												placeholder={"Phone number"}
												onChange={this.handleChange}
												autoComplete="off"
												required
											/>

											<div className={this.state.errorStripeField ? 'invalid stripe-input-container' : 'stripe-input-container'}>
												{/* <CardElement /> */}
												<ElementsConsumer>
													{({ stripe, elements }) => (
														<InjectedForm ref={this.injectedform} stripe={stripe} elements={elements} cardHolder={`${this.state.firstName} ${this.state.lastName}`} />
													)}
												</ElementsConsumer>
											</div>

											{
												!!this.state.errorPaymentForm &&
												<div className="error-container">
													<div className="error-wrapper">
														<p className="error">
															{this.state.errorPaymentForm}
															{
																!!this.state.errorStripePaymentForm &&
																<> <br /> {this.state.errorStripePaymentForm} </>
															}
															{
																!!this.state.errorCodePaymentForm &&
																<> <br /> Error : {this.state.errorCodePaymentForm} </>
															}
														</p>
													</div>
												</div>
											}


											{
												this.state.paymentError &&
												<div className="error-container">
													<div className="error-wrapper">
														<p className="error">There was an issue with your payment<br />Please try again.</p>
													</div>
												</div>
											}

											<div className="terms-container">
												<p>By sending the payment, I agree to both the artist’s and bookme.ink’s terms and conditions.</p>
											</div>

											<div className="buttons-container">
												<button className="button" onClick={() => {
													this.setState({
														activeView: 1,
														firstName: '',
														lastName: '',
														email: '',
														quantity: 1,
														errorQuantity: false,
														errorFirstName: false,
														errorLastName: false,
														errorEmail: false,
														errorPaymentForm: '',
														errorCodePaymentForm: '',
														errorStripeField: false
													}, function () { scroll.scrollToTop(); });

												}}>&#60;&nbsp;&nbsp;Back</button>
												<button onClick={() => this.handleSubmit()} className="button dark">Send payment</button>
											</div>
										</div>
									}

									{this.state.activeView === 3 &&
										<div className="content-container content-confirmation">
											{/*!!this.product.data.title && <h1>{this.product.data.title}</h1>*/}

											<h2>Confirmation</h2>

											<div className="details-container">

												<h3>Thank you for your order!</h3>
												<p>The payment for your order was processed successfully.<br />An email confirmation will be sent to {this.props.paymentIntentProduct.data.receipt_email}</p>

												<p className="payment-subtitle"><b>Summary</b></p>
												<p>{this.state.quantity} x {this.props.paymentIntentProduct.data.description}</p>
												<p>{this.stripeAmountToDollars(this.props.paymentIntentProduct.data.amount)} {this.props.paymentIntentProduct.data.currency.toUpperCase()}</p>


												{
													!this.state.parcelShipping &&
													<>
														<p className="payment-subtitle"><b>Pickup location</b></p>

														<p>{this.props.websiteInfo.data[pickupAddress].addressLine1}{this.props.websiteInfo.data[pickupAddress].addressLine2 !== "" && <>, {this.props.websiteInfo.data[pickupAddress].addressLine2}</>}, {this.props.websiteInfo.data[pickupAddress].city}</p>

														<p>{countryJson.getCountryByShort(this.props.websiteInfo.data[pickupAddress].country).name}{this.props.websiteInfo.data[pickupAddress].province !== null && <>, {this.props.websiteInfo.data[pickupAddress].province}</>}</p>

														<p>{this.props.websiteInfo.data[pickupAddress].postal}</p>


														<p className="payment-subtitle"><b>Pickup identification</b></p>
														<p className="details">At the discretion of the artist, a proof of residency could be required at the moment of the pickup.</p>

													</>
												}



											</div>

											<div className="buttons-container">
												<Link className="cancel button" to={`/${this.props.siteUrl}/products`}>Buy another product</Link>
											</div>
										</div>
									}
								</div>
							</section>
						</main>
					</>
				);
			} else {
				return (
					<>
						<main className="page-single-product">
							<section className="product-details">
								<div className="wrapper">
									<p className="no-avail">This product is no longer available</p>
								</div>
							</section>
						</main>

					</>
				)
			}


		} else { return <main className="page-single-product"></main> }
	}

	handleSubmit() {

		function validateEmail(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		}

		function validatePhoneNumber(number) {
			var re = /^([+]?)((\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
			return re.test(String(number))
		}

		const isValidQuantity = (quantity) => {
			return (quantity !== "" && parseFloat(quantity) !== 0 && parseFloat(quantity) % 1 === 0 && parseFloat(quantity) <= this.props.product.data.data.quantity);
		}

		if (this.state.firstName === "" || this.state.lastName === "" || this.state.email === "" || this.state.phoneNumber === "" || !isValidQuantity(this.state.quantity)) {

			let errorMessage = "";
			let errorFirstName = false;
			let errorLastName = false;
			let errorEmail = false;
			let errorPhoneNumber = false;
			let errorQuantity = false;

			if (this.state.firstName === "") errorFirstName = true;
			if (this.state.lastName === "") errorLastName = true;
			if (this.state.email === "") errorEmail = true;
			if (this.state.phoneNumber === "") errorPhoneNumber = true;
			if (!isValidQuantity(this.state.quantity)) errorQuantity = true;

			const errorsArray = [errorFirstName, errorLastName, errorEmail, errorPhoneNumber, errorQuantity];
			var errorsCount = 0;
			for (var i = 0; i < errorsArray.length; ++i) {
				if (errorsArray[i] === true)
					errorsCount++;
			}

			if (errorsCount >= 2) {
				errorMessage = 'All fields are required.'

				if (errorQuantity) {
					errorMessage += "\n Please enter a quantity between 1 and " + this.props.product.data.data.quantity + "."
				}

			} else if (errorFirstName) {
				errorMessage = 'Please enter your first name.'
			} else if (errorLastName) {
				errorMessage = 'Please enter your last name.'
			} else if (errorEmail) {
				errorMessage = 'Please enter your email.'
			} else if (errorPhoneNumber) {
				errorMessage = "Please enter your phone number."
			} else if (errorQuantity) {
				errorMessage = "Please enter a quantity between 1 and " + this.props.product.data.data.quantity + "."
			}

			this.setState({
				errorFirstName: errorFirstName,
				errorLastName: errorLastName,
				errorEmail: errorEmail,
				errorPhoneNumber: errorPhoneNumber,
				errorPaymentForm: errorMessage,
				errorQuantity: errorQuantity
			});

			this.validateStripeField(errorMessage)

		} else if (!validateEmail(this.state.email)) {
			const errorMessage = "The email address is invalid."
			this.setState(
				{
					errorEmail: true,
					errorPaymentForm: errorMessage
				}, () => this.validateStripeField()
			);

		} else if (!validatePhoneNumber(this.state.phoneNumber)) {
			const errorMessage = "The phone number is invalid."
			this.setState(
				{
					errorPhoneNumber: true,
					errorPaymentForm: errorMessage
				}, () => this.validateStripeField()
			);
		} else if (this.state.email !== this.state.confirmEmail) {
			const errorMessage = "Your email and email confirmation do not match."
			this.setState(
				{
					errorEmail: true,
					errorPaymentForm: errorMessage
				}, () => this.validateStripeField()
			)
		} else {
			this.setState({ errorPaymentForm: "", errorStripePaymentForm: '' }, () => {
				this.submitPayment();
			})
		}
	}

	async validateStripeField() {
		// Only executes when a previous field already causes an error
		//this.setState({ isLoading: true });

		/*this.props.stripe.createToken({ name: `${this.state.firstName} ${this.state.lastName}` }).then(({ token, error }) => {
			if (!!error) {
				// handle error with stripe validation

				if (error.hasOwnProperty('type') && error.type === "validation_error") {

					let errorMessage = `${this.createStripeErrorMessage(error)}`;

					this.setState({
						errorStripeField: true,
						errorStripePaymentForm: errorMessage,
						errorCodePaymentForm: '',
						isLoading: false
					})
				} else {
					this.setState({
						errorStripeField: true,
						errorStripePaymentForm: "Your payment information could not be processed.",
						errorCodePaymentForm: error.code,
						isLoading: false
					})
				}

			} else {
				this.setState({
					errorStripeField: false,
					errorStripePaymentForm: '',
					errorCodePaymentForm: '',
					isLoading: false
				})
			}
		});*/
	}

	async submitPayment(ev) {

		// User clicked submit
		this.setState({ isLoading: true });

		let data = {
			description: this.product.data.title,
			receiptEmail: this.state.email,
			productId: this.product.data.id,
			quantity: parseInt(this.state.quantity),
			isLocalShipping: this.state.shippingCountry === this.props.address.data.country,
			shippingAddress: this.state.shippingAddress,
			shippingCity: this.state.shippingCity,
			shippingCountry: this.state.shippingCountry,
			shippingName: this.state.shippingName, 
			shippingPhone: this.state.shippingPhone,
			shippingPostal: this.state.shippingPostal,
			shippingProvince: this.state.shippingProvince,
			isParcelPickup: !this.state.parcelShipping

		}

		this.props.createPaymentIntentProduct(this.props.siteUrl, data).then(async () => {
			if (this.props.paymentIntentProduct.status === 1) {
				console.log(this.props.paymentIntentProduct);
				var paymentSuccess = await this.injectedform.current.handlePayment(this.props.paymentIntentProduct.data);
				if (paymentSuccess) {
					scroll.scrollToTop();
					this.setState({
						activeView: 3,
						isLoading: false
					});
				} else {
					this.setState({
						isLoading: false
					});
					alert("Error payment");
				}
			} else {
				alert(this.props.paymentIntentProduct.message);
			}
		});

		/*this.props.stripe.createToken({ name: `${this.state.firstName} ${this.state.lastName}` }).then(({ token, error }) => {
			if (!!error) {
				// handle error with stripe validation
				this.setState({ isLoading: false });
				if (error.hasOwnProperty('type') && error.type === "validation_error") {
					let errorMessage = this.createStripeErrorMessage(error);
					this.setState({
						errorStripeField: true,
						errorPaymentForm: errorMessage,
						errorCodePaymentForm: ''

					})
				} else {
					this.setState({
						errorStripeField: true,
						errorPaymentForm: "Your payment could not be processed.",
						errorCodePaymentForm: error.code
					})
				}
			} else {
				let data = {
					description: this.product.data.title,
					sourceId: token.id,
					receiptEmail: this.state.email,
					productId: this.product.data.id,
					quantity: parseInt(this.state.quantity),
					isLocalShipping: this.state.shippingCountry === this.props.address.data.country,
					shippingAddress: this.state.shippingAddress,
					shippingCity: this.state.shippingCity,
					shippingCountry: this.state.shippingCountry,
					shippingName: this.state.shippingName,
					shippingPhone: this.state.shippingPhone,
					shippingPostal: this.state.shippingPostal,
					shippingProvince: this.state.shippingProvince
				}

				let siteUrl = this.props.siteUrl;

				this.props.postStripeProductPaymentConnect(data, siteUrl).then(() => {
					if (this.props.stripeResponse.hasOwnProperty('paid') && this.props.stripeResponse.paid) {
						// Payment success
						let image = !!this.product.data.image ? this.product.data.image.imageUrl : "../../assets/images/sites/placeholders/product-placeholder.png";
						scroll.scrollToTop();

						this.setState({
							activeView: 3,
							isLoading: false
						});

					} else if (this.props.stripeResponse.statusCode === 2) {
						this.setState({
							isLoading: false
						})
					} else {
						// Stripe payment failure						
						// Payment denied
						this.setState({
							paymentError: true,
							isLoading: false
						})
					}
				})
			}
		});*/
	}

	createStripeErrorMessage(error) {
		switch (error.code) {
			case 'incomplete_number' ||
				'invalid_number':
				return "The credit card number is invalid.";
				break;
			case "incomplete_expiry" ||
				"invalid_expiry_year" ||
				"invalid_expiry_year_past" ||
				"invalid_expiry_month_past":
				return "The credit card expiration date is invalid or expired.";
				break;
			case "incomplete_cvc":
				return "The credit card security number is invalid."
				break;
			case "incomplete_zip":
				return "The zip code linked to the credit card is invalid."
				break;
			default:
				return "There seems to be an error in your credit card information."
				break;
		}
	}
}


const mapStateToProp = state => {
	return {
		product: state.product.product,
		isFetchingProduct: state.product.isFetching,
		isFecthingStripe: state.stripe.isFetching,
		websiteInfo: state.websiteInfo.websiteInfo,
		address: state.stripe.address,
		paymentIntentProduct: state.stripe.paymentIntentProduct
	}
}

export default connect(mapStateToProp, { fetchProductById, getUserAddress, createPaymentIntentProduct })(SitesSingleProduct);
