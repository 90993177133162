import axios from 'axios';
import { FETCHING_AVAILABILITY_REQUEST, FETCHING_AVAILABILITY_SUCCESS, FETCHING_AVAILABILITY_FAILURE, CREATE_AVAILABILITY_REQUEST, CREATE_AVAILABILITY_SUCCESS, CREATE_AVAILABILITY_FAILURE, EDIT_AVAILABILITY_REQUEST, EDIT_AVAILABILITY_SUCCESS, EDIT_AVAILABILITY_FAILURE } from './types';

// Availabilities
export const fetchAvailability = (siteUrl) => {
    return async dispatch => {
        try {
            dispatch({ type: FETCHING_AVAILABILITY_REQUEST });
            const response = await axios({
                url: `/availability/${siteUrl}`,
                method: 'get',
                timeout: 10000,

            });
            dispatch({ type: FETCHING_AVAILABILITY_SUCCESS, payload: response });
        }
        catch (error) {
            dispatch({ type: FETCHING_AVAILABILITY_FAILURE, payload: error });
        }
    };
}

export const fetchCurrentUserAvailability = () => {
    return async dispatch => {
        try {
            dispatch({ type: FETCHING_AVAILABILITY_REQUEST });
            const response = await axios({
                url: `/availability`,
            });
            dispatch({ type: FETCHING_AVAILABILITY_SUCCESS, payload: response });
        }
        catch (error) {
            dispatch({ type: FETCHING_AVAILABILITY_FAILURE, payload: error });
        }
    };
}

export const createAvailability = data => async (dispatch) => {
    try {
      dispatch({ type: CREATE_AVAILABILITY_REQUEST });
      const response = await axios.post(`/availability`, data);
      dispatch({ type: CREATE_AVAILABILITY_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: CREATE_AVAILABILITY_FAILURE, payload: error });
    }
};

export const editAvailability = data => async (dispatch) => {
    try {
      dispatch({ type: EDIT_AVAILABILITY_REQUEST });
      const response = await axios.put(`/availability`, data);
      dispatch({ type: EDIT_AVAILABILITY_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: EDIT_AVAILABILITY_FAILURE, payload: error });
    }
};

