import { FETCHING_USER_REQUEST, FETCHING_USER_SUCCESS, FETCHING_USER_FAILURE, EDIT_CURRENCY_REQUEST, EDIT_CURRENCY_SUCCESS, EDIT_CURRENCY_FAILURE } from '../actions/types';

const initialState = {
    user: null,
    isFetching: false,
    errors: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCHING_USER_REQUEST:
            return { ...state, isFetching: true };
        case FETCHING_USER_SUCCESS:
            return { ...state, isFetching: false, user: action.payload };
        case FETCHING_USER_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case EDIT_CURRENCY_REQUEST:
            return { ...state, isFetching: true };
        case EDIT_CURRENCY_SUCCESS:
            return { ...state, isFetching: false, user: action.payload };
        case EDIT_CURRENCY_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;
    }
}