import React, { Component } from 'react';
import CreditCard from './partials/creditCard';
import Navigation from './partials/navigation';
import GeneralSettings from './partials/generalSettings';
import AdvancedSettings from './partials/advancedSettings';
import SocialMediasSettings from './partials/socialMediasSettings';


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render(props) {
        return (

            <div className="dashboard-wrapper">
                <Navigation subMenu={true} pathname={this.props.location.pathname} subMenuTitle={"Settings"} active="settings" subMenuItems={[{ name: "General", link: "/admin/settings/general" }, { name: "Social medias", link: "/admin/settings/socialmedias" },  { name: "Advanced", link: "/admin/settings/advanced" }]} />

                {
                    <div className="dashboard-content-wrapper">
                        <>
                            {
                                this.props.location.pathname === '/admin/settings/general' && <GeneralSettings auth={this.props.auth} location={this.props.location} />
                            }

                            {this.props.location.pathname === "/admin/settings/socialmedias" && <SocialMediasSettings auth={this.props.auth} location={this.props.location} />}

                            {
                                this.props.location.pathname === "/admin/settings/advanced" && <AdvancedSettings auth={this.props.auth} location={this.props.location} />
                            }
                        </>
                    </div>
                }
            </div>
        );
    }
}

export default Settings;