import React, { Component } from 'react';
import Loading from "../components/dashboard/partials/loading";

class Callback extends Component {
  render() {
    return <Loading isLoading={true} />;
  }
}

export default Callback;
 