import React, { Component } from 'react';
import WebsiteCreate from '../partials/partials/websiteCreate';
import GoogleCalendarCreate from '../partials/partials/googleCalendarCreate';
import StripeConnect from '../partials/partials/stripeConnect';
import Auth from '../../../auth/authService';

const auth = new Auth();

class FirstLogin extends Component {
    state = {
       isWebsiteCreated: false,
       isGoogleConnected: false
    }

    componentDidMount() {
        if(this.props.initialSetupState.websiteInfo.data.length !== 0){
            this.setState({isWebsiteCreated: true}, function(){
                this.props.isWebsiteCreated(this.state.isWebsiteCreated);
            })
        }
        if(this.props.initialSetupState.currentUser.data.googleRefreshToken !== null){
            this.setState({isGoogleConnected: true}, function(){
                this.props.isGoogleConnected(this.state.isGoogleConnected);
            })
        }
    }

    handleModalSkip(){
        this.props.handleModalSkip();
    }

	render() {
		return (
			<div className={"dashboard-wrapper firstLoginWrapper"}>
                    {
                        !this.state.isWebsiteCreated &&
                        <WebsiteCreate auth={auth} isWebsiteCreated={(data) => {
                            this.setState({isWebsiteCreated: data}, function(){
                                this.props.isWebsiteCreated(this.state.isWebsiteCreated)
                            })
                        }} />
                    }
                    {
                        (this.state.isWebsiteCreated &&
                        !this.state.isGoogleConnected) &&
                        <GoogleCalendarCreate auth={auth} handleSkip={ () => this.handleModalSkip() } location={this.props.location } isGoogleConnected={(data) => {
                            this.setState({isGoogleConnected: data}, function(){
                                this.props.isGoogleConnected(this.state.isGoogleConnected)
                            })
                        }} />
                    }
                    {
                        (this.state.isWebsiteCreated &&
                        this.state.isGoogleConnected &&
                        this.props.initialSetupState.currentUser.data.stripeId === null) &&
                        <StripeConnect auth={auth} location={this.props.location} handleSkip={ () => this.handleModalSkip() }/>
                    }
			</div>
		);
	}
}

export default FirstLogin;
