import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from "lodash";
import { connect } from 'react-redux';
import { fetchProducts } from '../../../actions';


class SitesHeader extends Component {

	constructor() {
		super();
		this.state = {
			hamburger: {
				antispam: false,
				isVisible: false
			}
		}
		this.menuItems = null;
		this.hamburgerMenuItems = null;

		this.hamLinkHandler = this.hamLinkHandler.bind(this);
		this.hamButtonHandler = this.hamButtonHandler.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}


	componentDidMount() {
		const siteUrl = this.props.data.siteUrl;

		if (_.isEmpty(this.props.products)) {
			this.props.fetchProducts(siteUrl, false).then(() => console.log(this.props.products));
		}
	}

	render(props) {
		/* GENERATE MENU ITEMS */
		this.menuItems = this.generateMenuItems(this.props.data.pages, false);
		this.hamburgerMenuItems = this.generateMenuItems(this.props.data.pages, true);


		/* RETURN */
		return (
			<header >
				<div className="wrapper">
					{this.hamburgerMenuItems !== null &&
						<div className={this.state.hamburger.isVisible ? 'active hamburger-scroll-prevent' : "hamburger-scroll-prevent"}>
							<div className="hamburger-container">
								<button className="hamburger-btn" onClick={() => { this.hamButtonHandler() }} >
									<figure ></figure>
									<figure ></figure>
									<figure ></figure>
								</button>
								<div className="hamburger-nav-container"></div>
							</div>

							<nav className="hamburger-nav">
								<ul>
									{this.hamburgerMenuItems}

									{!!this.props.data.instagramURL &&
										<li className="social-item">
											<a href={this.props.data.instagramURL} target="_blank" rel="noopener noreferrer">
												<img
													src={require('../../../assets/images/sites/icons/instagram-header-white@2x.png')}
													alt="instagram"
												/>
											</a>
										</li>
									}

									{!!this.props.data.facebookURL &&
										<li className="social-item">
											<a href={this.props.data.facebookURL} target="_blank" rel="noopener noreferrer">
												<img
													src={require('../../../assets/images/sites/icons/facebook-header-white@2x.png')}
													alt="facebook"
												/>
											</a>
										</li>
									}
								</ul>
							</nav>
						</div>
					}


					{this.menuItems !== null && <>
						< Link to={`/${this.props.data.siteUrl}`} className="logo-container">
							{!!this.props.data.logoUrl && <img className="logo" src={this.props.data.logoUrl} alt="Logo" />}
						</Link>


						<nav className="main-navigation">
							<ul>
								{this.menuItems}

								{!!this.props.data.instagramURL &&
									<li className="social-item">
										<a href={this.props.data.instagramURL} target="_blank" rel="noopener noreferrer">
											<img
												src={require('../../../assets/images/sites/icons/instagram-header-black@2x.png')}
												alt="instagram"
											/>
										</a>
									</li>
								}

								{!!this.props.data.facebookURL &&
									<li className="social-item">
										<a href={this.props.data.facebookURL} target="_blank" rel="noopener noreferrer">
											<img
												src={require('../../../assets/images/sites/icons/facebook-header-black@2x.png')}
												alt="facebook"
											/>
										</a>
									</li>
								}
							</ul>
						</nav>
					</>
					}

					{this.menuItems === null &&
						<a href="https://bookme.ink" target="_blank" rel="noopener noreferrer" className="logo-container">
							<img className="logo" src={require('../../../assets/images/sites/logos/bookmeink-logo@2x.png')} alt="Logo" />
						</a>
					}
				</div>
			</header >
		);
	}

	hasProducts() {

		const verifyQuantity = () => {
			for( let i = 0; i < this.props.products.data.data.length; i += 1 ){
				const product = this.props.products.data.data[i];
				if(product.quantity > 0){
					return true;
				}
			}
			return false;
		}

		if (
			this.props.products !== null && 
			this.props.products.hasOwnProperty('data') && 
			this.props.products.data.status === 1 && 
			this.props.products.data.data.length !== 0 &&
			verifyQuantity()
			) {

				return true;

		} else {
			return false;
		}

	}


	/* GENERATE MENU ITEMS HELPER */
	generateMenuItems(pages, isHamburger) {

		if (!!pages) {
			// Remove pages that must not be in the main navigation
			let filteredPages = [];
			let pagesToRemove = [];

			if (!isHamburger) pagesToRemove = [
				'terms-and-conditions'
			];


			for (var i = pages.length - 1; i >= 0; i--) {
				if (pagesToRemove.indexOf(pages[i].type) > -1) {
					continue;
				} else {
					filteredPages.push(pages[i]);
				}
			}

			// Re-order the pages
			if (filteredPages.length >= 2) {
				const pagesOrder = [
					"homepage",
					"flashes",
					"products",
					"about",
					"contact",
					"terms-and-conditions"
				];

				filteredPages.sort((a, b) => {
					const aPriority = pagesOrder.indexOf(a.type);
					const bPriority = pagesOrder.indexOf(b.type);

					if (aPriority && bPriority === -1) {
						console.error(`Types ${a.type} and ${b.type} can't be ordered. Please review the pagesOrder array in components/navigation/header.js`);
					}

					const output = (aPriority > bPriority) ? 1 : -1;
					return output;

				});
			}

			// Create the items
			let menuItemsArray = filteredPages.map((pItem, key) => {

				switch (pItem.type) {
					case "homepage":
						return (
							<li key={key}>
								<Link
									className={this.props.data.currentPage === "homepage" ? 'active' : null}
									to={`/${this.props.data.siteUrl}`}
									onClick={isHamburger ? () => { this.hamLinkHandler() } : null}
								>
									{pItem.name}
								</Link>
							</li>
						);

					case "about":
						return (
							<li key={key}>
								<Link
									className={this.props.data.currentPage === "about" ? 'active' : null}
									to={`/${this.props.data.siteUrl}/about`}
									onClick={isHamburger ? () => { this.hamLinkHandler() } : null}
								>
									{pItem.name}
								</Link>
							</li>
						);


					case "flashes":
						return (
							<li key={key}>
								<Link
									className={this.props.data.currentPage === "flashes" ? 'active' : null}
									to={`/${this.props.data.siteUrl}/flashes`}
									onClick={isHamburger ? () => { this.hamLinkHandler() } : null}
								>
									{pItem.name}
								</Link>
							</li>
						);

					case "products":

						if (this.hasProducts()) {
							return (
								<li key={key}>
									<Link
										className={this.props.data.currentPage === "products" ? 'active' : null}
										to={`/${this.props.data.siteUrl}/products`}
										onClick={isHamburger ? () => { this.hamLinkHandler() } : null}
									>
										{pItem.name}
									</Link>
								</li>
							);
						} else {
							return null;
						}



					case "contact":
						return (
							<li key={key}>
								<Link
									className={this.props.data.currentPage === "contact" ? 'active' : null}
									to={`/${this.props.data.siteUrl}/contact`}
									onClick={isHamburger ? () => { this.hamLinkHandler() } : null}
								>
									{pItem.name}
								</Link>
							</li>
						);

					case "terms-and-conditions":
						return (
							<li key={key}>
								<Link
									to={`/${this.props.data.siteUrl}/terms-and-conditions`}
									onClick={() => { this.hamLinkHandler() }}
								>
									{pItem.name}
								</Link>
							</li>
						);

					default:
						console.error(`The pagetype ${pItem.type} isn't supported in the navigation menu`);
						return null;

				}
			});

			return menuItemsArray;
		} else { return null; }
	}


	/* HAMBURGER MENU METHODS */
	hamLinkHandler() {
		if (!!this.state.hamburger.antispam) {
			return false;
		} else {
			let hamburgerObj = Object.assign({}, this.state.hamburger);
			hamburgerObj.antispam = true;
			this.setState({ hamburger: hamburgerObj });
			this.closeMenu();
		}
	}

	hamButtonHandler() {
		if (!!this.state.hamburger.antispam) {
			return false;
		} else {
			let hamburgerObj = Object.assign({}, this.state.hamburger);
			hamburgerObj.antispam = true;
			this.setState({ hamburger: hamburgerObj }, () => this.toggleMenu());
		}
	}

	toggleMenu() {

		let hamburgerObj = Object.assign({}, this.state.hamburger);
		hamburgerObj.isVisible = !hamburgerObj.isVisible;
		this.setState({ hamburger: hamburgerObj });

		const scope = this;
		setTimeout(function () {
			hamburgerObj.antispam = false;
			scope.setState({ hamburger: hamburgerObj });
		}, 600);


	}

	closeMenu() {
		let hamburgerObj = Object.assign({}, this.state.hamburger);
		hamburgerObj.isVisible = false;
		this.setState({ hamburger: hamburgerObj });

		const scope = this;
		setTimeout(function () {
			hamburgerObj.antispam = false;
			scope.setState({ hamburger: hamburgerObj });
		}, 600);
	}

}

const mapStateToProp = state => {
	return {
		products: state.product.products,
		isFetching: state.flash.isFetching
	}
}

export default connect(mapStateToProp, { fetchProducts })(SitesHeader);
