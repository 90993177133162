import axios from 'axios';
import { CALENDAR_EXCHANGE_CODE_REQUEST, CALENDAR_EXCHANGE_CODE_SUCCESS, CALENDAR_EXCHANGE_CODE_FAILURE, FETCHING_CALENDAR_REQUEST, FETCHING_CALENDAR_SUCCESS, FETCHING_CALENDAR_FAILURE, POSTING_CALENDAR_EVENT_REQUEST, POSTING_CALENDAR_EVENT_SUCCESS, POSTING_CALENDAR_EVENT_FAILURE, UNLINK_CALENDAR_REQUEST, UNLINK_CALENDAR_SUCCESS, UNLINK_CALENDAR_FAILURE } from './types';

export const postGoogleCalendarToken = data => async (dispatch) => {
    try {
      dispatch({ type: CALENDAR_EXCHANGE_CODE_REQUEST });
      const response = await axios.post('/google', data);
      dispatch({ type: CALENDAR_EXCHANGE_CODE_SUCCESS, payload: response.data });
    } catch (error) {
      alert('Error', error);
      dispatch({ type: CALENDAR_EXCHANGE_CODE_FAILURE, payload: error });
    }
  };


  export const fetchGoogleCalendar = (siteUrl) => {
    return async dispatch => {
          try {
              dispatch({ type: FETCHING_CALENDAR_REQUEST });
              const response = await axios({
                  url: `/google/${siteUrl}`,
                  method: 'get',
                  timeout: 15000
              });
              dispatch({ type: FETCHING_CALENDAR_SUCCESS, payload: response });
          } 
          catch(error) {
              dispatch({ type: FETCHING_CALENDAR_FAILURE, payload: error });
          }
      };
  }


  export const postGoogleCalendarEvent = (siteUrl, data) => {
    return async dispatch => {
          try {
              dispatch({ type: POSTING_CALENDAR_EVENT_REQUEST });
              const response = await axios.post(`/google/${siteUrl}`, data);

              dispatch({ type: POSTING_CALENDAR_EVENT_SUCCESS, payload: response });
          } 
          catch(error) {
              dispatch({ type: POSTING_CALENDAR_EVENT_FAILURE, payload: error });
          }
      };
  }

  export const unlinkGoogleCalendar = () => {
    return async dispatch => {
          try {
              dispatch({ type: UNLINK_CALENDAR_REQUEST });
              const response = await axios.delete(`/google`);

              dispatch({ type: UNLINK_CALENDAR_SUCCESS, payload: response });
          } 
          catch(error) {
              dispatch({ type: UNLINK_CALENDAR_FAILURE, payload: error });
          }
      };
  }