import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createAvailability, fetchCurrentUserAvailability, editAvailability } from '../../actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Navigation from './partials/navigation';

import TimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/entry.nostyle';

import TimeKeeper from 'react-timekeeper';

import Loading from "./partials/loading";


class Availability extends Component {
	timekeeperConfig = {
		TIMEPICKER_BACKGROUND: '#4C2B62',
		DONE_BUTTON_COLOR: '#ffffff',
		TIME_SELECTED_COLOR: '#4C2B62',
		TIME_DEFAULT_COLOR: '#000000',
		DROPDOWN_COLOR: '#4C2B62',
		DROPDOWN_SELECTED_COLOR: '#ECE9E9',
		CLOCK_HAND_INTERMEDIATE_CIRCLE_BACKGROUND: '#4C2B62',
		CLOCK_HAND_CIRCLE_BACKGROUND: '#af87ca',
		CLOCK_HAND_ARM: '#ddcbe8',
		CLOCK_WRAPPER_MERIDIEM_TEXT_COLOR_SELECTED: '#ffffff',
		CLOCK_WRAPPER_MERIDIEM_BACKGROUND_COLOR_SELECTED: '#4C2B62',
		CLOCK_WRAPPER_MERIDIEM_COLOR: '#000000',
		CLOCK_NUMBER_COLOR: '#ffffff',
		CLOCK_BACKGROUND: '#4C2B62',
		useCoarseMinutes: true,
		hour24Mode:false

	}

	state = {
		showTimeKeeper: null,
		bookingRangeMax: 90,
		bookingRangeMin: 7,
		date: {
			monday: null,
			tuesday: null,
			wednesday: null,
			thursday: null,
			friday: null,
			saturday: null,
			sunday: null
		},
		availabilityExist: false,
		dateErrors: {
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false
		},
		maxBookingPerDay: {
			monday: 0,
			tuesday: 0,
			wednesday: 0,
			thursday: 0,
			friday: 0,
			saturday: 0,
			sunday: 0
		},
		bookingRangeMaxError: false,
		bookingRangeMinError: false,
		dateError: false
	}

	componentDidMount() {
		this.props.fetchCurrentUserAvailability().then(() => {
			this.props.availability.data.statusCode !== 0 && this.handleAvailabilitySeed();
		});
	}

	handleAvailabilitySeed() {
		this.setState({ availabilityExist: true });

		let newState = {
			bookingRangeMax: this.props.availability.data.bookingRangeMax,
			bookingRangeMin: this.props.availability.data.bookingRangeMin,
			maxBookingPerDay: this.state.maxBookingPerDay,
			date: {}
		}

		for (var property in this.props.availability.data) {
			if (this.props.availability.data.hasOwnProperty(property) && property !== "bookingRangeMax" && property !== "bookingRangeMin") {
				//Do things here
				if (this.props.availability.data[property] !== null) {

					const maxBookingCharIndex = this.props.availability.data[property].indexOf("|");
					if (maxBookingCharIndex !== -1) {
						// Has max booking per day
						newState.maxBookingPerDay[property] = parseInt(this.props.availability.data[property].split('|')[1]);

						let availabilityString = this.props.availability.data[property].substring(0, maxBookingCharIndex);
						newState.date[property] = availabilityString.split(",");

					} else {
						newState.date[property] = this.props.availability.data[property].split(",");
					}

				} else {
					newState.date[property] = null;
				}
			}
		}

		this.setState({ ...newState });
	}

	onChange = (data, from) => {
		let newDate = this.state.date;
		newDate[from] = data;
		this.setState({ date: newDate });
	}

	onTimeChange(data, weekday, index) {
		let newDate = this.state.date;
		newDate[weekday][index] = data.formatted24;
		this.setState({ showTimeKeeper: null, date: newDate });
	}

	handleCloseDay(weekday) {
		let newDate = this.state.date;
		newDate[weekday] = null;

		let newMaxBookingObj = this.state.maxBookingPerDay;
		newMaxBookingObj[weekday] = 0;
		this.setState({
			date: newDate,
			maxBookingPerDay: newMaxBookingObj
		});
	}

	handleOpenDay(weekday) {
		let newDate = this.state.date;
		newDate[weekday] = ["9:00", "16:00"];
		this.setState({ date: newDate });
	}

	handleShowTimeKeeper(el) {
		this.setState({ showTimeKeeper: el });
	}

	convertTo12h(time) {
		let hours = parseInt(time.split(":")[0]);
		if (hours < 12) {
			return time + " AM";
		} else if (hours == 12) {
			return (hours) + ':' + time.split(":")[1] + " PM";
		} else {
			return (hours - 12) + ':' + time.split(":")[1] + " PM";
		}
	}

	handleSubmit() {
		let errorDays = [];
		let errorBookingRangeMax = false;
		let errorBookingRangeMin = false;

		if (parseInt(this.state.bookingRangeMax) === 0 || this.state.bookingRangeMax === null || this.state.bookingRangeMax === "" || parseInt(this.state.bookingRangeMax) <= parseInt(this.state.bookingRangeMin)) {
			errorBookingRangeMax = true;
		}

		if (parseInt(this.state.bookingRangeMin) === 0 || this.state.bookingRangeMin === null || this.state.bookingRangeMin === "" || parseInt(this.state.bookingRangeMax) <= parseInt(this.state.bookingRangeMin)) {
			errorBookingRangeMin = true;
		}

		if ((parseInt(this.state.bookingRangeMax) - parseInt(this.state.bookingRangeMin)) > 90) {
			errorBookingRangeMax = true;
			errorBookingRangeMin = true;
		}

		// Form validation
		for (var property in this.state.date) {
			if (this.state.date.hasOwnProperty(property)) {
				if (this.state.date[property] === null) {
					// Do nothing
				} else if (
					this.state.date[property][0] !== undefined &&
					this.state.date[property][1] !== undefined &&
					this.state.date[property][0] !== null &&
					this.state.date[property][1] !== null) {
					const StartTimeToInt = parseInt(this.state.date[property][0].replace(':', ''));
					const EndTimeToInt = parseInt(this.state.date[property][1].replace(':', ''));
					const startMinutes = parseInt(this.state.date[property][0].split(":")[1])
					const endMinutes = parseInt(this.state.date[property][1].split(":")[1])

					if (StartTimeToInt < EndTimeToInt && startMinutes % 30 === 0 && endMinutes % 30 === 0) {
						// Do nothing
					} else {
						// Error: start time must be before end time
						errorDays.push(property);
					}
				} else {
					// Error: Time array contains a null or undefined
					errorDays.push(property);
				}
			}
		}

		let newDateErrors = {
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false
		};

		if (errorDays.length > 0) {
			for (let i = 0; i < errorDays.length; i++) {
				newDateErrors[errorDays[i]] = true;
			}
			this.setState({ dateErrors: newDateErrors, dateError: true, bookingRangeMaxError: errorBookingRangeMax, bookingRangeMinError: errorBookingRangeMin });

		} else if (errorBookingRangeMax || errorBookingRangeMin) {
			this.setState({ dateErrors: newDateErrors, dateError: false, bookingRangeMaxError: errorBookingRangeMax, bookingRangeMinError: errorBookingRangeMin });

		} else {
			this.submitData();
		}
	}

	submitData() {

		// Create data
		let data = {
			bookingRangeMax: this.state.bookingRangeMax,
			bookingRangeMin: this.state.bookingRangeMin
		}

		for (var property in this.state.date) {
			if (this.state.date.hasOwnProperty(property)) {
				//Do things here
				if (this.state.date[property] !== null) {


					if (parseInt(this.state.maxBookingPerDay[property]) !== 0) {
						data[property] = `${this.state.date[property][0]},${this.state.date[property][1]}|${this.state.maxBookingPerDay[property]}`;

					} else {
						data[property] = `${this.state.date[property][0]},${this.state.date[property][1]}`;
					}

				} else {
					data[property] = null;
				}
			}
		}

		if (this.state.availabilityExist) {
			this.props.editAvailability(data);
			this.setState({
				dateErrors: {
					monday: false,
					tuesday: false,
					wednesday: false,
					thursday: false,
					friday: false,
					saturday: false,
					sunday: false
				},
				dateError: false,
				bookingRangeMaxError: false,
				bookingRangeMinError: false
			});
		} else {
			this.props.createAvailability(data);
			this.setState({
				availabilityExist: true,
				dateErrors: {
					monday: false,
					tuesday: false,
					wednesday: false,
					thursday: false,
					friday: false,
					saturday: false,
					sunday: false
				},
				dateError: false,
				bookingRangeError: false
			});
		}
	}

	handleInputChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleMaxBookingChange(weekday, num) {

		const getFormattedNum = number => {
			let formattedNum = number.toString();

			if (formattedNum !== "") {
				formattedNum = formattedNum.replace(",", "");
				formattedNum = formattedNum.replace(".", "");

				if (formattedNum.length > 1 && formattedNum.charAt(0) === "0") {
					formattedNum = formattedNum.substr(1);
				}

			} else {
				formattedNum = 0;
			}

			return formattedNum;
		}

		let newMaxBookingObj = this.state.maxBookingPerDay;
		newMaxBookingObj[weekday] = getFormattedNum(num);
		this.setState({ maxBookingPerDay: newMaxBookingObj }, () => console.log(this.state));
	}

	render() {
		console.log(this.props);

		if (this.props.websiteInfo !== null) {



			return (
				<div className="dashboard-wrapper">
					<div className={!!this.state.showTimeKeeper ? "backdrop active" : "backdrop"}></div>
					<Navigation subMenu={true} subMenuTitle={"Availabilities"} active="availability" />
					<div className="dashboard-content-wrapper">
						<section className="availability">
							<Loading isLoading={this.props.isFetching} />
							<h1>Availabilities</h1>

							<div className={this.state.bookingRangeMinError ? 'field-container error' : 'field-container'}>
								<h2>Booking range start</h2>
								<p className="details">How many days before your next availability ?</p>
								<input type="number" name="bookingRangeMin" onChange={(text) => this.handleInputChange(text)} value={this.state.bookingRangeMin} />
							</div>

							<div className={this.state.bookingRangeMaxError ? 'field-container error' : 'field-container'}>
								<h2>Booking range end</h2>
								<p className="details">How many days does this booking period include ?<br />(ex: book for 18 days, 30 days or 90 days at a time)<br />*Maximum 90 days between start and end.</p>
								<input type="number" name="bookingRangeMax" onChange={(text) => this.handleInputChange(text)} value={this.state.bookingRangeMax} />
							</div>

							<h2>Available booking hours</h2>
							<p className="details subtitle">All booking hours must be increments of 30 minutes.</p>


							<div className={this.state.dateErrors.monday ? 'field-container error' : 'field-container'}>
								<h3>Monday {!this.state.date.monday && <span>(Closed)</span>}</h3>

								{/* Monday opening */}
								{!!this.state.date.monday &&
									<>
										<div className={this.state.showTimeKeeper === "open-monday" ? "timekeeper-container active" : "timekeeper-container"}>
											<TimeKeeper
												time={this.state.date.monday[0]}
												onDoneClick={(data) => this.onTimeChange(data, 'monday', 0)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>From</p>
											<p className="details">Booking starts at</p>
											<button onClick={() => this.handleShowTimeKeeper('open-monday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.monday[0] : this.convertTo12h(this.state.date.monday[0])}</button>
										</div>
									</>
								}


								{/* Monday closing */}
								{!!this.state.date.monday &&
									<>
										<div className={this.state.showTimeKeeper === "close-monday" ? "timekeeper-container active" : "timekeeper-container"}>
											<TimeKeeper
												time={this.state.date.monday[1]}
												onDoneClick={(data) => this.onTimeChange(data, 'monday', 1)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>To</p>
											<p className="details">Booking ends at</p>
											<button onClick={() => this.handleShowTimeKeeper('close-monday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.monday[1] : this.convertTo12h(this.state.date.monday[1])}</button>
										</div>
									</>
								}

								{/* Monday max bookings per day */}
								{!!this.state.date.monday &&
									<>
										<div className="info-container">
											<p>Maximum bookings amount</p>
											<p className="details">Enter 0 to remove the limit</p>
											<input type="number" min="1" max="99" onChange={(e) => this.handleMaxBookingChange('monday', e.target.value)} value={this.state.maxBookingPerDay.monday} />
										</div>
									</>
								}

								{!this.state.date.monday &&
									<button onClick={() => this.handleOpenDay('monday')}>Add availability</button>
								}

								{!!this.state.date.monday &&
									<button onClick={() => this.handleCloseDay('monday')}>Remove availability</button>
								}

							</div>



							<div className={this.state.dateErrors.tuesday ? 'field-container error' : 'field-container'}>
								<h3>Tuesday  {!this.state.date.tuesday && <span>(Closed)</span>}</h3>

								{/* Tuesday opening */}
								{!!this.state.date.tuesday &&
									<>
										<div className={this.state.showTimeKeeper === "open-tuesday" ? "timekeeper-container active" : "timekeeper-container"}>

											<TimeKeeper
												time={this.state.date.tuesday[0]}
												onDoneClick={(data) => this.onTimeChange(data, 'tuesday', 0)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>From</p>
											<p className="details">Booking starts at</p>
											<button onClick={() => this.handleShowTimeKeeper('open-tuesday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.tuesday[0] : this.convertTo12h(this.state.date.tuesday[0])}</button>
										</div>
									</>
								}


								{/* Tuesday closing */}
								{!!this.state.date.tuesday &&
									<>
										<div className={this.state.showTimeKeeper === "close-tuesday" ? "timekeeper-container active" : "timekeeper-container"}>
											<TimeKeeper
												time={this.state.date.tuesday[1]}
												onDoneClick={(data) => this.onTimeChange(data, 'tuesday', 1)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>To</p>
											<p className="details">Booking ends at</p>
											<button onClick={() => this.handleShowTimeKeeper('close-tuesday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.tuesday[1] : this.convertTo12h(this.state.date.tuesday[1])}</button>
										</div>
									</>
								}

								{/* Tuesday max bookings per day */}
								{!!this.state.date.tuesday &&
									<>
										<div className="info-container">
											<p>Maximum bookings amount</p>
											<p className="details">Enter 0 to remove the limit</p>
											<input type="number" min="1" max="99" onChange={(e) => this.handleMaxBookingChange('tuesday', e.target.value)} value={this.state.maxBookingPerDay.tuesday} />
										</div>
									</>
								}

								{!this.state.date.tuesday &&
									<button onClick={() => this.handleOpenDay('tuesday')}>Add availability</button>
								}

								{!!this.state.date.tuesday &&
									<button onClick={() => this.handleCloseDay('tuesday')}>Remove availability</button>
								}
							</div>

							<div className={this.state.dateErrors.wednesday ? 'field-container error' : 'field-container'}>
								<h3>Wednesday {!this.state.date.wednesday && <span>(Closed)</span>}</h3>

								{/* Wednesday opening */}
								{!!this.state.date.wednesday &&
									<>
										<div className={this.state.showTimeKeeper === "open-wednesday" ? "timekeeper-container active" : "timekeeper-container"}>

											<TimeKeeper
												time={this.state.date.wednesday[0]}
												onDoneClick={(data) => this.onTimeChange(data, 'wednesday', 0)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>From</p>
											<p className="details">Booking starts at</p>
											<button onClick={() => this.handleShowTimeKeeper('open-wednesday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.wednesday[0] : this.convertTo12h(this.state.date.wednesday[0])}</button>
										</div>
									</>
								}


								{/* Wednesday closing */}
								{!!this.state.date.wednesday &&
									<>
										<div className={this.state.showTimeKeeper === "close-wednesday" ? "timekeeper-container active" : "timekeeper-container"}>
											<TimeKeeper
												time={this.state.date.wednesday[1]}
												onDoneClick={(data) => this.onTimeChange(data, 'wednesday', 1)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>To</p>
											<p className="details">Booking ends at</p>
											<button onClick={() => this.handleShowTimeKeeper('close-wednesday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.wednesday[1] : this.convertTo12h(this.state.date.wednesday[1])}</button>
										</div>
									</>
								}

								{/* Wednesday max bookings per day */}
								{!!this.state.date.wednesday &&
									<>
										<div className="info-container">
											<p>Maximum bookings amount</p>
											<p className="details">Enter 0 to remove the limit</p>
											<input type="number" min="1" max="99" onChange={(e) => this.handleMaxBookingChange('wednesday', e.target.value)} value={this.state.maxBookingPerDay.wednesday} />
										</div>
									</>
								}

								{!this.state.date.wednesday &&
									<button onClick={() => this.handleOpenDay('wednesday')}>Add availability</button>
								}

								{!!this.state.date.wednesday &&
									<button onClick={() => this.handleCloseDay('wednesday')}>Remove availability</button>
								}
							</div>

							<div className={this.state.dateErrors.thursday ? 'field-container error' : 'field-container'}>
								<h3>Thursday {!this.state.date.thursday && <span>(Closed)</span>}</h3>
								{/* Thursday opening */}
								{!!this.state.date.thursday &&
									<>
										<div className={this.state.showTimeKeeper === "open-thursday" ? "timekeeper-container active" : "timekeeper-container"}>

											<TimeKeeper
												time={this.state.date.thursday[0]}
												onDoneClick={(data) => this.onTimeChange(data, 'thursday', 0)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>From</p>
											<p className="details">Booking starts at</p>
											<button onClick={() => this.handleShowTimeKeeper('open-thursday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.thursday[0] : this.convertTo12h(this.state.date.thursday[0])}</button>
										</div>
									</>
								}


								{/* Thursday closing */}
								{!!this.state.date.thursday &&
									<>
										<div className={this.state.showTimeKeeper === "close-thursday" ? "timekeeper-container active" : "timekeeper-container"}>
											<TimeKeeper
												time={this.state.date.thursday[1]}
												onDoneClick={(data) => this.onTimeChange(data, 'thursday', 1)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>To</p>
											<p className="details">Booking ends at</p>
											<button onClick={() => this.handleShowTimeKeeper('close-thursday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.thursday[1] : this.convertTo12h(this.state.date.thursday[1])}</button>
										</div>
									</>
								}

								{/* Thursday max bookings per day */}
								{!!this.state.date.thursday &&
									<>
										<div className="info-container">
											<p>Maximum bookings amount</p>
											<p className="details">Enter 0 to remove the limit</p>
											<input type="number" min="1" max="99" onChange={(e) => this.handleMaxBookingChange('thursday', e.target.value)} value={this.state.maxBookingPerDay.thursday} />
										</div>
									</>
								}

								{!this.state.date.thursday &&
									<button onClick={() => this.handleOpenDay('thursday')}>Add availability</button>
								}

								{!!this.state.date.thursday &&
									<button onClick={() => this.handleCloseDay('thursday')}>Remove availability</button>
								}
							</div>

							<div className={this.state.dateErrors.friday ? 'field-container error' : 'field-container'}>
								<h3>Friday {!this.state.date.friday && <span>(Closed)</span>}</h3>
								{/* Friday opening */}
								{!!this.state.date.friday &&
									<>
										<div className={this.state.showTimeKeeper === "open-friday" ? "timekeeper-container active" : "timekeeper-container"}>

											<TimeKeeper
												time={this.state.date.friday[0]}
												onDoneClick={(data) => this.onTimeChange(data, 'friday', 0)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>From</p>
											<p className="details">Booking starts at</p>
											<button onClick={() => this.handleShowTimeKeeper('open-friday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.friday[0] : this.convertTo12h(this.state.date.friday[0])}</button>
										</div>
									</>
								}


								{/* Friday closing */}
								{!!this.state.date.friday &&
									<>
										<div className={this.state.showTimeKeeper === "close-friday" ? "timekeeper-container active" : "timekeeper-container"}>
											<TimeKeeper
												time={this.state.date.friday[1]}
												onDoneClick={(data) => this.onTimeChange(data, 'friday', 1)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>To</p>
											<p className="details">Booking ends at</p>
											<button onClick={() => this.handleShowTimeKeeper('close-friday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.friday[1] : this.convertTo12h(this.state.date.friday[1])}</button>
										</div>
									</>
								}

								{/* Friday max bookings per day */}
								{!!this.state.date.friday &&
									<>
										<div className="info-container">
											<p>Maximum bookings amount</p>
											<p className="details">Enter 0 to remove the limit</p>
											<input type="number" min="1" max="99" onChange={(e) => this.handleMaxBookingChange('friday', e.target.value)} value={this.state.maxBookingPerDay.friday} />
										</div>
									</>
								}

								{!this.state.date.friday &&
									<button onClick={() => this.handleOpenDay('friday')}>Add availability</button>
								}

								{!!this.state.date.friday &&
									<button onClick={() => this.handleCloseDay('friday')}>Remove availability</button>
								}
							</div>

							<div className={this.state.dateErrors.saturday ? 'field-container error' : 'field-container'}>
								<h3>Saturday {!this.state.date.saturday && <span>(Closed)</span>}</h3>
								{/* Saturday opening */}
								{!!this.state.date.saturday &&
									<>
										<div className={this.state.showTimeKeeper === "open-saturday" ? "timekeeper-container active" : "timekeeper-container"}>

											<TimeKeeper
												time={this.state.date.saturday[0]}
												onDoneClick={(data) => this.onTimeChange(data, 'saturday', 0)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>From</p>
											<p className="details">Booking starts at</p>
											<button onClick={() => this.handleShowTimeKeeper('open-saturday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.saturday[0] : this.convertTo12h(this.state.date.saturday[0])}</button>
										</div>
									</>
								}


								{/* Saturday closing */}
								{!!this.state.date.saturday &&
									<>
										<div className={this.state.showTimeKeeper === "close-saturday" ? "timekeeper-container active" : "timekeeper-container"}>
											<TimeKeeper
												time={this.state.date.saturday[1]}
												onDoneClick={(data) => this.onTimeChange(data, 'saturday', 1)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>To</p>
											<p className="details">Booking ends at</p>
											<button onClick={() => this.handleShowTimeKeeper('close-saturday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.saturday[1] : this.convertTo12h(this.state.date.saturday[1])}</button>
										</div>
									</>
								}

								{/* Saturday max bookings per day */}
								{!!this.state.date.saturday &&
									<>
										<div className="info-container">
											<p>Maximum bookings amount</p>
											<p className="details">Enter 0 to remove the limit</p>
											<input type="number" min="1" max="99" onChange={(e) => this.handleMaxBookingChange('saturday', e.target.value)} value={this.state.maxBookingPerDay.saturday} />
										</div>
									</>
								}

								{!this.state.date.saturday &&
									<button onClick={() => this.handleOpenDay('saturday')}>Add availability</button>
								}

								{!!this.state.date.saturday &&
									<button onClick={() => this.handleCloseDay('saturday')}>Remove availability</button>
								}
							</div>

							<div className={this.state.dateErrors.sunday ? 'field-container error' : 'field-container'}>
								<h3>Sunday {!this.state.date.sunday && <span>(Closed)</span>}</h3>

								{/* Sunday opening */}
								{!!this.state.date.sunday &&
									<>
										<div className={this.state.showTimeKeeper === "open-sunday" ? "timekeeper-container active" : "timekeeper-container"}>

											<TimeKeeper
												time={this.state.date.sunday[0]}
												onDoneClick={(data) => this.onTimeChange(data, 'sunday', 0)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>From</p>
											<p className="details">Booking starts at</p>
											<button onClick={() => this.handleShowTimeKeeper('open-sunday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.sunday[0] : this.convertTo12h(this.state.date.sunday[0])}</button>
										</div>
									</>
								}


								{/* Sunday closing */}
								{!!this.state.date.sunday &&
									<>
										<div className={this.state.showTimeKeeper === "close-sunday" ? "timekeeper-container active" : "timekeeper-container"}>
											<TimeKeeper
												time={this.state.date.sunday[1]}
												onDoneClick={(data) => this.onTimeChange(data, 'sunday', 1)}
												config={this.timekeeperConfig}
											/>
										</div>

										<div className="info-container">
											<p>To</p>
											<p className="details">Booking ends at</p>
											<button onClick={() => this.handleShowTimeKeeper('close-sunday')}>{this.props.websiteInfo.data[0].is24HourConvention ? this.state.date.sunday[1] : this.convertTo12h(this.state.date.sunday[1])}</button>
										</div>
									</>
								}

								{/* Sunday max bookings per day */}
								{!!this.state.date.sunday &&
									<>
										<div className="info-container">
											<p>Maximum bookings amount</p>
											<p className="details">Enter 0 to remove the limit</p>
											<input type="number" min="1" max="99" onChange={(e) => this.handleMaxBookingChange('sunday', e.target.value)} value={this.state.maxBookingPerDay.sunday} />
										</div>
									</>
								}

								{!this.state.date.sunday &&
									<button onClick={() => this.handleOpenDay('sunday')}>Add availability</button>
								}

								{!!this.state.date.sunday &&
									<button onClick={() => this.handleCloseDay('sunday')}>Remove availability</button>
								}
							</div>

							<div className="errors-container">
								{this.state.dateError &&
									<p className="error">Please make sure all opening hours are increments of 30 minutes and entered correctly.</p>
								}

								{this.state.bookingRangeMinError &&
									<p className="error">Please make sure the booking range start is entered correctly and smaller than the booking range end.</p>
								}

								{this.state.bookingRangeMaxError &&
									<p className="error">Please make sure the booking range end is entered correctly and higher than the booking range start.</p>
								}
							</div>

							<div>
								<button onClick={() => this.handleSubmit()}>Save</button>
							</div>
						</section>
					</div>
				</div >
			);

		}
		else {
			return null;
		}
	}
}

const mapStateToProps = state => ({
	availability: state.availability.availability,
	isFetching: state.availability.isFetching,
});

export default connect(mapStateToProps, { createAvailability, fetchCurrentUserAvailability, editAvailability })(Availability);