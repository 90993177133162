import axios from 'axios';
import { FETCHING_FLASHES_REQUEST, FETCHING_FLASHES_SUCCESS, FETCHING_FLASHES_FAILURE, FETCHING_FLASH_REQUEST, FETCHING_FLASH_SUCCESS, FETCHING_FLASH_FAILURE, PUT_FLASH_REQUEST, PUT_FLASH_SUCCESS, PUT_FLASH_FAILURE, DELETE_FLASH_REQUEST, DELETE_FLASH_SUCCESS, DELETE_FLASH_FAILURE, POST_FLASH_REQUEST, POST_FLASH_SUCCESS, POST_FLASH_FAILURE } from './types';

// All flashes
export const fetchFlashes = (siteUrl) => {
    return async dispatch => {

        try {
            dispatch({ type: FETCHING_FLASHES_REQUEST });
            const response = await axios({
                url: `/flashes/${siteUrl}`,
                method: 'get',
                timeout: 10000,

            });
            dispatch({ type: FETCHING_FLASHES_SUCCESS, payload: response });
        } 
        catch (error) {
                dispatch({ type: FETCHING_FLASHES_FAILURE, payload: error });
        }
    };
}

export const fetchCurrentUserFlashes = () => {
    return async dispatch => {
        try {
            dispatch({ type: FETCHING_FLASHES_REQUEST });
            const response = await axios.get("/flash");
            dispatch({ type: FETCHING_FLASHES_SUCCESS, payload: response });
        } 
        catch (error) {
            dispatch({ type: FETCHING_FLASHES_FAILURE, payload: error });
        }
    };
}

export const postFlash = data => async (dispatch) => {
    try {
      dispatch({ type: POST_FLASH_REQUEST });
      const response = await axios.post('/flash', data);
      dispatch({ type: POST_FLASH_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: POST_FLASH_FAILURE, payload: error });
    }
};

//Single flash by ID
export const fetchFlashById = (flashId) => {
    return async dispatch => {
        try {
            dispatch({ type: FETCHING_FLASH_REQUEST });
            const response = await axios({
                url: `/flash/${flashId}`,
                method: 'get',
                timeout: 10000
            });
            dispatch({ type: FETCHING_FLASH_SUCCESS, payload: response });
        }
        catch (error) {
            dispatch({ type: FETCHING_FLASH_FAILURE, payload: error });
        }
    };
}

export const putFlash = data => async (dispatch) => {
    try {
      dispatch({ type: PUT_FLASH_REQUEST });
      const response = await axios.put(`flash/${data.id}`, data);
      dispatch({ type: PUT_FLASH_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: PUT_FLASH_FAILURE, payload: error });
    }
};


export const deleteFlash = id => async (dispatch) => {
    try {
      dispatch({ type: DELETE_FLASH_REQUEST });
      const response = await axios.delete(`flash/${id}`);
      dispatch({ type: DELETE_FLASH_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: DELETE_FLASH_FAILURE, payload: error });
    }
};


