import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUserAvailability, fetchCurrentUserFlashes, fetchCurrentUserPages, fetchCurrentUserWebsiteInfo } from '../../actions';
import PropTypes from 'prop-types';
import Navigation from './partials/navigation';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Loading from "./partials/loading";

class Dashboard extends Component {
	static propTypes = {
		fetchUser: PropTypes.func.isRequired,
		currentUser: PropTypes.object,
	}

	componentDidMount() {
		this.setState({ isWebsiteCreated: true });
		if (!_.isEmpty(this.props.websiteInfo)) {
			this.props.fetchCurrentUserPages();
		}
		if (_.isEmpty(this.props.availability)) {
			this.props.fetchCurrentUserAvailability();
		}
		if (_.isEmpty(this.props.flashes)) {
			this.props.fetchCurrentUserFlashes();
		}
		if (_.isEmpty(this.props.websiteInfo)) {
			this.props.fetchCurrentUserWebsiteInfo().then((data) => { console.log(data) });
		}
	}

	componentDidUpdate() {
		if (!_.isEmpty(this.props.websiteInfo)) {
			if (_.isEmpty(this.props.websitePages))
				this.props.fetchCurrentUserPages();
		}
	}

	handleShowModal() {
		if (!!this.props.currentUser && (!this.props.currentUser.data.stipeId || !this.props.currentUser.data.googleRefreshToken)) {
			this.props.showModal();
		}
	}

	render() {

		console.log(this.props);

		return (
			<>
				<Loading isLoading={((_.isEmpty(this.props.websiteInfo) && _.isEmpty(this.props.websitePages)) || _.isEmpty(this.props.availability) || _.isEmpty(this.props.flashes))} />

				{(!_.isEmpty(this.props.currentUser) && !_.isEmpty(this.props.websiteInfo)) &&
					<div>
						{/* --- open first login if user dont have website --- */
							<div className="dashboard-wrapper">
								<Navigation subMenu={true} subMenuTitle={"Home"} active="index" websiteInfo={this.props.websiteInfo} />
								<div className="dashboard-content-wrapper">
									<section className="home">
										<h1>Welcome {this.props.currentUser.data.email}</h1>
										{
											(
												!_.isEmpty(this.props.websitePages) &&
												!_.isEmpty(this.props.availability) &&
												!_.isEmpty(this.props.flashes) &&
												!_.isEmpty(this.props.websiteInfo)
											) &&
											<>
												<div className="field-container">
													<h2>Get started</h2>
													<p className="details">In order to have a fully functional website, the following steps must be completed.</p>

													<div className="itemProgressionWrapper">
														<Link to={"/admin/settings/general"}>
															<h3>Store address</h3>
															{
																(
																	this.props.websiteInfo.data.length !== 0 &&
																	this.props.websiteInfo.data[0].address.addressLine1 !== "" &&
																	this.props.websiteInfo.data[0].address.city !== "" &&
																	this.props.websiteInfo.data[0].address.country !== null &&
																	this.props.websiteInfo.data[0].address.postal !== "" &&
																	this.props.websiteInfo.data[0].address.province !== null
																) ?
																	<img className="checked-image" src={require('../../assets/images/dashboard/icons/checked_3x.png')} /> :
																	<>
																		<figure className="circle"></figure>
																		<p>Enter your store location</p>
																	</>
															}
														</Link>
													</div>

													<div className="itemProgressionWrapper">
														<Link to={"/admin/availabilities"}>
															<h3>Availability</h3>
															{
																this.props.availability.data.statusCode !== 0 ?
																	<img className="checked-image" src={require('../../assets/images/dashboard/icons/checked_3x.png')} /> :
																	<>
																		<figure className="circle"></figure>
																		<p>Enter your availability hours and booking range.</p>
																	</>
															}
														</Link>
													</div>

													<div className="itemProgressionWrapper">
														<Link to={"/admin/site-pages/home"}>
															<h3>Create your first page</h3>
															{
																this.props.websitePages.data.length !== 0 ?
																	<img className="checked-image" src={require('../../assets/images/dashboard/icons/checked_3x.png')} /> :
																	<>
																		<figure className="circle"></figure>
																		<p>Let clients visit your new website, starting with your home page.</p>
																	</>
															}
														</Link>
													</div>

													<div className="itemProgressionWrapper">
														<Link to={"/admin/flashes/addflashes"}>
															<h3>Add your first flash</h3>
															{
																this.props.flashes.data.length !== 0 ?
																	<img className="checked-image" src={require('../../assets/images/dashboard/icons/checked_3x.png')} /> :
																	<>
																		<figure className="circle"></figure>
																		<p>Add your own creations and start making sales.</p>
																	</>
															}
														</Link>
													</div>

													<div className="itemProgressionWrapper">
														<Link to={"/admin/settings/general"}>
															<h3>Add your own logo</h3>
															{
																(this.props.websiteInfo.data.length === 0 || this.props.websiteInfo.data[0].logo === undefined || this.props.websiteInfo.data[0].logo === null || this.props.websiteInfo.data[0].logo.imageUrl === null) ?
																	<>
																		<figure className="circle"></figure>
																		<p>Brand your website by adding your unique logo.</p>
																	</>
																	:
																	<img className="checked-image" src={require('../../assets/images/dashboard/icons/checked_3x.png')} />
															}
														</Link>
													</div>
													<div className="itemProgressionWrapper">
														<button onClick={() => this.handleShowModal()}>
															<h3>Link your Stripe and Google calendar accounts</h3>
															{
																(!this.props.currentUser.data.stripeId || !this.props.currentUser.data.googleRefreshToken) ?
																	<>
																		<figure className="circle"></figure>
																		<p>Receive client payments and bookings.</p>
																	</>
																	:
																	<img className="checked-image" src={require('../../assets/images/dashboard/icons/checked_3x.png')} />
															}
														</button>
													</div>
												</div>
											</>
										}
									</section>
								</div>
							</div>
						}
					</div>
				}

			</>
		);
	}
}

const mapStateToProps = state => ({
	availability: state.availability.availability,
	isFetchingAvailability: state.availability.isFetching,
	flashes: state.flash.flashes,
	isFlashesFetching: state.flash.isFetching,
	websitePages: state.page.pages,
	websiteInfo: state.websiteInfo.websiteInfo,
	isWebsitePagesFetching: state.page.pages
});

export default connect(mapStateToProps, { fetchCurrentUserAvailability, fetchCurrentUserFlashes, fetchCurrentUserPages, fetchCurrentUserWebsiteInfo })(Dashboard);