import axios from 'axios';
import { FETCHING_WEBSITEINFO_REQUEST, FETCHING_WEBSITEINFO_SUCCESS, FETCHING_WEBSITEINFO_FAILURE, FETCHING_CURRENT_USER_WEBSITE_INFO_REQUEST, FETCHING_CURRENT_USER_WEBSITE_INFO_SUCCESS, FETCHING_CURRENT_USER_WEBSITE_INFO_FAILURE, CREATE_WEBSITE_REQUEST, CREATE_WEBSITE_SUCCESS, CREATE_WEBSITE_FAILURE, EDIT_WEBSITE_REQUEST, EDIT_WEBSITE_SUCCESS, EDIT_WEBSITE_FAILURE, CHANGE_URL_REQUEST, CHANGE_URL_SUCCESS, CHANGE_URL_FAILURE } from './types';

export const fetchCurrentUserWebsiteInfo = () => {
	return async dispatch => {
        try {
            dispatch({ type: FETCHING_CURRENT_USER_WEBSITE_INFO_REQUEST });
            const response = await axios({
                url: `/website`,
                method: 'get'
            });
            dispatch({ type: FETCHING_CURRENT_USER_WEBSITE_INFO_SUCCESS, payload: response });
        } 
        catch(error) {
            dispatch({ type: FETCHING_CURRENT_USER_WEBSITE_INFO_FAILURE, payload: error });
        }
    };
}

export const createWebsite = data => async (dispatch) => {
    try {
      dispatch({ type: CREATE_WEBSITE_REQUEST });
      const response = await axios.post('/website', data);
      dispatch({ type: CREATE_WEBSITE_SUCCESS, payload: response.data });
    } catch (error) {
      alert(error);
      dispatch({ type: CREATE_WEBSITE_FAILURE, payload: error });
    }
  };

  export const editWebsite = data => async (dispatch) => {
    try {      
      console.log(data);
      dispatch({ type: EDIT_WEBSITE_REQUEST });
      const response = await axios.put('/website', data);
      dispatch({ type: EDIT_WEBSITE_SUCCESS, payload: response.data });
    } catch (error) {
      alert(error);
      dispatch({ type: EDIT_WEBSITE_FAILURE, payload: error });
    }
  };

export const fetchWebsiteInfo = (siteUrl) => {
	return async dispatch => {
        try {
            dispatch({ type: FETCHING_WEBSITEINFO_REQUEST });
            const response = await axios({
                url: `/website/${siteUrl}`,
                method: 'get',
                timeout: 15000
            });
            dispatch({ type: FETCHING_WEBSITEINFO_SUCCESS, payload: response });
        } 
        catch(error) {
            dispatch({ type: FETCHING_WEBSITEINFO_FAILURE, payload: error });
        }
    };
}
  
export const changeWebsiteUrl = (changeWebsiteUrl) => {
	return async dispatch => {
        try {
            dispatch({ type: CHANGE_URL_REQUEST });
            const response = await axios({
                url: `/changeurl/${changeWebsiteUrl}`,
                method: 'put'
            });
            dispatch({ type: CHANGE_URL_SUCCESS, payload: response });
        } 
        catch(error) {
            dispatch({ type: CHANGE_URL_FAILURE, payload: error });
        }
    };
}