import _ from 'lodash';

export const hasStoreAddress = (pAddress) => {
    if (
        !_.isEmpty(pAddress.addressLine1) &&
        !_.isEmpty(pAddress.city) &&
        !_.isEmpty(pAddress.country) &&
        !_.isEmpty(pAddress.postal)
    ) {
        return true;
    }
    return false;

}