import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProductItem extends Component {


    render(props) {
        let activeState = this.props.isActive ? "active  " : "inactive";
        const pricingMin = this.props.data.pricingMin + this.props.data.deposit;
        const pricingMax = this.props.data.pricingMax + this.props.data.deposit;

        return (
            <Link to={`/${this.props.siteUrl}/${this.props.type}/${this.props.data.id}`} className={"product-item " + activeState}>
                <div className="image-container">
                    {
                        !!this.props.data.image ?
                            <img src={this.props.data.image.imageUrl} alt="Product" /> :
                            <img loading="lazy" src={require("../../../assets/images/sites/placeholders/product-placeholder.png")} alt="Placeholder"></img>
                    }
                </div>
                <h4>{this.props.data.title}</h4>
                {
                    (this.props.data.hasOwnProperty('isRecurring') && !this.props.data.isRecurring) &&
                    <p className="recurrence">Unique</p>
                }
                {
                    (this.props.data.hasOwnProperty('category') && this.props.data.category !== null) &&
                    <p className="category">{this.props.data.category.name}</p>
                }
                {
                    this.props.type === "flash" &&
                    <>
                        {
                            (!!this.props.data.pricingMax && (this.props.data.pricingMax > this.props.data.pricingMin)) ?
                                <p>{pricingMin} {this.props.websiteInfo.ownerCurrency.toUpperCase()} - {pricingMax} {this.props.websiteInfo.ownerCurrency.toUpperCase()}</p>
                                :
                                <p>{pricingMin} {this.props.websiteInfo.ownerCurrency.toUpperCase()}</p>
                        }
                    </>
                }

                {
                    this.props.type === "product" &&
                    <>
                        <p>{this.props.data.price} {this.props.websiteInfo.ownerCurrency.toUpperCase()}</p>
                    </>
                }
            </Link>
        );
    }
}

export default ProductItem;   