import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const initialState = {};
const middleware = [thunk];
let store = null;

const middlewares = [applyMiddleware(...middleware)]
if (process.env.REACT_APP_ENV === 'development')
	if (!window.__REDUX_DEVTOOLS_EXTENSION__)
		console.log("Please install redux devtools extension")
	else middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__())

store = createStore(
	rootReducer,
	initialState,
	compose(...middlewares)
);

export default store;