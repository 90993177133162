import React, { Component } from 'react';
import { fetchProducts } from '../../actions';
import _ from "lodash";

import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll'

// import partials
import BannerSmall from "./sections/section.banner-small";
import ProductsListing from "./sections/section.products-listing";


class SitesProducts extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		this.productsListingData = {
			title: "",
			isLoading: false,
			products: [],
			errorMsg: "No products found."
		};

		this.bannerData = null;

	}
	componentDidMount() {
		scroll.scrollToTop();
		const siteUrl = this.props.match.params.site;

		if (_.isEmpty(this.props.products)) {
			this.setState({ isLoading: true });
			this.props.fetchProducts(siteUrl, false);
		} else {
			this.setState({ isLoading: false });
		}
	}

	render() { 
		// Define productsListingData products
		if (!_.isEmpty(this.props.products)) {
			this.productsListingData.products = this.props.products.data.data;
		}

		// Define bannerData
		if (!!this.props.data && !this.bannerData) {
			if (!!this.props.data.content1 || !!this.props.data.banner) this.bannerData = {};
			if (!!this.props.data.content1) this.bannerData.title = this.props.data.content1;
			if (!!this.props.data.banner) this.bannerData.background = this.props.data.banner.imageUrl;
		}

		this.productsListingData.isLoading = this.props.isFetching ? true : false;

		return (
			<div className="main">
				<BannerSmall data={this.bannerData} />
				{
					this.productsListingData !== null &&
					<ProductsListing
						data={this.productsListingData}
						siteUrl={this.props.siteUrl}
						websiteInfo={this.props.websiteInfo}
						type="product"
					/>

				}

			</div>
		);
	}
}

const mapStateToProp = state => {
	return {
		products: state.product.products,
		websiteInfo: state.websiteInfo.websiteInfo.data,
		isFetching: state.flash.isFetching
	}
}

export default connect(mapStateToProp, { fetchProducts })(SitesProducts);
