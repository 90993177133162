import { ADD_STRIPE_ACCOUNT_REQUEST, ADD_STRIPE_ACCOUNT_SUCCESS, ADD_STRIPE_ACCOUNT_FAILURE, /*PROCESS_PAYMENT_CONNECT_REQUEST, PROCESS_PAYMENT_CONNECT_SUCCESS, PROCESS_PAYMENT_CONNECT_FAILURE, PROCESS_PRODUCT_PAYMENT_CONNECT_REQUEST, PROCESS_PRODUCT_PAYMENT_CONNECT_SUCCESS, PROCESS_PRODUCT_PAYMENT_CONNECT_FAILURE, */GET_CARD_REQUEST, GET_CARD_SUCCESS, GET_CARD_FAILURE, CREATE_CARD_REQUEST, CREATE_CARD_SUCCESS, CREATE_CARD_FAILURE, DELETE_CARD_REQUEST, DELETE_CARD_SUCCESS, DELETE_CARD_FAILURE, GET_ADDRESS_REQUEST, GET_ADDRESS_SUCCESS, GET_ADDRESS_FAILURE, GET_PURCHASES_REQUEST, GET_PURCHASES_SUCCESS, GET_PURCHASES_FAILURE, POST_INTENT_REQUEST, POST_INTENT_SUCCESS, POST_INTENT_FAILURE, POST_INTENT_PRODUCT_REQUEST, POST_INTENT_PRODUCT_SUCCESS, POST_INTENT_PRODUCT_FAILURE } from '../actions/types';

const initialState = {
    stripeAccount: null,
    stripeResponse: null,
    cards: null,
    card: null,
    address: null,
    purchases: null,
    delCard: null,
    paymentIntent: {},
    paymentIntentProduct: {},
    isFetching: false,
    errors: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_STRIPE_ACCOUNT_REQUEST:
            return { ...state, isFetching: true };
        case ADD_STRIPE_ACCOUNT_SUCCESS:
            return { ...state, isFetching: false, stripeAccount: action.payload };
        case ADD_STRIPE_ACCOUNT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        /*case PROCESS_PAYMENT_CONNECT_REQUEST:
            return { ...state, isFetching: true };
        case PROCESS_PAYMENT_CONNECT_SUCCESS:
            return { ...state, isFetching: false, stripeResponse: action.payload };
        case PROCESS_PAYMENT_CONNECT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case PROCESS_PRODUCT_PAYMENT_CONNECT_REQUEST:
            return { ...state, isFetching: true };
        case PROCESS_PRODUCT_PAYMENT_CONNECT_SUCCESS:
            return { ...state, isFetching: false, stripeResponse: action.payload };
        case PROCESS_PRODUCT_PAYMENT_CONNECT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };*/
        case GET_CARD_REQUEST:
            return { ...state, isFetching: true };
        case GET_CARD_SUCCESS:
            return { ...state, isFetching: false, cards: action.payload };
        case GET_CARD_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case CREATE_CARD_REQUEST:
            return { ...state, isFetching: true };
        case CREATE_CARD_SUCCESS:
            return { ...state, isFetching: false, card: action.payload };
        case CREATE_CARD_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case DELETE_CARD_REQUEST:
            return { ...state, isFetching: true };
        case DELETE_CARD_SUCCESS:
            return { ...state, isFetching: false, delCard: action.payload };
        case DELETE_CARD_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case GET_ADDRESS_REQUEST:
            return { ...state, isFetching: true };
        case GET_ADDRESS_SUCCESS:
            return { ...state, isFetching: false, address: action.payload };
        case GET_ADDRESS_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case GET_PURCHASES_REQUEST:
            return { ...state, isFetching: true };
        case GET_PURCHASES_SUCCESS:
            return { ...state, isFetching: false, purchases: action.payload };
        case GET_PURCHASES_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case POST_INTENT_REQUEST:
            return { ...state, isFetching: true };
        case POST_INTENT_SUCCESS:
            return { ...state, isFetching: false, paymentIntent: action.payload };
        case POST_INTENT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case POST_INTENT_PRODUCT_REQUEST:
            return { ...state, isFetching: true };
        case POST_INTENT_PRODUCT_SUCCESS:
            return { ...state, isFetching: false, paymentIntentProduct: action.payload };
        case POST_INTENT_PRODUCT_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;
    }
}