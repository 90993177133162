import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postSupportEmail } from '../../actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Navigation from './partials/navigation';
import Loading from "./partials/loading";

class Support extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			subject: '',
			email: '',
			message: '',
			mailSent: false,
			error: "",
			errorName: false,
			errorSubject: false,
			errorEmail: false,
			errorMessage: false,
			errorSend: false
		};
	}

	//componentDidMount() {}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value }, () => {
			if (this.state.error !== "") {
				if (this.state.errorName && this.state.name !== "") {
					this.setState({ errorName: false });
				}

				if (this.state.errorSubject && this.state.subject !== "") {
					this.setState({ errorSubject: false });
				}

				if (this.state.errorEmail && this.state.email !== "") {
					this.setState({ errorEmail: false });
				}

				if (this.state.errorMessage && this.state.message !== "") {
					this.setState({ errorMessage: false });
				}
			}
		});
	}

	handleFormSubmit = (e) => {

		e.preventDefault();

		function validateEmail(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		}

		if (this.state.name === "" || this.state.subject === "" || this.state.email === "" || this.state.message === "") {
			this.setState({ error: "You are required to fill all the fields. Please do so before submitting the form." })

			if (this.state.name === "") {
				this.setState({ errorName: true });
			}

			if (this.state.subject === "") {
				this.setState({ errorSubject: true });
			}

			if (this.state.email === "") {
				this.setState({ errorEmail: true });
			}

			if (this.state.message === "") {
				this.setState({ errorMessage: true });
			}

		} else if (!validateEmail(this.state.email)) {
			this.setState({ errorEmail: true, error: "The email address is invalid." });

		} else {
			this.setState({ error: "" }, () => {

				let emailData = {
					name: this.state.name,
					email: this.state.email,
					subject: this.state.subject,
					message: this.state.message
				}

				this.props.postSupportEmail(emailData, this.props.siteUrl).then(() => {

					if (this.props.supportEmailResponse !== undefined && this.props.supportEmailResponse.data.statusCode === 1) {
						this.setState({ mailSent: true, errorSend: false })
					} else {
						this.setState({ errorSend: true })
					}

				})
			})
		}
	};

	handleSubmit() {
		console.log('handle submit');
	}




	render() {
		return (
			<>
				<Loading isLoading={this.props.isFetching} />
				<div className="dashboard-wrapper">
					<Navigation subMenu={true} subMenuTitle={"Support"} active="support" />
					<div className="dashboard-content-wrapper">
						<section className="support">
							<h1>Support</h1>
							<div className="field-container">
								<h2>Frequently asked questions</h2>
								<p className="details">Have a question about BookMe.Ink? Consult the FAQ to get a quick answer.</p>
								<div className="link-container">
									<a className="button" target='_blank' href="https://bookme.ink/faq/">FAQ</a>
								</div>
							</div>

							<div className="field-container">
								<h2>Contact us</h2>
								<p className="details">If the FAQ didn't answer your question, contact us directly.</p>




								{!!this.props.data &&
									<div className="paragraphs-container">
										<div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: this.props.data.text }}></div>
									</div>
								}


								{!this.state.mailSent &&
									<form action="" className="form">

										<input
											className={this.state.errorName ? "invalid" : ""}
											type="text"
											name="name"
											autoComplete="off"
											placeholder={"Name"}
											onChange={this.handleChange}
											required
										/>
										<input
											className={this.state.errorEmail ? "invalid" : ""}
											type="email"
											name="email"
											placeholder={"Email"}
											onChange={this.handleChange}
											autoComplete="off"
											required
										/>
										<input
											className={this.state.errorSubject ? "invalid" : ""}
											type="text"
											name="subject"
											autoComplete="off"
											placeholder={"Subject"}
											onChange={this.handleChange}
											required
										/>
										<textarea
											className={this.state.errorMessage ? "invalid" : ""}
											name="message"
											placeholder={"Message"}
											onChange={this.handleChange}
											required
										/>

										{!!this.state.error && <p className="error">{this.state.error}</p>}

										{
											this.state.errorSend &&
											<p className="error">We were unable to send your email. Please try again later.</p>
										}

										<input
											type="submit"
											onClick={e => this.handleFormSubmit(e)}
											value="Submit"
											className="button"
										/>


									</form>
								}

								{
									this.state.mailSent &&
									<div className='success-container'>
										<p className="success">Your email was successfully sent.</p>
									</div>
								}


							</div>

						</section>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	supportEmailResponse: state.email.emailResponse,
	isFetching: state.email.isFetching
});

export default connect(mapStateToProps, { postSupportEmail })(Support);