import { POST_CONTACT_EMAIL_REQUEST, POST_CONTACT_EMAIL_SUCCESS, POST_CONTACT_EMAIL_FAILURE, POST_SUPPORT_EMAIL_REQUEST, POST_SUPPORT_EMAIL_SUCCESS, POST_SUPPORT_EMAIL_FAILURE } from '../actions/types';

const initialState = {
    emailResponse: null,
	isFetching: false,
	errors: null
}

export default function(state = initialState, action) {
	switch(action.type) {
        case POST_CONTACT_EMAIL_REQUEST:
            return { ...state, isFetching: true };
        case POST_CONTACT_EMAIL_SUCCESS:
            return { ...state, isFetching: false, emailResponse: action.payload };
        case POST_CONTACT_EMAIL_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        case POST_SUPPORT_EMAIL_REQUEST:
            return { ...state, isFetching: true };
        case POST_SUPPORT_EMAIL_SUCCESS:
            return { ...state, isFetching: false, emailResponse: action.payload };
        case POST_SUPPORT_EMAIL_FAILURE:
            return { ...state, isFetching: false, errors: action.payload };
        default:
            return state;
    }
} 