import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUserPages } from '../../actions';
import _ from 'lodash';
import Navigation from './partials/navigation';
import SitePagesItem from './partials/sitePagesItem';

class SitePages extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if(_.isEmpty(this.props.pages)){
            this.props.fetchCurrentUserPages();
        }
    }
    
	render() { 
		return (
            <div className="dashboard-wrapper">
                {
                    _.isEmpty(this.props.pages) ?
                    <h1>Loading</h1> : 
                    <div>
                        <Navigation 
                        active="pages" 
                        subMenu={true} 
                        pathname={this.props.location.pathname} 
                        subMenuItems={[
                            {name: "Home", link: "/admin/site-pages/home"},
                            {name: "Flash gallery", link: "/admin/site-pages/flashes"},
                            {name: "Products", link: "/admin/site-pages/products"},
                            {name: "About", link: "/admin/site-pages/about"},
                            {name: "Contact", link: "/admin/site-pages/contact"},
                            {name: "Terms and conditions", link: "/admin/site-pages/terms-and-conditions"}
                            ]} 
                        subMenuTitle={"Pages"} 
                            />
                        <div className="dashboard-content-wrapper">
                            <div>
                                {
                                    this.props.location.pathname === "/admin/site-pages/home" && <SitePagesItem pages={this.props.pages} pageType={"homepage"} />
                                }
                                {
                                    this.props.location.pathname === "/admin/site-pages/flashes" && <SitePagesItem pages={this.props.pages} pageType={"flashes"} />
                                }
                                 {
                                    this.props.location.pathname === "/admin/site-pages/products" && <SitePagesItem pages={this.props.pages} pageType={"products"} />
                                }
                                {
                                    this.props.location.pathname === "/admin/site-pages/about" && <SitePagesItem pages={this.props.pages} pageType={"about"} />
                                }
                                {
                                    this.props.location.pathname === "/admin/site-pages/terms-and-conditions" && <SitePagesItem pages={this.props.pages} pageType={"terms-and-conditions"} />
                                }
                                {
                                    this.props.location.pathname === "/admin/site-pages/contact" && <SitePagesItem pages={this.props.pages} pageType={"contact"} />
                                }
                            </div>
                        </div>
                    </div>
                }
			</div>
		);
	}
}

const mapStateToProps = state => ({
    pages: state.page.pages,
});

export default connect(mapStateToProps, { fetchCurrentUserPages })(SitePages);