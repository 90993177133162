import axios from 'axios';
import { FETCHING_PRODUCTS_REQUEST, FETCHING_PRODUCTS_SUCCESS, FETCHING_PRODUCTS_FAILURE, FETCHING_PRODUCT_REQUEST, FETCHING_PRODUCT_SUCCESS, FETCHING_PRODUCT_FAILURE, PUT_PRODUCT_REQUEST, PUT_PRODUCT_SUCCESS, PUT_PRODUCT_FAILURE, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILURE, POST_PRODUCT_REQUEST, POST_PRODUCT_SUCCESS, POST_PRODUCT_FAILURE } from './types';

// All flashes
export const fetchProducts = (siteUrl) => {
    return async dispatch => {

        try {
            dispatch({ type: FETCHING_PRODUCTS_REQUEST });
            const response = await axios({
                url: `/products/${siteUrl}`,
                method: 'get',
                timeout: 10000, 
            });
            dispatch({ type: FETCHING_PRODUCTS_SUCCESS, payload: response });
        } 
        catch (error) {
                dispatch({ type: FETCHING_PRODUCTS_FAILURE, payload: error });
        }
    };
}

export const fetchCurrentUserProducts = () => {
    return async dispatch => {
        try {
            dispatch({ type: FETCHING_PRODUCTS_REQUEST });
            const response = await axios.get("/products");
            dispatch({ type: FETCHING_PRODUCTS_SUCCESS, payload: response });
        } 
        catch (error) {
            dispatch({ type: FETCHING_PRODUCTS_FAILURE, payload: error });
        }
    };
}

export const postProduct= data => async (dispatch) => {
    try {
      dispatch({ type: POST_PRODUCT_REQUEST });
      const response = await axios.post('/product', data);
      dispatch({ type: POST_PRODUCT_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: POST_PRODUCT_FAILURE, payload: error });
    }
};

//Single flash by ID
export const fetchProductById = (productId) => {
    return async dispatch => {
        try {
            dispatch({ type: FETCHING_PRODUCT_REQUEST });
            const response = await axios({
                url: `/product/${productId}`,
                method: 'get',
                timeout: 10000
            });
            dispatch({ type: FETCHING_PRODUCT_SUCCESS, payload: response });
        }
        catch (error) {
            dispatch({ type: FETCHING_PRODUCT_FAILURE, payload: error });
        }
    };
}

export const putProduct = data => async (dispatch) => {
    try {
      dispatch({ type: PUT_PRODUCT_REQUEST });
      const response = await axios.put(`product/${data.id}`, data);
      dispatch({ type: PUT_PRODUCT_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: PUT_PRODUCT_FAILURE, payload: error });
    }
};


export const deleteProduct = id => async (dispatch) => {
    try {
      dispatch({ type: DELETE_PRODUCT_REQUEST });
      const response = await axios.delete(`product/${id}`);
      dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: DELETE_PRODUCT_FAILURE, payload: error });
    }
};
