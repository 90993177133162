// Export all const with names and values
export const SET_ERROR = 'SET_ERROR';

// Stripe 
export const ADD_STRIPE_ACCOUNT_REQUEST = 'ADD_STRIPE_ACCOUNT_REQUEST';
export const ADD_STRIPE_ACCOUNT_SUCCESS = 'ADD_STRIPE_ACCOUNT_SUCCESS';
export const ADD_STRIPE_ACCOUNT_FAILURE = 'ADD_STRIPE_ACCOUNT_FAILURE';
/*export const PROCESS_PAYMENT_CONNECT_REQUEST = 'PROCESS_PAYMENT_CONNECT_REQUEST';
export const PROCESS_PAYMENT_CONNECT_SUCCESS = 'PROCESS_PAYMENT_CONNECT_SUCCESS';
export const PROCESS_PAYMENT_CONNECT_FAILURE = 'PROCESS_PAYMENT_CONNECT_FAILURE';
export const PROCESS_PRODUCT_PAYMENT_CONNECT_REQUEST = 'PROCESS_PRODUCT_PAYMENT_CONNECT_REQUEST';
export const PROCESS_PRODUCT_PAYMENT_CONNECT_SUCCESS = 'PROCESS_PRODUCT_PAYMENT_CONNECT_SUCCESS';
export const PROCESS_PRODUCT_PAYMENT_CONNECT_FAILURE = 'PROCESS_PRODUCT_PAYMENT_CONNECT_FAILURE';*/
export const GET_CARD_REQUEST = 'GET_CARD_REQUEST';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const GET_CARD_FAILURE = 'GET_CARD_FAILURE';
export const CREATE_CARD_REQUEST = 'CREATE_CARD_REQUEST';
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS';
export const CREATE_CARD_FAILURE = 'CREATE_CARD_FAILURE';
export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';
export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE';
export const GET_PURCHASES_REQUEST = 'GET_PURCHASES_REQUEST';
export const GET_PURCHASES_SUCCESS = 'GET_PURCHASES_SUCCESS';
export const GET_PURCHASES_FAILURE = 'GET_PURCHASES_FAILURE'; 
export const POST_INTENT_REQUEST = 'POST_INTENT_REQUEST';
export const POST_INTENT_SUCCESS = 'POST_INTENT_SUCCESS';
export const POST_INTENT_FAILURE = 'POST_INTENT_FAILURE'; 
export const POST_INTENT_PRODUCT_REQUEST = 'POST_INTENT_PRODUCT_REQUEST';
export const POST_INTENT_PRODUCT_SUCCESS = 'POST_INTENT_PRODUCT_SUCCESS';
export const POST_INTENT_PRODUCT_FAILURE = 'POST_INTENT_PRODUCT_FAILURE'; 

// User
export const FETCHING_USER_REQUEST = 'FETCHING_USER_REQUEST';
export const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS';
export const FETCHING_USER_FAILURE = 'FETCHING_USER_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const EDIT_CURRENCY_REQUEST = 'EDIT_CURRENCY_REQUEST';
export const EDIT_CURRENCY_SUCCESS = 'EDIT_CURRENCY_SUCCESS';
export const EDIT_CURRENCY_FAILURE = 'EDIT_CURRENCY_FAILURE';

// Flashes
export const FETCHING_FLASHES_REQUEST = 'FETCHING_FLASHES_REQUEST';
export const FETCHING_FLASHES_SUCCESS = 'FETCHING_FLASHES_SUCCESS';
export const FETCHING_FLASHES_FAILURE = 'FETCHING_FLASHES_FAILURE';

// Single flash
export const FETCHING_FLASH_REQUEST = 'FETCHING_FLASH_REQUEST';
export const FETCHING_FLASH_SUCCESS = 'FETCHING_FLASH_SUCCESS';
export const FETCHING_FLASH_FAILURE = 'FETCHING_FLASH_FAILURE';
export const PUT_FLASH_REQUEST = 'PUT_FLASH_REQUEST';
export const PUT_FLASH_SUCCESS = 'PUT_FLASH_SUCCESS';
export const PUT_FLASH_FAILURE = 'PUT_FLASH_FAILURE';
export const POST_FLASH_REQUEST = 'POST_FLASH_REQUEST';
export const POST_FLASH_SUCCESS = 'POST_FLASH_SUCCESS';
export const POST_FLASH_FAILURE = 'POST_FLASH_FAILURE';
export const DELETE_FLASH_REQUEST = 'DELETE_FLASH_REQUEST';
export const DELETE_FLASH_SUCCESS = 'DELETE_FLASH_SUCCESS';
export const DELETE_FLASH_FAILURE = 'DELETE_FLASH_FAILURE';

// Pages
export const FETCHING_PAGES_REQUEST = 'FETCHING_PAGES_REQUEST';
export const FETCHING_PAGES_SUCCESS = 'FETCHING_PAGES_SUCCESS';
export const FETCHING_PAGES_FAILURE = 'FETCHING_PAGES_FAILURE';
export const FETCHING_CURRENT_USER_PAGES_REQUEST = 'FETCHING_CURRENT_USER_PAGES_REQUEST';
export const FETCHING_CURRENT_USER_PAGES_SUCCESS = 'FETCHING_CURRENT_USER_PAGES_SUCCESS';
export const FETCHING_CURRENT_USER_PAGES_FAILURE = 'FETCHING_CURRENT_USER_PAGES_FAILURE';
export const POST_PAGES_REQUEST = 'POST_PAGES_REQUEST';
export const POST_PAGES_SUCCESS = 'POST_PAGES_SUCCESS';
export const POST_PAGES_FAILURE = 'POST_PAGES_FAILURE';
export const PUT_PAGES_REQUEST = 'PUT_PAGES_REQUEST';
export const PUT_PAGES_SUCCESS = 'PUT_PAGES_SUCCESS';
export const PUT_PAGES_FAILURE = 'PUT_PAGES_FAILURE';

// WebsiteInfo
export const FETCHING_WEBSITEINFO_REQUEST = 'FETCHING_WEBSITEINFO_REQUEST';
export const FETCHING_WEBSITEINFO_SUCCESS = 'FETCHING_WEBSITEINFO_SUCCESS';
export const FETCHING_WEBSITEINFO_FAILURE = 'FETCHING_WEBSITEINFO_FAILURE';
export const FETCHING_CURRENT_USER_WEBSITE_INFO_REQUEST = 'FETCHING_CURRENT_USER_WEBSITE_INFO_REQUEST';
export const FETCHING_CURRENT_USER_WEBSITE_INFO_SUCCESS = 'FETCHING_CURRENT_USER_WEBSITE_INFO_SUCCESS';
export const FETCHING_CURRENT_USER_WEBSITE_INFO_FAILURE = 'FETCHING_CURRENT_USER_WEBSITE_INFO_FAILURE';
export const CREATE_WEBSITE_REQUEST = 'CREATE_WEBSITE_REQUEST';
export const CREATE_WEBSITE_SUCCESS = 'CREATE_WEBSITE_SUCCESS';
export const CREATE_WEBSITE_FAILURE = 'CREATE_WEBSITE_FAILURE';
export const EDIT_WEBSITE_REQUEST = 'EDIT_WEBSITE_REQUEST';
export const EDIT_WEBSITE_SUCCESS = 'EDIT_WEBSITE_SUCCESS';
export const EDIT_WEBSITE_FAILURE = 'EDIT_WEBSITE_FAILURE';
export const CHANGE_URL_REQUEST = 'CHANGE_URL_REQUEST';
export const CHANGE_URL_SUCCESS = 'CHANGE_URL_SUCCESS';
export const CHANGE_URL_FAILURE = 'CHANGE_URL_FAILURE';

//  Google Calendar
export const CALENDAR_EXCHANGE_CODE_REQUEST = 'CALENDAR_EXCHANGE_CODE_REQUEST';
export const CALENDAR_EXCHANGE_CODE_SUCCESS = 'CALENDAR_EXCHANGE_CODE_SUCCESS';
export const CALENDAR_EXCHANGE_CODE_FAILURE = 'CALENDAR_EXCHANGE_CODE_FAILURE';
export const FETCHING_CALENDAR_REQUEST = 'FETCHING_CALENDAR_REQUEST';
export const FETCHING_CALENDAR_SUCCESS = 'FETCHING_CALENDAR_SUCCESS'; 
export const FETCHING_CALENDAR_FAILURE = 'FETCHING_CALENDAR_FAILURE';
export const POSTING_CALENDAR_EVENT_REQUEST = 'POSTING_CALENDAR_EVENT_REQUEST';
export const POSTING_CALENDAR_EVENT_SUCCESS = 'POSTING_CALENDAR_EVENT_SUCCESS'; 
export const POSTING_CALENDAR_EVENT_FAILURE = 'POSTING_CALENDAR_EVENT_FAILURE'; 
export const UNLINK_CALENDAR_REQUEST = 'UNLINK_CALENDAR_REQUEST';
export const UNLINK_CALENDAR_SUCCESS = 'UNLINK_CALENDAR_SUCCESS'; 
export const UNLINK_CALENDAR_FAILURE = 'UNLINK_CALENDAR_FAILURE'; 

//  Availability
export const FETCHING_AVAILABILITY_REQUEST = 'FETCHING_AVAILABILITY_REQUEST';
export const FETCHING_AVAILABILITY_SUCCESS = 'FETCHING_AVAILABILITY_SUCCESS';
export const FETCHING_AVAILABILITY_FAILURE = 'FETCHING_AVAILABILITY_FAILURE';
export const CREATE_AVAILABILITY_REQUEST = 'CREATE_AVAILABILITY_REQUEST';
export const CREATE_AVAILABILITY_SUCCESS = 'CREATE_AVAILABILITY_SUCCESS';
export const CREATE_AVAILABILITY_FAILURE = 'CREATE_AVAILABILITY_FAILURE';
export const EDIT_AVAILABILITY_REQUEST = 'EDIT_AVAILABILITY_REQUEST';
export const EDIT_AVAILABILITY_SUCCESS = 'EDIT_AVAILABILITY_SUCCESS';
export const EDIT_AVAILABILITY_FAILURE = 'EDIT_AVAILABILITY_FAILURE';

// Upload
export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';

// Categories
export const FETCHING_CATEGORIES_REQUEST = 'FETCHING_CATEGORIES_REQUEST';
export const FETCHING_CATEGORIES_SUCCESS = 'FETCHING_CATEGORIES_SUCCESS';
export const FETCHING_CATEGORIES_FAILURE = 'FETCHING_CATEGORIES_FAILURE';
export const FETCHING_CATEGORY_REQUEST = 'FETCHING_CATEGORY_REQUEST';
export const FETCHING_CATEGORY_SUCCESS = 'FETCHING_CATEGORY_SUCCESS';
export const FETCHING_CATEGORY_FAILURE = 'FETCHING_CATEGORY_FAILURE';
export const PUT_CATEGORY_REQUEST = 'PUT_CATEGORY_REQUEST';
export const PUT_CATEGORY_SUCCESS = 'PUT_CATEGORY_SUCCESS';
export const PUT_CATEGORY_FAILURE = 'PUT_CATEGORY_FAILURE';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const POST_CATEGORY_REQUEST = 'POST_CATEGORY_REQUEST';
export const POST_CATEGORY_SUCCESS = 'POST_CATEGORY_SUCCESS';
export const POST_CATEGORY_FAILURE = 'POST_CATEGORY_FAILURE';

// Email
export const POST_CONTACT_EMAIL_REQUEST = 'POST_CONTACT_EMAIL_REQUEST';
export const POST_CONTACT_EMAIL_SUCCESS = 'POST_CONTACT_EMAIL_SUCCESS';
export const POST_CONTACT_EMAIL_FAILURE = 'POST_CONTACT_EMAIL_FAILURE';
export const POST_SUPPORT_EMAIL_REQUEST = 'POST_SUPPORT_EMAIL_REQUEST';
export const POST_SUPPORT_EMAIL_SUCCESS = 'POST_SUPPORT_EMAIL_SUCCESS';
export const POST_SUPPORT_EMAIL_FAILURE = 'POST_SUPPORT_EMAIL_FAILURE';

// Products
export const FETCHING_PRODUCTS_REQUEST = 'FETCHING_PRODUCTS_REQUEST';
export const FETCHING_PRODUCTS_SUCCESS = 'FETCHING_PRODUCTS_SUCCESS';
export const FETCHING_PRODUCTS_FAILURE = 'FETCHING_PRODUCTS_FAILURE';

// Single PRODUCT
export const FETCHING_PRODUCT_REQUEST = 'FETCHING_PRODUCT_REQUEST';
export const FETCHING_PRODUCT_SUCCESS = 'FETCHING_PRODUCT_SUCCESS';
export const FETCHING_PRODUCT_FAILURE = 'FETCHING_PRODUCT_FAILURE';
export const PUT_PRODUCT_REQUEST = 'PUT_PRODUCT_REQUEST';
export const PUT_PRODUCT_SUCCESS = 'PUT_PRODUCT_SUCCESS';
export const PUT_PRODUCT_FAILURE = 'PUT_PRODUCT_FAILURE';
export const POST_PRODUCT_REQUEST = 'POST_PRODUCT_REQUEST';
export const POST_PRODUCT_SUCCESS = 'POST_PRODUCT_SUCCESS';
export const POST_PRODUCT_FAILURE = 'POST_PRODUCT_FAILURE';
export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';