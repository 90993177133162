import React, { Component } from "react";
import { InView } from "react-intersection-observer";

class LoadMore extends Component {

    render() {
        return (
            <InView onChange={(inView) => inView && this.props.context.loadMore()} >
                <div style={{ width: "100%", height: "100px" }}>
                </div>
            </InView>
        )
    }
}

export default LoadMore; 