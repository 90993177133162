import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BannerLarge extends Component {

    constructor() {
        super();
        this.state = {};
    }

    render(props) {

        if (!!this.props.data) {
            return (

                <section
                    className="banner-large"
                    style={!!this.props.data.background ? { backgroundImage: 'url(' + this.props.data.background + ')' } : null}
                >
                    {!!this.props.data.title && <h1>{this.props.data.title}</h1>}
                    {!!this.props.data.subtitle && <h2>{this.props.data.subtitle}</h2>}

                    {(!!this.props.data.button && !!this.props.data.buttonUrl && !this.props.data.hideButton) && 
                        <Link to={this.props.data.buttonUrl}> 
                            <button className="button">{this.props.data.button}</button>
                        </Link>
                    }
                </section>

            );
        } else { return null; }

    }
}

export default BannerLarge;