import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Callback from './auth/Callback';
import Body from "./Body";
import DashboardBody from "./DashboardBody";

import Auth from './auth/authService';


const auth = new Auth();

const handleAuthentication = ({ location }) => {
	if (/access_token|id_token|error/.test(location.hash)) {
		auth.handleAuthentication();
	}
}

class Router extends Component {
	render(props) {
		return (
			<Switch>
				{/* --- Admin Routes --- */}
				<Route path='/' exact render={() => window.location.replace("/admin")} />
				<Route path="/callback" exact render={(props) => { handleAuthentication(props); return <Callback {...props} /> }} />
				<Route path='/admin' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-admin" /> : auth.login()} />
				<Route path='/admin/availabilities' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-availability" /> : auth.login()} />
				<Route path='/admin/site-pages/*' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-sitePages" /> : auth.login()} />
				<Route path='/admin/flashes/myflashes/:id' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-singleFlash" /> : auth.login()} />
				<Route path='/admin/flashes/category/:id' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-singleCategory" /> : auth.login()} />
				<Route path='/admin/flashes/*' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-flashes" />  : auth.login()} />


				<Route path='/admin/products/myproducts/:id' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-singleProduct" /> : auth.login()} />
				<Route path='/admin/products/*' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-products" />  : auth.login()} />

				<Route path='/admin/support' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-support" />  : auth.login()} />
				<Route path='/admin/settings' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-settings" />  : auth.login()} />
				<Route path='/admin/settings/*' exact render={(props) => auth.isAuthenticated() ? <DashboardBody {...props} pageType="dashboard-settings" />  : auth.login()} />

				{/* --- MINI SITES ROUTES --- */}

				{/* Home */}
				<Route
					path='/:site'
					render={(props) => <Body {...props} pageType="homepage" />}
					exact
				/>

				{/* About */}
				<Route
					path='/:site/about'
					render={(props) => <Body {...props} pageType="about" />}
					exact
				/>

				{/* Products */}
				<Route
					path='/:site/products'
					render={(props) => <Body {...props} pageType="products" />}
					exact
				/> 

				{/* Single product */}
				<Route
					path='/:site/product/:productid'
					render={(props) => <Body {...props} pageType="single-product" />}
					exact
				/>

				{/* Contact */}
				<Route
					path='/:site/contact'
					render={(props) => <Body {...props} pageType="contact" />}
					exact
				/>

				{/* Flashes */}
				<Route
					path='/:site/flashes'
					render={(props) => <Body {...props} pageType="flashes" />}
					exact
				/>

				{/* Single flash */}
				<Route
					path='/:site/flash/:flashid'
					render={(props) => <Body {...props} pageType="single-flash" />}
					exact
				/>

				<Route
					path='/:site/flash'
					render={(props) => <Body {...props} pageType="404" />}
					exact
				/>

				{/* Terms and conditions */}
				<Route
					path='/:site/terms-and-conditions'
					render={(props) => <Body {...props} pageType="terms-and-conditions" />}
					exact
				/>

				{/* 404 */}
				<Route
					path='/:site/*'
					render={(props) => <Body {...props} pageType="404" />}
					exact
				/>
			</Switch>
		);
	}
}

export default Router; 