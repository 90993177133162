import axios from 'axios';
import { FETCHING_PAGES_REQUEST, FETCHING_PAGES_SUCCESS, FETCHING_PAGES_FAILURE, POST_PAGES_REQUEST, POST_PAGES_SUCCESS, POST_PAGES_FAILURE, PUT_PAGES_REQUEST, PUT_PAGES_SUCCESS, PUT_PAGES_FAILURE } from './types';

export const fetchPages = (siteUrl) => {
	return async dispatch => {
        try {
            dispatch({ type: FETCHING_PAGES_REQUEST });
            const response = await axios({
                url: `/website/${siteUrl}/pages`,
                method: 'get',
                timeout: 15000
            });
            dispatch({ type: FETCHING_PAGES_SUCCESS, payload: response });
        } 
        catch(error) {
            dispatch({ type: FETCHING_PAGES_FAILURE, payload: error });
        }
    };
}

export const fetchCurrentUserPages = () => {
	return async dispatch => {
        try {
            dispatch({ type: FETCHING_PAGES_REQUEST });
            const response = await axios.get('/pages');
            dispatch({ type: FETCHING_PAGES_SUCCESS, payload: response });
        } 
        catch(error) {
            dispatch({ type: FETCHING_PAGES_FAILURE, payload: error });
        }
    };
}

export const postPages = data => async (dispatch) => {
    try {
      dispatch({ type: POST_PAGES_REQUEST });
      const response = await axios.post('/website/pages', data);
      dispatch({ type: POST_PAGES_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: POST_PAGES_FAILURE, payload: error });
    }
};
  
export const putPages = data => async (dispatch) => {
    try {
      dispatch({ type: PUT_PAGES_REQUEST });
      const response = await axios.put(`/website/pages/${data.id}`, data);
      dispatch({ type: PUT_PAGES_SUCCESS, payload: response });
    } catch (error) {
      alert(error);
      dispatch({ type: PUT_PAGES_FAILURE, payload: error });
    }
};