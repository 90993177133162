import React, { Component } from 'react';

import BannerSmall from "./sections/section.banner-small";
import ContentAbout from "./sections/section.content-about";
import { animateScroll as scroll} from 'react-scroll'


class SitesAbout extends Component {
	constructor() {
		super();
		this.state = {}

		this.bannerData = {
			title : "About",
			background : null
		};

		this.contentData = {
			text : "Your content"
		}; 
	}

	componentDidMount(){
		scroll.scrollToTop();
	}

	render() {

		// Define components data
		if (this.props.data !== null) {

			// bannerData
			if( !! this.props.data.content1 ) this.bannerData.title = this.props.data.content1;
			if( !! this.props.data.banner ) this.bannerData.background = this.props.data.banner.imageUrl;

			// contentData
			if( !! this.props.data.content2 ) this.contentData.text = this.props.data.content2;
		}
 
		return (
			<main className="page-about">
				<BannerSmall data={this.bannerData} />
				<ContentAbout data={this.contentData}/>
			</main>
		);
	}
}

export default SitesAbout;