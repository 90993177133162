import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { getuserCard, createUserCard, deleteUserCard } from '../../../actions';
import { connect } from 'react-redux';
import Loading from '../partials/loading';
import _ from 'lodash';

class CreditCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holderName: null,
            cards: null,
            cardAdded: false
        }
    }

    handleNameChange(event) {
        this.setState({ holderName: event.target.value });
    }

    handleRemoveCard(id) {
        if (window.confirm("Are you sure you want to delete this credit card?")) {
            let data = {
                cardId: id
            }
            this.props.deleteUserCard(data).then(() => {

                this.props.getuserCard().then(() => {
                    this.setState({
                        cards: this.props.cards.data
                    });
                });
            });
        }
    }

    componentDidMount() {
        this.props.getuserCard().then(() => {
            this.setState({
                cards: this.props.cards.data
            });
        });
    }

    addCard() {
        this.props.stripe.createToken({ name: this.state.holderName }).then(({ token, error }) => {
            if (token.id !== null) {
                let data = {
                    source: token.id
                }

                this.props.createUserCard(data).then(() => {
                    this.props.getuserCard().then(() => {
                        this.setState({
                            cards: this.props.cards.data,
                            cardAdded: true
                        });
                    });
                })

            } else if (error !== null) {
                alert("Error with paiement : " + error)
            } else {
                alert("Error with credit card, please try again later.")
            }
        });
    }

    intToMonth(number) {
        if (parseInt(number) < 10) {
            return '0' + number;
        } else {
            return number;
        }
    }

    render() {
        console.log(this.state.cards, this.props.cards);

        return (
            <div>
                <Loading isLoading={this.props.isFetching} />
                <div>
                    <h1>Manage credit cards</h1>
                    <h2>My credit cards</h2>
                    <div>
                        {
                            !_.isEmpty(this.state.cards) ?
                                this.props.cards.data.map((item, key) => {
                                    return <div key={key} className={"userCreditCardList"}>
                                        <p><span className='title'>Card number:</span>&nbsp;&nbsp;<span className="brand">{item.brand}</span> <span className="dots">••••</span>&nbsp;<span className="dots">••••</span>&nbsp;<span className="dots">••••</span>&nbsp;&nbsp;{item.last4}</p>
                                        <p><span className='title'>Expiration date:</span>&nbsp;&nbsp;{this.intToMonth(item.exp_month)}/{item.exp_year}</p>

                                        <button className="delete-btn" onClick={() => this.handleRemoveCard(item.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" stroke="black" strokeWidth="2" className="react-timerange-picker__clear-button__icon react-timerange-picker__button__icon"><line x1="4" y1="4" x2="15" y2="15"></line><line x1="15" y1="4" x2="4" y2="15"></line></svg></button>
                                    </div>
                                }) :
                                <p>You dont have a credit card, please add your credit card.</p>
                        }
                    </div>
                </div>
                {
                    !this.state.cardAdded ?

                        <div style={{ marginTop: 50 }}>
                            <h2>Add credit card</h2>
                            <input placeholder="Card holder name" onChange={(text) => this.handleNameChange(text)} />
                            <div className="dashboardInputCard">
                                <CardElement />
                            </div>
                            <button className="button" style={{ marginTop: 25 }} onClick={() => this.addCard()}>Add card</button>
                        </div> :

                        <div style={{ marginTop: 50 }}>
                            <h2>Add credit card</h2>
                            <p className='confirmation'>The credit card was added successfully.</p>
                        </div>
                }
            </div>
        );
    }
}

const mapStateToProp = state => ({
    cards: state.stripe.cards,
    card: state.stripe.card,
    delCard: state.stripe.delCard,
    isFetching: state.stripe.isFetching
});

export default connect(mapStateToProp, { getuserCard, createUserCard, deleteUserCard })(injectStripe(CreditCard));